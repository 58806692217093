export default class TermsAndConditionsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getHistory() {
    return this.apiClient.getTermsAndConditionsHistory();
  }

  create(newContent) {
    return this.apiClient.createTermsAndConditions(newContent);
  }

  update(termsAndConditionsId, newContent) {
    return this.apiClient.updateTermsAndConditions(termsAndConditionsId, newContent);
  }
}
