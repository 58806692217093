export const CONSUMPTIONS_WEIGHTS = {
  SET_COLLECTION: 'CONSUMPTIONS_WEIGHTS_SET_COLLECTION',
  RESET_COLLECTION: 'CONSUMPTIONS_WEIGHTS_RESET_COLLECTION',
  FETCH_COLLECTION_REQUEST: 'CONSUMPTIONS_WEIGHTS_COLLECTION_REQUEST',
  FETCH_COLLECTION_SUCCESS: 'CONSUMPTIONS_WEIGHTS_COLLECTION_SUCCESS',
  FETCH_COLLECTION_FAILURE: 'CONSUMPTIONS_WEIGHTS_COLLECTION_FAILURE',
  FETCH_COLLECTION_SELECTOR: 'CONSUMPTIONS_WEIGHTS_COLLECTION',
  ADD_COLLECTION_ITEM: 'CONSUMPTIONS_WEIGHTS_ADD_COLLECTION_ITEM',
  ADD_COLLECTION_ITEM_REQUEST: 'CONSUMPTIONS_WEIGHTS_ADD_COLLECTION_ITEM_REQUEST',
  ADD_COLLECTION_ITEM_SUCCESS: 'CONSUMPTIONS_WEIGHTS_ADD_COLLECTION_ITEM_SUCCESS',
  ADD_COLLECTION_ITEM_FAILURE: 'CONSUMPTIONS_WEIGHTS_ADD_COLLECTION_ITEM_FAILURE',
  CHANGE_COLLECTION_ITEM: 'CONSUMPTIONS_WEIGHTS_CHANGE_COLLECTION_ITEM',
  CHANGE_COLLECTION_ITEM_REQUEST: 'CONSUMPTIONS_WEIGHTS_CHANGE_COLLECTION_ITEM_REQUEST',
  CHANGE_COLLECTION_ITEM_SUCCESS: 'CONSUMPTIONS_WEIGHTS_CHANGE_COLLECTION_ITEM_SUCCESS',
  CHANGE_COLLECTION_ITEM_FAILURE: 'CONSUMPTIONS_WEIGHTS_CHANGE_COLLECTION_ITEM_FAILURE',
  DELETE_COLLECTION_ITEM: 'CONSUMPTIONS_WEIGHTS_DELETE_COLLECTION_ITEM',
  DELETE_COLLECTION_ITEM_REQUEST: 'CONSUMPTIONS_WEIGHTS_DELETE_COLLECTION_ITEM_REQUEST',
  DELETE_COLLECTION_ITEM_SUCCESS: 'CONSUMPTIONS_WEIGHTS_DELETE_COLLECTION_ITEM_SUCCESS',
  DELETE_COLLECTION_ITEM_FAILURE: 'CONSUMPTIONS_WEIGHTS_DELETE_COLLECTION_ITEM_FAILURE',
  PREPARE_NEW_COLLECTION_ITEM: 'CONSUMPTIONS_WEIGHTS_PREPARE_NEW_COLLECTION_ITEM',
  REMOVE_NEW_COLLECTION_ITEM: 'CONSUMPTIONS_WEIGHTS_REMOVE_NEW_COLLECTION_ITEM',
  RESET_NEW_COLLECTION: 'CONSUMPTIONS_WEIGHTS_RESET_NEW_COLLECTION',
};

export const ConsumptionsWeightsActions = {

  setCollection: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.SET_COLLECTION,
    payload,
  }),

  resetCollection: () => ({
    type: CONSUMPTIONS_WEIGHTS.RESET_COLLECTION,
  }),

  fetchCollectionRequest: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.FETCH_COLLECTION_REQUEST,
    payload,
  }),

  fetchCollectionSuccess: () => ({
    type: CONSUMPTIONS_WEIGHTS.FETCH_COLLECTION_SUCCESS,
  }),

  fetchCollectionFailure: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.FETCH_COLLECTION_FAILURE,
    payload,
  }),

  addCollectionItem: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.ADD_COLLECTION_ITEM,
    payload,
  }),

  addCollectionItemRequest: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.ADD_COLLECTION_ITEM_REQUEST,
    payload,
  }),

  addCollectionItemFailure: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.ADD_COLLECTION_ITEM_FAILURE,
    payload,
  }),

  addCollectionItemSuccess: () => ({
    type: CONSUMPTIONS_WEIGHTS.ADD_COLLECTION_ITEM_SUCCESS,
  }),

  changeCollectionItem: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.CHANGE_COLLECTION_ITEM,
    payload,
  }),

  changeCollectionItemRequest: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.CHANGE_COLLECTION_ITEM_REQUEST,
    payload,
  }),

  changeCollectionItemFailure: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.CHANGE_COLLECTION_ITEM_FAILURE,
    payload,
  }),

  changeCollectionItemSuccess: () => ({
    type: CONSUMPTIONS_WEIGHTS.CHANGE_COLLECTION_ITEM_SUCCESS,
  }),

  deleteCollectionItem: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.DELETE_COLLECTION_ITEM,
    payload,
  }),

  deleteCollectionItemRequest: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.DELETE_COLLECTION_ITEM_REQUEST,
    payload,
  }),

  deleteCollectionItemFailure: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.DELETE_COLLECTION_ITEM_FAILURE,
    payload,
  }),

  deleteCollectionItemSuccess: () => ({
    type: CONSUMPTIONS_WEIGHTS.DELETE_COLLECTION_ITEM_SUCCESS,
  }),

  prepareNewCollectionItem: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.PREPARE_NEW_COLLECTION_ITEM,
    payload,
  }),

  removeNewCollectionItem: (payload) => ({
    type: CONSUMPTIONS_WEIGHTS.REMOVE_NEW_COLLECTION_ITEM,
    payload,
  }),

  resetNewCollection: () => ({
    type: CONSUMPTIONS_WEIGHTS.RESET_NEW_COLLECTION,
  }),
};
