import { TermsAndConditionsAction, TERMS_AND_CONDITIONS } from './termsAndConditionsActions';

export const initialTermsState = {
  collection: [],
  isLoading: false,
};

const termsAndConditionsReducer = (state = initialTermsState, action = { type: '' }) => {
  switch (action.type) {
    case TermsAndConditionsAction.request(TERMS_AND_CONDITIONS.GET_HISTORY).type: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TermsAndConditionsAction.success(TERMS_AND_CONDITIONS.GET_HISTORY).type: {
      return {
        ...state,
        collection: action.payload,
        isLoading: false,
      };
    }
    case TermsAndConditionsAction.failure(TERMS_AND_CONDITIONS.GET_HISTORY).type: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export { termsAndConditionsReducer };
