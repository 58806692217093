import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorBoundariesActions } from '../../errorBoundaries/actions/errorBoundaries';
import { dispatch } from 'app/store/configureStore';
import { retryBackoff } from 'backoff-rxjs';

export const METHOD_GET = 'GET';
export const METHOD_PATCH = 'PATCH';
export const METHOD_POST = 'POST';
export const METHOD_PUT = 'PUT';
export const METHOD_DELETE = 'DELETE';

export class RestClient {
  constructor(user$, ajax) {
    this.user = null;
    this.ajax = ajax;
    user$.subscribe((user) => { this.user = user; });
  }

  get accessToken() {
    return this.user ? `Bearer ${this.user.accessToken}` : '';
  }

  sendRequest(endpointUrl, method, body, authorization, contentType = 'application/json') {
    let headers = {};

    if (contentType) {
      headers['Content-Type'] = contentType;
    }

    if (authorization) {
      headers.Authorization = this.accessToken;
    }

    let request = {
      url: endpointUrl,
      method,
      headers,
    };

    if (body) {
      request.body = body;
    }
    return this.ajax(request).pipe(
      map((response) => response.response),
      retryBackoff({
        initialInterval: 1000,
        maxRetries: 2,
        resetOnSuccess: true,
      }),
      catchError((error) => {
        dispatch(ErrorBoundariesActions.throwError(error));
        return throwError(error);
      }),
    );
  }

  delete(endpointUrl, authorization = true) {
    return this.sendRequest(endpointUrl, METHOD_DELETE, null, authorization);
  }

  get(endpointUrl, authorization = true) {
    return this.sendRequest(endpointUrl, METHOD_GET, null, authorization);
  }

  patch(endpointUrl, body, authorization = true, contentType = 'application/json') {
    return this.sendRequest(endpointUrl, METHOD_PATCH, body, authorization, contentType);
  }

  post(endpointUrl, body, authorization = true, contentType = 'application/json') {
    return this.sendRequest(endpointUrl, METHOD_POST, body, authorization, contentType);
  }

  put(endpointUrl, body, authorization = true, contentType = 'application/json') {
    return this.sendRequest(endpointUrl, METHOD_PUT, body, authorization, contentType);
  }
}
