const REQUEST_SUFFIX = '_REQUEST';
const SUCCESS_SUFFIX = '_SUCCESS';
const FAILURE_SUFFIX = '_FAILURE';
const CREATE_SUFFIX = '_CREATE';
const READ_SUFFIX = '_READ';
const UPDATE_SUFFIX = '_UPDATE';
const DELETE_SUFFIX = '_DELETE';

export class ActionCreator {
  constructor(group) {
    this.group = group;
  }

  static createAction(type, payload) {
    return payload !== undefined && payload !== null ? ({ type, payload }) : ({ type });
  }

  action(type, payload) {
    return ActionCreator.createAction(type, payload);
  }

  /* API Actions */
  request(action, payload) {
    const type = action + REQUEST_SUFFIX;
    return ActionCreator.createAction(type, payload);
  }

  success(action, payload) {
    const type = action + SUCCESS_SUFFIX;
    return ActionCreator.createAction(type, payload);
  }

  failure(action, payload) {
    const type = action + FAILURE_SUFFIX;
    return ActionCreator.createAction(type, payload);
  }

  /* CRUD Actions */
  create = {
    request: (payload) => ActionCreator.createAction(this.group + CREATE_SUFFIX + REQUEST_SUFFIX, payload),
    success: (payload) => ActionCreator.createAction(this.group + CREATE_SUFFIX + SUCCESS_SUFFIX, payload),
    failure: (payload) => ActionCreator.createAction(this.group + CREATE_SUFFIX + FAILURE_SUFFIX, payload),
  };

  read = {
    request: (payload) => ActionCreator.createAction(this.group + READ_SUFFIX + REQUEST_SUFFIX, payload),
    success: (payload) => ActionCreator.createAction(this.group + READ_SUFFIX + SUCCESS_SUFFIX, payload),
    failure: (payload) => ActionCreator.createAction(this.group + READ_SUFFIX + FAILURE_SUFFIX, payload),
  };

  update = {
    request: (payload) => ActionCreator.createAction(this.group + UPDATE_SUFFIX + REQUEST_SUFFIX, payload),
    success: (payload) => ActionCreator.createAction(this.group + UPDATE_SUFFIX + SUCCESS_SUFFIX, payload),
    failure: (payload) => ActionCreator.createAction(this.group + UPDATE_SUFFIX + FAILURE_SUFFIX, payload),
  };

  delete = {
    request: (payload) => ActionCreator.createAction(this.group + DELETE_SUFFIX + REQUEST_SUFFIX, payload),
    success: (payload) => ActionCreator.createAction(this.group + DELETE_SUFFIX + SUCCESS_SUFFIX, payload),
    failure: (payload) => ActionCreator.createAction(this.group + DELETE_SUFFIX + FAILURE_SUFFIX, payload),
  };
}
