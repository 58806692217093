import { POOLS } from 'app/pools/actions/PoolsActions';

export const initialPoolsState = {
  currentPool: null,
  newItems: [],
  collection: [],
};

const poolsReducer = (state = initialPoolsState, action = { type: '' }) => {
  switch (action.type) {
    case POOLS.SET_POOL:
      return {
        ...state,
        currentPool: action.payload,
      };
    case POOLS.RESET_CURRENT_POOL:
      return {
        ...state,
        currentPool: initialPoolsState.currentPool,
      };
    case POOLS.SET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
      };
    case POOLS.RESET_COLLECTION:
      return {
        ...state,
        collection: initialPoolsState.collection,
      };
    case POOLS.PREPARE_NEW_COLLECTION_ITEM:
      return {
        ...state,
        newItems: [
          ...state.newItems,
          action.payload,
        ],
      };

    case POOLS.REMOVE_NEW_COLLECTION_ITEM:
      return {
        ...state,
        newItems: state.newItems.filter(item => item.id !== action.payload),
      };

    case POOLS.RESET_NEW_COLLECTION:
      return {
        ...state,
        newItems: [],
      };

    default:
      return state;
  }
};

export { poolsReducer };
