// admin panel routes
export const rootRoute = 'rootRoute';
export const editAlertNotificationsRoute = 'editAlertNotificationsRoute';
export const createAlertNotificationsRoute = 'createAlertNotificationsRoute';
export const alertNotificationsRoute = 'alertNotificationsRoute';
export const maintenanceRoute = 'maintenanceRoute';
export const maintenanceNpsRoute = 'maintenanceNpsRoute';
export const maintenanceCreateNpsRoute = 'maintenanceCreateNpsRoute';
export const maintenanceEditNpsRoute = 'maintenanceEditNpsRoute';
export const authenticationRoute = 'authenticationRoute';
export const authenticationReturnRoute = 'authenticationReturnRoute';
export const poolsRoute = 'poolsRoute';
export const bunkerConsumptionSettingsRoute = 'bunkerConsumptionSettingsRoute';
export const mapSettingsRoute = 'mapSettingsRoute';
export const vesselsImagesRoute = 'vesselsImagesRoute';
export const marketsRoute = 'marketsRoute';
export const poolCommentsRoute = 'poolCommentsRoute';
export const vesselGroupsRoute = 'vesselGroupsRoute';
export const calendarRoute = 'calendarRoute';
export const calendarEditRoute = 'calendarEditRoute';
export const calendarCreateRoute = 'calendarCreateRoute';
export const welcomePopupRoute = 'welcomePopupRoute';
export const forecastSettingsRoute = 'forecastSettingsRoute';
export const documentsRoute = 'documentsRoute';
export const documentsUploadRoute = 'documentsUploadRoute';
export const documentsEditRoute = 'documentsEditRoute';
export const documentsCategoryRoute = 'documentsCategoryRoute';
export const etlFilesRoute = 'etlFilesRoute';
export const etlFilesUploadRoute = 'etlFilesUploadRoute';
export const etlFileEditRoute = 'etlFileEditRoute';
export const newsRoute = 'newsRoute';
export const newsEditRoute = 'newsEditRoute';
export const newsCreateRoute = 'newsCreateRoute';
export const faqRoute = 'faqRoute';
export const faqCategoriesRoute = 'faqCategoriesRoute';
export const faqEditQuestionRoute = 'faqEditQuestionRoute';
export const faqNewQuestionRoute = 'faqNewQuestionRoute';
export const dataValidationRoute = 'dataValidationRoute';
export const vesselsRoute = 'vesselsRoute';
export const createVesselRoute = 'createVesselRoute';
export const editVesselRoute = 'editVesselRoute';
export const poolPartnersRoute = 'poolPartnersRoute';
export const createPoolPartnerRoute = 'createPoolPartnerRoute';
export const editPoolPartnerRoute = 'editPoolPartnerRoute';
export const usersRoute = 'usersRoute';
export const createUserRoute = 'createUserRoute';
export const editUserRoute = 'editUserRoute';
export const apiAdminVesselIdRoute = 'apiAdminVesselIdRoute';
export const apiAdminVesselDetailsImoRoute = 'apiAdminVesselDetailsImoRoute';
export const contactRoute = 'contactsRoute';
export const contactDepartmentsRoute = 'contactDepartmentsRoute';
export const contactEditPersonRoute = 'contactEditPersonRoute';
export const contactNewPersonRoute = 'contactNewPersonRoute';
export const termsAndConditionsRoute = 'termsAndConditionsRoute';
export const benchmarkRatesRoute = 'benchmarkRatesRoute';
export const vesselPartnerReportRoute = 'vesselPartnerReportRoute';

// maersk admin api routes names
export const maintenanceStatusRoute = 'maintenanceStatusRoute';
export const maintenanceStatusHistoryRoute = 'maintenanceHistoryStatusRoute';
export const apiClearCacheRoute = 'apiClearCacheRoute';
export const imagesRoute = 'imagesRoute';
export const imagesFileNameRoute = 'imagesFileNameRoute';
export const apiMapVesselsRoute = 'apiMapVesselsRoute';
export const apiVisibilityVesselRoute = 'apiVisibilityVesselRoute';
export const apiMarketRoute = 'apiMarketRoute';
export const apiVesselsGroupsRoute = 'apiVesselsGroupsRoute';
export const apiPoolsVesselsGroupsRoute = 'apiPoolsVesselsGroupsRoute';
export const apiPoolsVesselsGroupsIdRoute = 'apiPoolsVesselsGroupsIdRoute';
export const apiVesselsGroupsIdRoute = 'apiVesselsGroupsIdRoute';
export const apiVesselGroupsUpdateRoute = 'apiVesselGroupsUpdateRoute';
export const apiMarketIdRoute = 'apiMarketIdRoute';
export const apiCalendarEventsRoute = 'apiCalendarEventsRoute';
export const apiCalendarEventsIdRoute = 'apiCalendarEventsIdRoute';
export const apiMarketCommentaryRoute = 'apiMarketCommentaryRoute';
export const apiMarketIdCommentaryRoute = 'apiMarketCommentaryRoute';
export const apiMarketIdCommentaryIdRoute = 'apiMarketCommentaryIdRoute';
export const apiWelcomePopupRoute = 'apiWelcomePopupRoute';
export const apiPoolCommentsRoute = 'apiPoolCommentsRoute';
export const apiPoolCommentsIdRoute = 'apiPoolCommentsIdRoute';
export const apiPoolThresholdRoute = 'apiPoolThresholdRoute';
export const apiPoolForecastsRoute = 'apiPoolForecastsRoute';
export const apiPoolForecastsIdRoute = 'apiPoolForecastsIdRoute';
export const apiDocumentsRoute = 'apiDocumentsRoute';
export const apiDocumentsIdRoute = 'apiDocumentsIdRoute';
export const apiDocumentDownloadRoute = 'apiDocumentDownloadRoute';
export const apiDocumentCategories = 'apiDocumentCategories';
export const apiDocumentCategoriesId = 'apiDocumentCategoriesId';
export const apiNewsRoute = 'apiNewsRoute';
export const apiNewsIdRoute = 'apiNewsIdRoute';
export const apiEtlFilesRoute = 'apiEtlFilesRoute';
export const apiEtlFilesIdRoute = 'apiEtlFilesIdRoute';
export const apiEtlProcessStartAllRoute = 'apiEtlProcessStartAllRoute';
export const apiEtlProcessStartSingleRoute = 'apiEtlProcessStartSingleRoute';
export const apiEtlProcessStatusRoute = 'apiEtlProcessStatusRoute';
export const apiEtlProcessLogsRoute = 'apiEtlProcessLogsRoute';
export const apiDataValidationStartRoute = 'apiDataValidationStartRoute';
export const apiDataValidationStopRoute = 'apiDataValidationStopRoute';
export const apiDataValidationStatusRoute = 'apiDataValidationStatusRoute';
export const apiDataValidationResultsRoute = 'apiDataValidationResultsRoute';
export const apiDataValidationRulesRoute = 'apiDataValidationRulesRoute';
export const apiDataValidationIdRulesRoute = 'apiDataValidationIdRulesRoute';
export const apiDataValidationFinancialDate = 'apiDataValidationFinancialDate';
export const apiAdminVesselRoute = 'apiAdminVesselRoute';
export const apiContactsRoute = 'apiContactsRoute';
export const apiContactsDepartmentRoute = 'apiContactsDepartmentRoute';
export const apiContactsPersonsRoute = 'apiContactsPersonsRoute';
export const apiContactsPersonRoute = 'apiContactsPersonRoute';
export const apiContactUpdatePersonsOrderRoute = 'apiContactUpdatePersonsOrderRoute';
export const apiContactUpdateDepartmentsOrderRoute = 'apiContactUpdateDepartmentsOrderRoute';
export const apiContactsDepartmentsRoute = 'apiContactsDepartmentsRoute';
export const apiTermsAndConditionsRoute = 'apiTermsAndConditionsRoute';
export const apiTermsAndConditionsUpdateRoute = 'apiTermsAndConditionsUpdateRoute';
export const apiBenchmarkRatesRoute = 'apiBenchmarkRatesRoute';
export const apiSpecificBenchmarkRateRoute = 'apiSpecificBenchmarkRateRoute';
export const apiGetNpsRoute = 'apiGetNpsRoute';
export const apiCreateNpsRoute = 'apiCreateNpsRoute';
export const apiUpdateNpsRoute = 'apiUpdateNpsRoute';
export const apiDeleteNpsRoute = 'apiDeleteNpsRoute';
export const apiUpdateNpsStatusRoute = 'apiUpdateNpsStatusRoute';
export const apiGetVesselPartnerReportRoute = 'apiGetVesselPartnerReportRoute';

// maersk api routes
export const apiPoolsRoute = 'apiPoolsRoute';
export const apiPoolsIdRoute = 'apiPoolsIdRoute';
export const apiPoolsWithDetailsRoute = 'apiPoolsWithDetailsRoute';
export const apiPoolPartnersRoute = 'apiPoolPartnersRoute';
export const apiPoolPartnerRoute = 'apiPoolPartnerRoute';
export const apiUsersRoute = 'apiUsersRoute';
export const apiUserRoute = 'apiUserRoute';
export const apiPoolPartnersPoolIdRoute = 'apiPoolPartnersPoolIdRoute';
export const apiFaqCategoriesRoute = 'apiFaqCategoriesRoute';
export const apiFaqUpdateQuestionsOrderRoute = 'apiFaqUpdateQuestionsOrderRoute';
export const apiFaqUpdateCategoriesOrderRoute = 'apiFaqUpdateCategoriesOrderRoute';
export const apiFaqQuestionRoute = 'apiFaqQuestionRoute';
export const apiFaqCategoryRoute = 'apiFaqCategoryRoute';
export const apiFaqQuestionsRoute = 'apiFaqQuestionsRoute';

export const apiBunkerConsumptionConditionsParametersRoute = 'apiBunkerConsumptionConditionsParametersRoute';
export const apiBunkerConsumptionConditionsParametersIdRoute = 'apiBunkerConsumptionConditionsParametersIdRoute';
export const apiBunkerConsumptionConditionsWeightsRoute = 'apiBunkerConsumptionConditionsWeightsRoute';
export const apiBunkerConsumptionConditionsWeightsIdRoute = 'apiBunkerConsumptionConditionsWeightsIdRoute';

export const apiVesselsRoute = 'apiVesselsRoute';

export const postRoutes = {
  example: '/examplePostRoute',
};

export const getRoutes = {
  [rootRoute]: '/',
  [alertNotificationsRoute]: '/alert-notifications',
  [createAlertNotificationsRoute]: '/alert-notifications/create',
  [editAlertNotificationsRoute]: '/alert-notifications/edit/:id',
  [maintenanceRoute]: '/maintenance',
  [maintenanceNpsRoute]: '/maintenance/nps',
  [maintenanceCreateNpsRoute]: '/maintenance/nps/create',
  [maintenanceEditNpsRoute]: '/maintenance/nps/:npsId',
  [authenticationRoute]: '/auth',
  [authenticationReturnRoute]: '/auth-return',
  [poolsRoute]: '/pools',
  [bunkerConsumptionSettingsRoute]: '/bunker-consumption-settings',
  [vesselsImagesRoute]: '/vessels-images',
  [mapSettingsRoute]: '/map-settings',
  [marketsRoute]: '/markets',
  [poolCommentsRoute]: '/pool-comments',
  [vesselGroupsRoute]: '/vessel-groups',
  [calendarRoute]: '/calendar',
  [calendarEditRoute]: '/calendar/edit/:id',
  [calendarCreateRoute]: '/calendar/create',
  [welcomePopupRoute]: '/welcome-popup',
  [maintenanceStatusRoute]: '/maintenance/status',
  [maintenanceStatusHistoryRoute]: '/maintenance/status/history',
  [imagesRoute]: '/images',
  [imagesFileNameRoute]: '/images/:fileName',
  [forecastSettingsRoute]: '/forecast-settings',
  [documentsCategoryRoute]: '/documents/category',
  [documentsEditRoute]: '/documents/edit/:id',
  [documentsUploadRoute]: '/documents/upload',
  [documentsRoute]: '/documents',
  [etlFilesRoute]: '/etl-files',
  [etlFilesUploadRoute]: '/etl-files/upload',
  [etlFileEditRoute]: '/etl-files/edit/:id',
  [newsRoute]: '/news',
  [newsEditRoute]: '/news/edit/:id',
  [newsCreateRoute]: '/news/create',
  [faqRoute]: '/faq',
  [faqCategoriesRoute]: '/faq/categories',
  [faqEditQuestionRoute]: '/faq/edit/:categoryId/:questionId',
  [faqNewQuestionRoute]: '/faq/create',
  [dataValidationRoute]: '/data-validation',
  [vesselsRoute]: '/vessels',
  [createVesselRoute]: '/vessels/create',
  [editVesselRoute]: '/vessels/:vesselId',
  [poolPartnersRoute]: '/pool-partners',
  [createPoolPartnerRoute]: '/pool-partners/create',
  [editPoolPartnerRoute]: '/pool-partners/:poolPartnerId',
  [usersRoute]: '/users',
  [createUserRoute]: '/users/create',
  [editUserRoute]: '/users/:userId',
  [contactRoute]: '/contact',
  [contactDepartmentsRoute]: '/contact/departments',
  [contactEditPersonRoute]: '/contact/person/edit/:departmentId/:personId',
  [contactNewPersonRoute]: '/contact/person/create',
  [termsAndConditionsRoute]: '/terms-and-conditions',
  [benchmarkRatesRoute]: '/benchmark/rates',
  [vesselPartnerReportRoute]: '/vessel-partner',
  [apiClearCacheRoute]: '/cache/clear',
  [apiMapVesselsRoute]: '/map/v1/pools/:poolName/vessels',
  [apiVisibilityVesselRoute]: '/map/v1/vessels/:imo/status',
  [apiMarketRoute]: '/pool/:poolId/market',
  [apiPoolsVesselsGroupsRoute]: '/pool/:poolId/vessel-groups',
  [apiPoolsVesselsGroupsIdRoute]: '/pool/:poolId/vessel-groups:/id',
  [apiVesselsGroupsRoute]: '/vessel-groups',
  [apiVesselsGroupsIdRoute]: '/vessel-groups/:id',
  [apiVesselGroupsUpdateRoute]: '/vessel-groups/:groupId/vessel/:vesselIMO',
  [apiMarketIdRoute]: '/pool/:poolId/market/:id',
  [apiCalendarEventsRoute]: '/calendar',
  [apiCalendarEventsIdRoute]: '/calendar/:id',
  [apiMarketCommentaryRoute]: '/pool/:poolId/market/commentary',
  [apiMarketIdCommentaryRoute]: '/pool/:poolId/market/:marketId/commentary',
  [apiMarketIdCommentaryIdRoute]: '/pool/:poolId/market/:marketId/commentary/:id',
  [apiWelcomePopupRoute]: '/welcome-popup',
  [apiPoolsRoute]: '/pools',
  [apiPoolsIdRoute]: '/pools/:id',
  [apiPoolsWithDetailsRoute]: '/pools/detail',
  [apiPoolPartnersRoute]: '/partners',
  [apiPoolPartnerRoute]: '/partners/:partnerId',
  [apiUsersRoute]: '/admin/users',
  [apiUserRoute]: '/admin/users/:userId',
  [apiPoolPartnersPoolIdRoute]: '/pools/:poolId/partners',
  [apiBunkerConsumptionConditionsParametersRoute]: '/bunker-consumption/conditions-parameters',
  [apiBunkerConsumptionConditionsParametersIdRoute]: '/bunker-consumption/conditions-parameters/:id',
  [apiBunkerConsumptionConditionsWeightsRoute]: '/bunker-consumption/conditions-weights',
  [apiBunkerConsumptionConditionsWeightsIdRoute]: '/bunker-consumption/conditions-weights/:id',
  [apiVesselsRoute]: '/pools/:poolId/vessels',
  [apiPoolCommentsRoute]: '/pool/:poolId/comment',
  [apiPoolCommentsIdRoute]: '/pool/:poolId/comment/:id',
  [apiPoolThresholdRoute]: '/pool/:poolId/forecast/threshold',
  [apiPoolForecastsRoute]: '/pool/:poolId/forecast',
  [apiPoolForecastsIdRoute]: '/pool/:poolId/forecast/:id',
  [apiDocumentsRoute]: '/documents',
  [apiDocumentsIdRoute]: '/documents/:id',
  [apiDocumentDownloadRoute]: '/documents/:id/downloads',
  [apiDocumentCategories]: '/document-categories',
  [apiDocumentCategoriesId]: '/document-categories/:id',
  [apiNewsRoute]: '/news',
  [apiNewsIdRoute]: '/news/:id',
  [apiEtlFilesRoute]: '/etl/file',
  [apiEtlFilesIdRoute]: '/etl/file/:id',
  [apiEtlProcessStartAllRoute]: '/etl/processing/start',
  [apiEtlProcessStartSingleRoute]: '/etl/processing/start/:fileType',
  [apiEtlProcessStatusRoute]: '/etl/processing/status',
  [apiEtlProcessLogsRoute]: '/etl/processing/logs',
  [apiFaqCategoriesRoute]: '/faq/category',
  [apiFaqCategoryRoute]: '/faq/category/:categoryId',
  [apiFaqQuestionsRoute]: '/faq/category/:categoryId/question',
  [apiFaqQuestionRoute]: '/faq/category/:categoryId/question/:questionId',
  [apiFaqUpdateQuestionsOrderRoute]: '/faq/category/:categoryId/question/order',
  [apiFaqUpdateCategoriesOrderRoute]: '/faq/category/order',
  [apiDataValidationStatusRoute]: '/validation/status',
  [apiDataValidationFinancialDate]: '/validation/current-period',
  [apiDataValidationStartRoute]: '/validation/start',
  [apiDataValidationStopRoute]: '/validation/stop',
  [apiDataValidationResultsRoute]: '/validation/result',
  [apiDataValidationRulesRoute]: '/validation/rule',
  [apiDataValidationIdRulesRoute]: '/validation/rule/:id',
  [apiAdminVesselRoute]: '/admin/vessels',
  [apiAdminVesselIdRoute]: '/admin/vessels/:id',
  [apiAdminVesselDetailsImoRoute]: '/admin/vessels/:imo/details',
  [apiContactsRoute]: '/contacts',
  [apiContactsDepartmentsRoute]: '/contacts/department',
  [apiContactsDepartmentRoute]: '/contacts/department/:departmentId',
  [apiContactsPersonsRoute]: '/contacts/department/:departmentId/person',
  [apiContactsPersonRoute]: '/contacts/department/:departmentId/person/:personId',
  [apiContactUpdatePersonsOrderRoute]: '/contacts/department/:departmentId/person/order',
  [apiContactUpdateDepartmentsOrderRoute]: '/contacts/department/order',
  [apiTermsAndConditionsRoute]: '/terms-and-conditions',
  [apiTermsAndConditionsUpdateRoute]: '/terms-and-conditions/:termsAndConditionsId',
  [apiBenchmarkRatesRoute]: '/admin/benchmarks',
  [apiSpecificBenchmarkRateRoute]: '/admin/benchmarks/:benchmarkRateId',
  [apiGetNpsRoute]: '/nps/survey',
  [apiCreateNpsRoute]: '/nps/survey',
  [apiUpdateNpsRoute]: '/nps/survey/:id',
  [apiDeleteNpsRoute]: '/nps/survey/:id',
  [apiUpdateNpsStatusRoute]: '/nps/status',
  [apiGetVesselPartnerReportRoute]: '/admin/vessels/partners',
};
