import { ActionCreator } from '../../store/actionCreator';

const DATA_VALIDATION_PROCESS_NAME = 'DATA_VALIDATION_PROCESS';

export const DATA_VALIDATION_PROCESS = {
  START: 'DATA_VALIDATION_PROCESS_START',
  STOP: 'DATA_VALIDATION_PROCESS_STOP',
  FETCH_STATUS: 'DATA_VALIDATION_PROCESS_FETCH_STATUS',
  SET_IN_PROGRESS: 'DATA_VALIDATION_PROCESS_IN_PROGRESS_SET',
  SET_PERIOD: 'DATA_VALIDATION_PROCESS_SET_PERIOD',
  RESET_PERIOD: 'DATA_VALIDATION_PROCESS_RESET_PERIOD',
  FETCH_LATEST_FINANCIAL_DATE: 'DATA_VALIDATION_PROCESS_FETCH_LATEST_FINANCIAL_DATE',
};

export const DataValidationProcessAction = new ActionCreator(DATA_VALIDATION_PROCESS_NAME);
