import { NPS, npsActions, npsStatusActions } from './npsActions';

const initialState = {
  globallyEnabled: true,
  periods: [],
  loadingStatus: false,
  npsContext: null,
};

export const npsReducer = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case NPS.SET_CONTEXT:
      return {
        ...state,
        npsContext: action.payload,
      };
    case npsActions.read.success().type:
      return {
        ...state,
        globallyEnabled: action.payload.areSurveysEnabled,
        periods: action.payload.surveys,
        loadingStatus: false,
      };
    case npsActions.create.success().type:
      return {
        ...state,
        periods: [ ...state.periods, action.payload ],
        loadingStatus: false,
      };
    case npsActions.update.success().type:
      return {
        ...state,
        periods: state.periods.map(period => {
          if (period.id === action.payload.id) {
            return action.payload;
          }
          return period;
        }),
        loadingStatus: false,
        npsContext: null,
      };
    case npsActions.delete.success().type:
      return {
        ...state,
        periods: state.periods.filter(period => period.id !== action.payload),
        loadingStatus: false,
      };
    case npsStatusActions.update.success().type:
      return {
        ...state,
        globallyEnabled: action.payload,
        loadingStatus: false,
      };
    case npsActions.delete.request().type:
    case npsActions.update.request().type:
    case npsActions.create.request().type:
    case npsActions.read.request().type:
    case npsStatusActions.update.request().type:
      return {
        ...state,
        loadingStatus: true,
      };
    case npsActions.delete.failure().type:
    case npsActions.update.failure().type:
    case npsActions.create.failure().type:
    case npsActions.read.failure().type:
    case npsStatusActions.update.failure().type:
      return {
        ...state,
        loadingStatus: false,
      };
    default:
      return state;
  }
};
