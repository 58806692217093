class EtlFilesService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  static createFileFormData(file, fileContent = null) {
    const formData = new FormData();
    const {
      name, typeId, poolId, type
    } = file;
    const fileUploadContent = {
      filename: name,
      type: typeId || type,
      poolId,
    };
    formData.append('File', fileContent !== null ? fileContent : file);
    formData.append('EtlFileData', JSON.stringify(fileUploadContent));
    return formData;
  }

  getFiles() {
    return this.apiClient.getEtlFiles();
  }

  getFile(fileId) {
    return this.apiClient.getEtlFile(fileId);
  }

  postFile(file) {
    return this.apiClient.postEtlFile(
      EtlFilesService.createFileFormData(file),
    );
  }

  deleteFile(fileId) {
    return this.apiClient.deleteEtlFile(fileId);
  }

  updateFile(file) {
    return this.apiClient.updateEtlFile(file.id, EtlFilesService.createFileFormData(file, file.newFile));
  }
}

export default EtlFilesService;
