import { ActionCreator } from 'app/store/actionCreator';

export const CALENDAR_NAME = 'CALENDAR';

export const CALENDAR = {
  ADD_EVENT: 'EVENT_ADD_EVENT',
  GET_EVENT: 'EVENT_GET_EVENT',
  GET_CALENDAR_COLLECTION: 'CALENDAR_GET_CALENDAR_COLLECTION',
  GET_CALENDAR_POOL_COLLECTION: 'GET_CALENDAR_POOL_COLLECTION',
  UPDATE_EVENT: 'CALENDAR_UPDATE_EVENT',
  REMOVE_CALENDAR_EVENT: 'CALENDAR_REMOVE_EVENT',
  SET_CALENDAR_FORM: 'SET_CALENDAR_FORM',
  RESET_CALENDAR_FORM: 'RESET_CALENDAR_FORM',
  SET_POOL: 'CALENDAR_SET_POOL',
};

export const CalendarActions = new ActionCreator(CALENDAR_NAME);
