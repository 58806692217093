/* eslint-disable max-classes-per-file */
import { routingActionCreators } from './actions/RoutingActions';
import {
  getRoutes,
  postRoutes,
  maintenanceRoute,
  authenticationReturnRoute,
  bunkerConsumptionSettingsRoute,
  authenticationRoute,
  vesselsImagesRoute,
  mapSettingsRoute,
  poolsRoute,
  marketsRoute,
  vesselGroupsRoute,
  calendarRoute,
  welcomePopupRoute,
  poolCommentsRoute,
  newsRoute,
  newsEditRoute,
  newsCreateRoute,
  faqRoute,
  faqCategoriesRoute,
  faqNewQuestionRoute,
  faqEditQuestionRoute,
  etlFilesRoute,
  etlFilesUploadRoute,
  etlFileEditRoute,
  dataValidationRoute,
  vesselsRoute,
  createVesselRoute,
  editVesselRoute,
  contactEditPersonRoute,
  contactDepartmentsRoute,
  contactNewPersonRoute,
  contactRoute,
  poolPartnersRoute,
  createPoolPartnerRoute,
  editPoolPartnerRoute,
  createUserRoute,
  editUserRoute,
  usersRoute,
  termsAndConditionsRoute,
  alertNotificationsRoute,
  createAlertNotificationsRoute,
  editAlertNotificationsRoute,
  benchmarkRatesRoute,
  maintenanceCreateNpsRoute,
  maintenanceEditNpsRoute,
  maintenanceNpsRoute,
  calendarCreateRoute,
  calendarEditRoute,
  documentsEditRoute,
  vesselPartnerReportRoute,
} from './routeConstants';
import uniloc from 'uniloc';
import { keyBy, reduce } from 'lodash';
import {
  documentsCategoryRoute, documentsRoute, documentsUploadRoute, forecastSettingsRoute,
} from 'app/routing/routeConstants';

class Route {
  constructor(path, name, authenticationRequired, onEnterActionCreator) {
    this.path = path;
    this.name = name;
    this.authenticationRequired = authenticationRequired;
    this.onEnter = onEnterActionCreator;
  }
}

class Routes {
  constructor(routes) {
    this.routesArray = routes;
    this.routesMap = keyBy(routes, (route) => route.path);
  }

  hasRoute(path) {
    return path in this.routesMap;
  }

  getOnEnterActionCreatorForRoute(path) {
    const route = this.routesMap[path];
    return route ? route.onEnter : () => {
    };
  }

  isAuthenticationRequired(path) {
    return this.hasRoute(path) ? this.routesMap[path].authenticationRequired : false;
  }
}

const getUnilocRoutes = (routes, methodType) => reduce(routes, (result, value, key) => ({
  ...result,
  [key]: `${methodType} ${value}`,
}), {});

const unilocGetRoutes = getUnilocRoutes(getRoutes, 'GET');
const unilocPostRoutes = getUnilocRoutes(postRoutes, 'POST');

const unilocRoutes = { ...unilocGetRoutes, ...unilocPostRoutes };

export const ROUTER = uniloc(
  unilocRoutes,
);

export const routes = new Routes([
  new Route(
    getRoutes.maintenanceCreateNpsRoute,
    maintenanceCreateNpsRoute,
    true,
    routingActionCreators.enteredMaintenanceCreateNpsPage,
  ),
  new Route(
    getRoutes.maintenanceEditNpsRoute,
    maintenanceEditNpsRoute,
    true,
    routingActionCreators.enteredMaintenanceEditNpsPage,
  ),
  new Route(
    ROUTER.generate(maintenanceNpsRoute),
    maintenanceNpsRoute,
    true,
    routingActionCreators.enteredMaintenancePage,
  ),
  new Route(
    ROUTER.generate(maintenanceRoute),
    maintenanceRoute,
    true,
    routingActionCreators.enteredMaintenancePage,
  ),
  new Route(
    ROUTER.generate(authenticationRoute),
    authenticationRoute,
    false,
    routingActionCreators.enteredAuthPage,
  ),
  new Route(
    ROUTER.generate(authenticationReturnRoute),
    authenticationReturnRoute,
    false,
    routingActionCreators.enteredAuthReturnPage,
  ),
  new Route(
    getRoutes.editAlertNotificationsRoute, // path
    editAlertNotificationsRoute, // name
    true, // authenticationRequired
    () => ({ // onEnter
      type: '',
    }),
  ),
  new Route(
    getRoutes.createAlertNotificationsRoute, // path
    createAlertNotificationsRoute, // name
    true, // authenticationRequired
    () => ({ // onEnter
      type: '',
    }),
  ),
  new Route(
    getRoutes.alertNotificationsRoute, // path
    alertNotificationsRoute, // name
    true, // authenticationRequired
    () => ({ // onEnter
      type: '',
    }),
  ),
  new Route(
    ROUTER.generate(poolsRoute),
    poolsRoute,
    true,
    routingActionCreators.enteredPoolsPage,
  ),
  new Route(
    ROUTER.generate(bunkerConsumptionSettingsRoute),
    bunkerConsumptionSettingsRoute,
    true,
    routingActionCreators.enteredBunkerConsumptionSettingsPage,
  ),
  new Route(
    ROUTER.generate(benchmarkRatesRoute),
    benchmarkRatesRoute,
    true,
    routingActionCreators.benchmarkRatesPage,
  ),
  new Route(
    getRoutes.createVesselRoute,
    createVesselRoute,
    true,
    routingActionCreators.enteredCreateVesselPage,
  ),
  new Route(
    getRoutes.editVesselRoute,
    editVesselRoute,
    true,
    routingActionCreators.enteredEditVesselPage,
  ),
  new Route(
    ROUTER.generate(vesselsRoute),
    vesselsRoute,
    true,
    routingActionCreators.enteredVesselsPage,
  ),
  new Route(
    getRoutes.createPoolPartnerRoute,
    createPoolPartnerRoute,
    true,
    routingActionCreators.enteredCreatePoolPartnerPage,
  ),
  new Route(
    getRoutes.editPoolPartnerRoute,
    editPoolPartnerRoute,
    true,
    routingActionCreators.enteredEditPoolPartnerPage,
  ),
  new Route(
    ROUTER.generate(poolPartnersRoute),
    poolPartnersRoute,
    true,
    routingActionCreators.enteredPoolPartnersPage,
  ),
  new Route(
    getRoutes.createUserRoute,
    createUserRoute,
    true,
    routingActionCreators.enteredCreateUserPage,
  ),
  new Route(
    getRoutes.editUserRoute,
    editUserRoute,
    true,
    routingActionCreators.enteredEditUserPage,
  ),
  new Route(
    ROUTER.generate(usersRoute),
    usersRoute,
    true,
    routingActionCreators.enteredUsersPage,
  ),
  new Route(
    ROUTER.generate(vesselsImagesRoute),
    vesselsImagesRoute,
    true,
    routingActionCreators.enteredVesselsImagesPage,
  ),
  new Route(
    ROUTER.generate(mapSettingsRoute),
    mapSettingsRoute,
    true,
    routingActionCreators.enteredMapSettingsPage,
  ),
  new Route(
    ROUTER.generate(marketsRoute),
    marketsRoute,
    true,
    routingActionCreators.enteredMarketsPage,
  ),
  new Route(
    ROUTER.generate(poolCommentsRoute),
    poolCommentsRoute,
    true,
    routingActionCreators.enteredPoolCommentsPage,
  ),
  new Route(
    ROUTER.generate(vesselGroupsRoute),
    vesselGroupsRoute,
    true,
    routingActionCreators.enteredVesselGroupsPage,
  ),
  new Route(
    getRoutes.calendarEditRoute,
    calendarEditRoute,
    true,
    routingActionCreators.enteredCalendarEditPage,
  ),
  new Route(
    getRoutes.calendarCreateRoute,
    calendarCreateRoute,
    true,
    routingActionCreators.enteredCalendarCreatePage,
  ),
  new Route(
    ROUTER.generate(calendarRoute),
    calendarRoute,
    true,
    routingActionCreators.enteredCalendarPage,
  ),
  new Route(
    ROUTER.generate(welcomePopupRoute),
    welcomePopupRoute,
    true,
    routingActionCreators.enteredWelcomePopupPage,
  ),
  new Route(
    ROUTER.generate(forecastSettingsRoute),
    forecastSettingsRoute,
    true,
    routingActionCreators.enteredForecastSettingsPage,
  ),
  new Route(
    getRoutes.documentsEditRoute,
    documentsEditRoute,
    true,
    routingActionCreators.enteredDocumentsEditPage,
  ),
  new Route(
    ROUTER.generate(documentsRoute),
    documentsRoute,
    true,
    routingActionCreators.enteredDocumentsPage,
  ),
  new Route(
    ROUTER.generate(documentsUploadRoute),
    documentsUploadRoute,
    true,
    routingActionCreators.enteredDocumentsUploadPage,
  ),
  new Route(
    ROUTER.generate(documentsCategoryRoute),
    documentsCategoryRoute,
    true,
    routingActionCreators.enteredDocumentsCategoryPage,
  ),
  new Route(
    getRoutes.etlFileEditRoute,
    etlFileEditRoute,
    true,
    routingActionCreators.enteredEtlFileEditPage,
  ),
  new Route(
    ROUTER.generate(etlFilesRoute),
    etlFilesRoute,
    true,
    routingActionCreators.enteredEtlFilesPage,
  ),
  new Route(
    ROUTER.generate(etlFilesUploadRoute),
    etlFilesUploadRoute,
    true,
    routingActionCreators.enteredEtlFilesUploadPage,
  ),
  new Route(
    getRoutes.newsEditRoute,
    newsEditRoute,
    true,
    routingActionCreators.enteredNewsEditPage,
  ),
  new Route(
    getRoutes.newsCreateRoute,
    newsCreateRoute,
    true,
    routingActionCreators.enteredNewsCreatePage,
  ),
  new Route(
    ROUTER.generate(newsRoute),
    newsRoute,
    true,
    routingActionCreators.enteredNewsPage,
  ),
  new Route(
    getRoutes.faqEditQuestionRoute,
    faqEditQuestionRoute,
    true,
    routingActionCreators.enteredFaqEditQuestionPage,
  ),
  new Route(
    getRoutes.faqCategoriesRoute,
    faqCategoriesRoute,
    true,
    routingActionCreators.enteredFaqCategoriesPage,
  ),
  new Route(
    getRoutes.faqNewQuestionRoute,
    faqNewQuestionRoute,
    true,
    routingActionCreators.enteredFaqNewQuestionPage,
  ),
  new Route(
    ROUTER.generate(faqRoute),
    faqRoute,
    true,
    routingActionCreators.enteredFaqPage,
  ),
  new Route(
    ROUTER.generate(dataValidationRoute),
    dataValidationRoute,
    true,
    routingActionCreators.enteredDataValidationPage,
  ),
  new Route(
    getRoutes.contactEditPersonRoute,
    contactEditPersonRoute,
    true,
    routingActionCreators.enteredContactEditPersonPage,
  ),
  new Route(
    getRoutes.contactDepartmentsRoute,
    contactDepartmentsRoute,
    true,
    routingActionCreators.enteredContactDepartmentsPage,
  ),
  new Route(
    getRoutes.contactNewPersonRoute,
    contactNewPersonRoute,
    true,
    routingActionCreators.enteredContactNewPersonPage,
  ),
  new Route(
    ROUTER.generate(contactRoute),
    contactRoute,
    true,
    routingActionCreators.enteredContactPage,
  ),
  new Route(
    ROUTER.generate(termsAndConditionsRoute),
    termsAndConditionsRoute,
    true,
    routingActionCreators.termsAndConditionsPage,
  ),
  new Route(
    ROUTER.generate(vesselPartnerReportRoute),
    vesselPartnerReportRoute,
    true,
    routingActionCreators.vesselPartnerReportPage,
  ),
]);
