import { ActionCreator } from 'app/store/actionCreator';

export const CONTACT_NAME = 'CONTACT';

export const CONTACT = {
  GET_ALL: 'CONTACT_GET_ALL',
  SET_CURRENT_DEPARTMENT: 'SET_CURRENT_DEPARTMENT',
  UPDATE_DEPARTMENTS_ORDER: 'UPDATE_DEPARTMENTS_ORDER',
  UPDATE_PERSONS_ORDER: 'UPDATE_PERSONS_ORDER',
  CREATE_PERSON: 'CREATE_PERSON',
  DELETE_PERSON: 'DELETE_PERSON',
  DELETE_DEPARTMENT: 'DELETE_DEPARTMENT',
  CREATE_DEPARTMENT: 'CREATE_DEPARTMENT',
  UPDATE_DEPARTMENT: 'UPDATE_DEPARTMENT',
  SET_EDIT_PERSON: 'SET_EDIT_PERSON',
  GET_EDIT_PERSON: 'GET_EDIT_PERSON',
  UPDATE_PERSON: 'UPDATE_PERSON',
  RESET_PERSON_FORM: 'RESET_PERSON_FORM',
};

export const ContactsAction = new ActionCreator(CONTACT_NAME);
