import { MaerskApiClient } from 'app/shared/clients/maerskApi.client';
import MaintenanceService from 'app/maintenance/services/MaintenanceService';
import AuthenticationService from 'app/authentication/services/AuthenticationService';
import PoolsService from 'app/pools/services/PoolsService';
import BunkerConsumptionService from 'app/bunkerConsumptionSettings/services/BunkerConsumptionService';
import ImageService from 'app/vessels/images/services/ImageService';
import MapSettingsService from 'app/mapSettings/services/mapSettingsService';
import CalendarService from 'app/calendar/services/calendarService';
import MarketsService from 'app/markets/services/marketsService';
import VesselGroupsService from 'app/vesselGroups/services/vesselGroupsService';
import WelcomePopupService from 'app/welcomePopup/services/WelcomePopupService';
import PoolCommentsService from 'app/poolComments/services/poolCommentsService';
import ForecastSettingsService from 'app/forecastSettings/services/forecastSettingsService';
import DocumentsService from 'app/documents/services/documentsService';
import NewsService from 'app/news/services/newsService';
import PoolPartnersService from 'app/poolPartners/services/poolPartnersService';
import FaqService from 'app/faq/services/faqService';
import EtlFilesService from 'app/etlFiles/services/etlFilesService';
import DataValidationService from 'app/dataValidation/services/dataValidationService';
import EtlProcessService from '../../etlFiles/services/etlProcessService';
import VesselsService from '../../vessels/manager/services/vesselsService';
import ContactService from '../../contactDepartments/services/contactService';
import UsersService from 'app/users/services/usersService';
import TermsAndConditionsService from 'app/termsAndConditions/services/termsAndConditionsService';
import BrenchmarkRatesService from '../../benchmark/rates/services/benchmarkRatesService';
import NpsService from 'app/nps/store/NpsService';
import VesselPartnerReportService from '../../vesselPartnerReport/services/VesselPartnerReportService';

export class ServicesFactory {
  constructor(restClient) {
    this.apiClient = new MaerskApiClient(restClient);
  }

  get authentication() {
    this._authentication = this._authentication || new AuthenticationService();
    return this._authentication;
  }

  get maintenance() {
    this._maintenance = this._maintenance || new MaintenanceService(this.apiClient);
    return this._maintenance;
  }

  get images() {
    this._images = this._images || new ImageService(this.apiClient);
    return this._images;
  }

  get pools() {
    this._pools = this._pools || new PoolsService(this.apiClient);
    return this._pools;
  }

  get mapSettings() {
    this._mapSettings = this._mapSettings || new MapSettingsService(this.apiClient);
    return this._mapSettings;
  }

  get bunkerConsumption() {
    this._bunkerConsumption = this._bunkerConsumption || new BunkerConsumptionService(this.apiClient);
    return this._bunkerConsumption;
  }

  get markets() {
    this._markets = this._markets || new MarketsService(this.apiClient);
    return this._markets;
  }

  get poolComments() {
    this._poolComments = this._poolComments || new PoolCommentsService(this.apiClient);
    return this._poolComments;
  }

  get vesselGroups() {
    this._vesselGroups = this._vesselGroups || new VesselGroupsService(this.apiClient, this.apiClient);
    return this._vesselGroups;
  }

  get calendar() {
    this._calendar = this._calendar || new CalendarService(this.apiClient);
    return this._calendar;
  }

  get welcomePopup() {
    this._welcomePopup = this._welcomePopup || new WelcomePopupService(this.apiClient);
    return this._welcomePopup;
  }

  get forecast() {
    this._forecast = this._forecast || new ForecastSettingsService(this.apiClient);
    return this._forecast;
  }

  get documents() {
    this._documents = this._documents || new DocumentsService(this.apiClient);
    return this._documents;
  }

  get etlFiles() {
    this._etlFiles = this._etlFiles || new EtlFilesService(this.apiClient);
    return this._etlFiles;
  }

  get etlProcess() {
    this._etlProcess = this._etlProcess || new EtlProcessService(this.apiClient);
    return this._etlProcess;
  }

  get news() {
    this._news = this._news || new NewsService(this.apiClient);
    return this._news;
  }

  get poolPartners() {
    this._poolPartners = this._poolPartners || new PoolPartnersService(this.apiClient);
    return this._poolPartners;
  }

  get users() {
    this._users = this._users || new UsersService(this.apiClient);
    return this._users;
  }

  get faq() {
    this._faq = this._faq || new FaqService(this.apiClient);
    return this._faq;
  }

  get vessels() {
    this._vessels = this._vessels || new VesselsService(this.apiClient);
    return this._vessels;
  }

  get dataValidation() {
    this._dataValidation = this._dataValidation || new DataValidationService(this.apiClient);
    return this._dataValidation;
  }

  get contact() {
    this._contact = this._contact || new ContactService(this.apiClient);
    return this._contact;
  }

  get termsAndConditions() {
    this._termsAndConditions = this._termsAndConditions || new TermsAndConditionsService(this.apiClient);
    return this._termsAndConditions;
  }

  get benchmarkRates() {
    this._benchmarkRates = this._benchmarkRates || new BrenchmarkRatesService(this.apiClient);
    return this._benchmarkRates;
  }

  get nps() {
    this._nps = this._nps || new NpsService(this.apiClient);
    return this._nps;
  }

  get vesselPartnerReport() {
    this._vesselPartnerReport = this._vesselPartnerReport || new VesselPartnerReportService(this.apiClient);
    return this._vesselPartnerReport;
  }
}
