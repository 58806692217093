import { maintenanceStatusReducer } from 'app/maintenance/reducers/MaintenanceStatusReducer';
import { authenticationReducer } from 'app/authentication/reducers/AuthenticationReducer';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { poolsReducer } from 'app/pools/reducers/PoolsReducer';
import { consumptionsWeightsReducer } from 'app/consumptionsWeights/reducers/ConsumptionsWeightsReducer';
import { conditionsParametersReducer } from 'app/conditionsParameters/reducers/ConditionsParametersReducer';
import { bunkerConsumptionSettingsReducer } from 'app/bunkerConsumptionSettings/reducers/BunkerConsumptionSettingsReducer';
import { mapSettingsReducer } from 'app/mapSettings/reducers/MapSettingsReducer';
import { marketsReducer } from 'app/markets/reducers/MarketsReducer';
import { vesselGroupsReducer } from 'app/vesselGroups/reducers/vesselGroupsReducer';
import { calendarReducer } from '../calendar/reducers/CalendarReducer';
import { welcomePopupReducer } from '../welcomePopup/reducers/WelcomePopupReducer';
import { poolCommentsReducer } from 'app/poolComments/reducers/PoolCommentsReducer';
import { forecastSettingsReducer } from 'app/forecastSettings/reducers/forecastSettingsReducer';
import { documentsReducer } from 'app/documents/reducers/documentsReducer';
import { newsReducer } from 'app/news/reducers/newsReducer';
import { poolPartnersReducer } from 'app/poolPartners/store/reducers/poolPartnersReducer';
import { faqReducer } from '../faq/reducers/faqReducer';
import { etlFilesReducer } from 'app/etlFiles/reducers/etlFilesReducer';
import { dataValidationReducer } from '../dataValidation/reducers/DataValidationReducer';
import { etlProcessReducer } from '../etlFiles/reducers/etlProcessReducer';
import { vesselsReducer } from '../vessels/manager/store/vesselsReducer';
import { contactReducer } from '../contactDepartments/store/contactReducer';
import { usersReducer } from 'app/users/store/reducers/usersReducer';
import { termsAndConditionsReducer } from '../termsAndConditions/store/termsAndConditionsReducer';
import { benchmarkRatesReducer } from '../benchmark/rates/store/benchmarkRatesReducer';
import { npsReducer } from '../nps/store/npsReducer';
import { vesselPartnerReportReducer } from '../vesselPartnerReport/reducers/vesselPartnerReportReducer';

const createRootReducer = (history) => {
  const reducers = {
    authentication: authenticationReducer,
    bunkerConsumptionSettings: bunkerConsumptionSettingsReducer,
    calendar: calendarReducer,
    conditionsParameters: conditionsParametersReducer,
    consumptionsWeights: consumptionsWeightsReducer,
    maintenance: maintenanceStatusReducer,
    pools: poolsReducer,
    poolComments: poolCommentsReducer,
    router: connectRouter(history),
    mapSettings: mapSettingsReducer,
    markets: marketsReducer,
    news: newsReducer,
    vesselGroups: vesselGroupsReducer,
    welcomePopup: welcomePopupReducer,
    forecastSettings: forecastSettingsReducer,
    documents: documentsReducer,
    etlFiles: etlFilesReducer,
    etlProcess: etlProcessReducer,
    poolPartners: poolPartnersReducer,
    faq: faqReducer,
    vessels: vesselsReducer,
    validation: dataValidationReducer,
    contact: contactReducer,
    users: usersReducer,
    termsAndConditions: termsAndConditionsReducer,
    benchmarkRates: benchmarkRatesReducer,
    nps: npsReducer,
    vesselPartnerReport: vesselPartnerReportReducer,
  };
  return combineReducers({ ...reducers });
};

export default createRootReducer;
