import { CONSUMPTIONS_WEIGHTS } from 'app/consumptionsWeights/actions/ConsumptionsWeightsActions';

export const initialConsumptionsWeightsState = {
  newItems: [],
  collection: [],
};

const consumptionsWeightsReducer = (state = initialConsumptionsWeightsState, action = { type: '' }) => {
  switch (action.type) {
    case CONSUMPTIONS_WEIGHTS.SET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
      };

    case CONSUMPTIONS_WEIGHTS.RESET_COLLECTION:
      return {
        ...state,
        collection: initialConsumptionsWeightsState.collection,
      };

    case CONSUMPTIONS_WEIGHTS.PREPARE_NEW_COLLECTION_ITEM:
      return {
        ...state,
        newItems: [
          ...state.newItems,
          action.payload,
        ],
      };

    case CONSUMPTIONS_WEIGHTS.REMOVE_NEW_COLLECTION_ITEM:
      return {
        ...state,
        newItems: state.newItems.filter(item => item.id !== action.payload),
      };

    case CONSUMPTIONS_WEIGHTS.RESET_NEW_COLLECTION:
      return {
        ...state,
        newItems: [],
      };

    default:
      return state;
  }
};

export { consumptionsWeightsReducer };
