import { combineEpics, ofType } from 'redux-observable';
import { POOLS, PoolsActions } from 'app/pools/actions/PoolsActions';
import { catchError, map, switchMap, tap, ignoreElements } from 'rxjs/operators';
import { toast } from 'react-toastify';
import { routingActionTypes } from '../../routing/actions/RoutingActions';

export const fetchPoolsEpic = (action$, state$, { Services }) =>
  action$.pipe(
    ofType(
      POOLS.FETCH_COLLECTION_REQUEST,
      routingActionTypes.ENTERED_POOLS_PAGE
    ),
    switchMap(() =>
      Services.pools.getPools().pipe(
        map((pools) => [
          PoolsActions.resetCollection(),
          PoolsActions.setCollection(pools),
          PoolsActions.fetchCollectionSuccess(),
        ]),
        catchError((e) => [
          PoolsActions.fetchCollectionFailure(e),
        ])
      )
    )
  );

export const addPoolEpic = (action$, state$, { Services }) =>
  action$.pipe(
    ofType(POOLS.ADD_COLLECTION_ITEM),
    switchMap((action) =>
      Services.pools.createPool(action.payload).pipe(
        map(() => PoolsActions.addCollectionItemSuccess()),
        catchError((payload) => [
          PoolsActions.addCollectionItemFailure(payload),
        ])
      )
    )
  );

export const addPoolSuccessEpic = (action$) =>
  action$.pipe(
    ofType(POOLS.ADD_COLLECTION_ITEM_SUCCESS),
    tap(() => toast.success('Created successfully')),
    map(() => PoolsActions.fetchCollectionRequest()),
  );

export const changePoolEpic = (action$, state$, { Services }) =>
  action$.pipe(
    ofType(POOLS.CHANGE_COLLECTION_ITEM),
    switchMap((action) =>
      Services.pools.updatePool(action.payload).pipe(
        map(() => PoolsActions.changeCollectionItemSuccess()),
        catchError((payload) => [
          PoolsActions.changeCollectionItemFailure(payload),
        ])
      )
    )
  );

export const changePoolSuccessEpic = (action$) =>
  action$.pipe(
    ofType(POOLS.CHANGE_COLLECTION_ITEM_SUCCESS),
    tap(() => toast.success('Created successfully')),
    map(() => PoolsActions.fetchCollectionRequest()),
  );

export const deletePoolEpic = (action$, state$, { Services }) =>
  action$.pipe(
    ofType(POOLS.DELETE_COLLECTION_ITEM),
    switchMap((action) =>
      Services.pools.deletePool(action.payload).pipe(
        map(() => PoolsActions.deleteCollectionItemSuccess()),
        catchError((payload) => [
          PoolsActions.deleteCollectionItemFailure(payload),
        ]),
      ),
    ),
  );

export const deletePoolSuccessEpic = (action$) =>
  action$.pipe(
    ofType(POOLS.DELETE_COLLECTION_ITEM_SUCCESS),
    tap(() => toast.success('Deleted successfully')),
    map(() => PoolsActions.fetchCollectionRequest()),
  );

export const deletePoolFailureEpic = (action$) =>
  action$.pipe(
    ofType(POOLS.DELETE_COLLECTION_ITEM_FAILURE),
    tap(() => toast.error('Failure while deleting')),
    ignoreElements(),
  );

export const changePoolFailureEpic = (action$) =>
  action$.pipe(
    ofType(POOLS.CHANGE_COLLECTION_ITEM_FAILURE),
    map((action) => {
      if (action.payload && action.payload.message && action.payload.response) {
        toast.error(`Save failed with error '${action.payload.message}'. Details: ${action.payload.response.message}.`);
      } else {
        toast.error('Change pool failed.');
      }
    }),
    ignoreElements(),
  );

export const addPoolFailureEpic = (action$) =>
  action$.pipe(
    ofType(POOLS.ADD_COLLECTION_ITEM_FAILURE),
    map((action) => {
      if (action.payload && action.payload.message && action.payload.response) {
        toast.error(`Add failed with error '${action.payload.message}'. Details: ${action.payload.response.message}.`);
      } else {
        toast.error('Add pool failed.');
      }
    }),
    ignoreElements(),
  );

export const fetchPoolFailureEpic = (action$) =>
  action$.pipe(
    ofType(POOLS.FETCH_COLLECTION_FAILURE),
    map((action) => {
      if (action.payload && action.payload.message && action.payload.response) {
        toast.error(`Collection fetch failed with error '${action.payload.message}'. Details ${action.payload.response.message} fields.`);
      } else {
        toast.error('Collection fetch failed.');
      }
    }),
    ignoreElements(),
  );

export const poolsEpic = combineEpics(
  fetchPoolsEpic,
  fetchPoolFailureEpic,
  addPoolEpic,
  addPoolSuccessEpic,
  addPoolFailureEpic,
  changePoolEpic,
  changePoolSuccessEpic,
  changePoolFailureEpic,
  deletePoolEpic,
  deletePoolSuccessEpic,
  deletePoolFailureEpic
);
