export const MAP_SETTINGS = {
  SET_COLLECTION: 'MAP_SETTINGS_SET_COLLECTION',
  RESET_COLLECTION: 'MAP_SETTINGS_RESET_COLLECTION',
  SET_POOL: 'MAP_SETTINGS_SET_POOL',
  FETCH_COLLECTION_REQUEST: 'MAP_SETTINGS_FETCH_COLLECTION_REQUEST',
  FETCH_COLLECTION_SUCCESS: 'MAP_SETTINGS_FETCH_COLLECTION_SUCCESS',
  FETCH_COLLECTION_FAILURE: 'MAP_SETTINGS_FETCH_COLLECTION_FAILURE',
  FETCH_COLLECTION_SELECTOR: 'MAP_SETTINGS_FETCH_COLLECTION',
  UPDATE_ITEM_REQUEST: 'MAP_SETTINGS_UPDATE_ITEM_REQUEST',
  UPDATE_ITEM_SUCCESS: 'MAP_SETTINGS_UPDATE_ITEM_SUCCESS',
  UPDATE_ITEM_FAILURE: 'MAP_SETTINGS_UPDATE_ITEM_FAILURE',
  UPDATE_ITEM_SELECTOR: 'MAP_SETTINGS_UPDATE_ITEM',
};

export const MapActions = {
  setCollection: (payload) => ({
    type: MAP_SETTINGS.SET_COLLECTION,
    payload,
  }),
  resetCollection: () => ({
    type: MAP_SETTINGS.RESET_COLLECTION,
  }),
  setPool: (payload) => ({
    type: MAP_SETTINGS.SET_POOL,
    payload,
  }),
  fetchCollectionRequest: (payload) => ({
    type: MAP_SETTINGS.FETCH_COLLECTION_REQUEST,
    payload,
  }),
  fetchCollectionSuccess: () => ({
    type: MAP_SETTINGS.FETCH_COLLECTION_SUCCESS,
  }),
  fetchCollectionFailure: (payload) => ({
    type: MAP_SETTINGS.FETCH_COLLECTION_FAILURE,
    payload,
  }),
  updateItemRequest: (payload) => ({
    type: MAP_SETTINGS.UPDATE_ITEM_REQUEST,
    payload,
  }),
  updateItemSuccess: (payload) => ({
    type: MAP_SETTINGS.UPDATE_ITEM_SUCCESS,
    payload,
  }),
  updateItemFailure: (payload) => ({
    type: MAP_SETTINGS.UPDATE_ITEM_FAILURE,
    payload,
  }),
};
