import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  map,
  filter,
  switchMap, mergeMap,
} from 'rxjs/operators';
import { find, get, isNull } from 'lodash';
import { routingActionTypes } from '../../routing/actions/RoutingActions';
import { PoolsActions } from '../../pools/actions/PoolsActions';
import { VESSEL_GROUPS, VesselGroupsAction } from 'app/vesselGroups/actions/vesselGroups';
import { forkJoin, of } from 'rxjs';

export const enteredVesselGroupEpic = (action$, state$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_VESSEL_GROUP_PAGE),
  map(() => get(state$.value, 'pools.currentPool', null)),
  map((currentPool) => [
    PoolsActions.fetchCollectionRequest(),
    VesselGroupsAction.action(VESSEL_GROUPS.SET_POOL, currentPool || 1),
  ])
);

export const fetchCollectionRequestEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(VesselGroupsAction.request(VESSEL_GROUPS.FETCH_COLLECTION).type),
  map((action) => {
    const pools = get(state$.value, 'pools.collection', []);
    return find(pools, {
      id: action.payload,
    });
  }),
  filter((pool) => pool),
  switchMap((pool) => forkJoin(
    Services.vesselGroups.getVessels(pool.id).pipe(
      catchError((e) => [
        VesselGroupsAction.failure(VESSEL_GROUPS.FETCH_COLLECTION, e),
      ])
    ),
    Services.vesselGroups.getVesselGroups(pool.id).pipe(
      catchError((e) => [
        VesselGroupsAction.failure(VESSEL_GROUPS.FETCH_GROUPS, e),
      ])
    )
  )),
  switchMap(([ vesselsCollection, vesselGroups ]) => forkJoin(
    of(vesselGroups),
    Services.vesselGroups.setGroupToVessel(vesselsCollection, vesselGroups),
  )),
  map(([ vesselGroups, vesselsCollection ]) => [
    VesselGroupsAction.action(VESSEL_GROUPS.SET_COLLECTION, vesselsCollection),
    VesselGroupsAction.action(VESSEL_GROUPS.SET_GROUPS, vesselGroups),
    VesselGroupsAction.success(VESSEL_GROUPS.FETCH_COLLECTION),
    VesselGroupsAction.success(VESSEL_GROUPS.FETCH_GROUPS),
  ]),
);

export const setPoolVesselGroupsEpic = (action$) => action$.pipe(
  ofType(VesselGroupsAction.action(VESSEL_GROUPS.SET_POOL).type),
  filter((action) => !isNull(action.payload)),
  map((action) => [
    PoolsActions.setPool(action.payload),
    VesselGroupsAction.request(VESSEL_GROUPS.FETCH_COLLECTION, action.payload),
  ])
);

export const addVesselToGroupEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(VesselGroupsAction.request(VESSEL_GROUPS.UPDATE_ITEM).type),
  mergeMap((action) => {
    const { vesselIMO, groupId } = action.payload;
    return Services.vesselGroups.addVesselToGroup(vesselIMO, groupId).pipe(
      map(() => [
        VesselGroupsAction.success(VESSEL_GROUPS.UPDATE_ITEM, action.payload),
      ]),
      catchError((e) => VesselGroupsAction.failure(VESSEL_GROUPS.UPDATE_ITEM, e)),
    );
  }),
);

export const removeVesselFromGroupEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(VesselGroupsAction.request(VESSEL_GROUPS.REMOVE_ITEM).type),
  mergeMap((action) => {
    const { vesselIMO, groupId } = action.payload;
    return Services.vesselGroups.removeVesselFromGroup(vesselIMO, groupId).pipe(
      map(() => [
        VesselGroupsAction.success(VESSEL_GROUPS.REMOVE_ITEM, action.payload),
      ]),
      catchError((e) => VesselGroupsAction.failure(VESSEL_GROUPS.REMOVE_ITEM, e)),
    );
  }),
);

export const vesselGroupsEpic = combineEpics(
  enteredVesselGroupEpic,
  fetchCollectionRequestEpic,
  setPoolVesselGroupsEpic,
  addVesselToGroupEpic,
  removeVesselFromGroupEpic,
);
