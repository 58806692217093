import { ActionCreator } from '../../store/actionCreator';

const POOL_COMMENTS_NAME = 'POOL_COMMENTS';

export const POOL_COMMENTS = {
  ADD_ITEM: 'POOL_COMMENTS_ADD_ITEM',
  FETCH_COLLECTION: 'POOL_COMMENTS_FETCH_COLLECTION',
  REMOVE_ITEM: 'POOL_COMMENTS_REMOVE_ITEM',
  SET_POOL: 'POOL_COMMENTS_SET_POOL',
  SET_COLLECTION: 'POOL_COMMENTS_SET_COLLECTION',
  UPDATE_ITEM: 'POOL_COMMENTS_UPDATE_ITEM',
};

export const PoolCommentsAction = new ActionCreator(POOL_COMMENTS_NAME);
