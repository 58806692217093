import { authenticationActionTypes } from 'app/authentication/actions/AuthenticationActions';

const initialState = {
  isUserAuthenticated: false,
  user: {},
};

const authenticationReducer = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case authenticationActionTypes.USER_LOGGED_IN:
      return {
        ...state,
        isUserAuthenticated: true,
      };
    case authenticationActionTypes.USER_CREATED:
      return {
        ...state,
        user: action.user,
      };
    case authenticationActionTypes.USER_LOG_OUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export { authenticationReducer };
