import { map } from 'rxjs/operators';
import { newsToApi, newsToView } from '../mappers/newsMappers';

class NewsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getNews(id) {
    return this.apiClient.getNews(id).pipe(
      map((news) => newsToView(news)),
    );
  }

  addNews(news) {
    return this.apiClient.postNews(newsToApi(news));
  }

  updateNews(news) {
    return this.apiClient.updateNews(newsToApi(news));
  }

  removeNews(newsId) {
    return this.apiClient.deleteNews(newsId);
  }

  getNewsCollection() {
    return this.apiClient.getNewsCollection().pipe(
      map((newsCollection) => newsCollection.map((news) => newsToView(news))),
    );
  }

  getNewsPoolCollection(poolId) {
    return this.apiClient.getNewsPoolCollection(poolId).pipe(
      map((newsCollection) => newsCollection.map((news) => newsToView(news))),
    );
  }
}

export default NewsService;
