import Excel from 'exceljs';
import FileSaver from 'file-saver';
import { formatKebabDate, formatShortMonthWithYear } from '../../helpers/datetime';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export function createFileName(prefix, fileName, suffix) {
  return `${prefix}${fileName || ''}-${suffix ? `${suffix}-` : ''}${formatKebabDate(new Date())}.xlsx`;
}

function createXlsCoreObject() {
  const workbook = new Excel.Workbook();

  workbook.creator = 'InSite Digital Admin Panel';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.properties.date1904 = true;

  return workbook;
}

function getFormattedRows(columns, rows) {
  const rowsCopy = [ ...rows ];

  // eslint-disable-next-line no-restricted-syntax
  for (const column of columns) {
    if (column.type === 'date') {
      for (let rowIndex = 0; rowIndex < rowsCopy.length; rowIndex += 1) {
        const rowCopy = { ...rowsCopy[rowIndex] };
        rowCopy[column.key] = formatShortMonthWithYear(rowCopy[column.key]);
        rowsCopy[rowIndex] = rowCopy;
      }
    }
  }

  return rowsCopy;
}

function fillXlsWithData(payload) {
  const {
    workbook, isShipOwnerFilter, mainRow, secondMainRow, rows, worksheetName, columnsStyle,
  } = payload;
  const worksheet = workbook.addWorksheet(worksheetName || (mainRow ? mainRow.name : ''));

  worksheet.columns = columnsStyle || [];
  worksheet.getRow(1).font = { bold: true };

  if (mainRow) {
    worksheet.addRow(mainRow);
    worksheet.getRow(2).font = { color: { argb: 'FF2CD5C4' }, bold: true };
  }

  if (isShipOwnerFilter) {
    worksheet.addRow(secondMainRow);
    worksheet.getRow(3).font = { bold: true };
  }

  const formattedRows = getFormattedRows(columnsStyle, rows);
  worksheet.addRows(formattedRows);
}

function getFileNamePrefix(mainRow, secondMainRow) {
  let fileName = `${mainRow ? mainRow.name : ''}`;
  fileName += `${secondMainRow && secondMainRow.name ? `-${secondMainRow.name}` : ''}`;

  return fileName;
}

export async function createXlsxFile(
  rows,
  config,
  fileName,
  fileSuffix = '',
  mainRow = undefined,
  secondMainRow = undefined,
  worksheetName = '',
) {
  const isShipOwnerFilter = secondMainRow && Object.keys(secondMainRow).length !== 0;
  const fileNamePrefix = getFileNamePrefix(mainRow, secondMainRow);
  const finalFileName = createFileName(fileNamePrefix, fileName, fileSuffix);
  const workbook = createXlsCoreObject();

  const xlsData = {
    workbook,
    isShipOwnerFilter,
    mainRow,
    secondMainRow,
    rows,
    worksheetName,
    columnsStyle: config,
  };
  fillXlsWithData(xlsData);

  const excelBuffer = await workbook.xlsx.writeBuffer(finalFileName);
  const data = new Blob([ excelBuffer ], { type: fileType });

  FileSaver.saveAs(data, finalFileName, { autoBom: true });
}

export default {
  createXlsxFile,
};
