export const CONDITIONS_PARAMETERS = {
  SET_COLLECTION: 'CONDITIONS_PARAMETERS_SET_COLLECTION',
  RESET_COLLECTION: 'CONDITIONS_PARAMETERS_RESET_COLLECTION',
  FETCH_COLLECTION_REQUEST: 'CONDITIONS_PARAMETERS_COLLECTION_REQUEST',
  FETCH_COLLECTION_SUCCESS: 'CONDITIONS_PARAMETERS_COLLECTION_SUCCESS',
  FETCH_COLLECTION_FAILURE: 'CONDITIONS_PARAMETERS_COLLECTION_FAILURE',
  FETCH_COLLECTION_SELECTOR: 'CONDITIONS_PARAMETERS_COLLECTION',
  ADD_COLLECTION_ITEM: 'CONDITIONS_PARAMETERS_ADD_COLLECTION_ITEM',
  ADD_COLLECTION_ITEM_REQUEST: 'CONDITIONS_PARAMETERS_ADD_COLLECTION_ITEM_REQUEST',
  ADD_COLLECTION_ITEM_SUCCESS: 'CONDITIONS_PARAMETERS_ADD_COLLECTION_ITEM_SUCCESS',
  ADD_COLLECTION_ITEM_FAILURE: 'CONDITIONS_PARAMETERS_ADD_COLLECTION_ITEM_FAILURE',
  CHANGE_COLLECTION_ITEM: 'CONDITIONS_PARAMETERS_CHANGE_COLLECTION_ITEM',
  CHANGE_COLLECTION_ITEM_REQUEST: 'CONDITIONS_PARAMETERS_CHANGE_COLLECTION_ITEM_REQUEST',
  CHANGE_COLLECTION_ITEM_SUCCESS: 'CONDITIONS_PARAMETERS_CHANGE_COLLECTION_ITEM_SUCCESS',
  CHANGE_COLLECTION_ITEM_FAILURE: 'CONDITIONS_PARAMETERS_CHANGE_COLLECTION_ITEM_FAILURE',
  DELETE_COLLECTION_ITEM: 'CONDITIONS_PARAMETERS_DELETE_COLLECTION_ITEM',
  DELETE_COLLECTION_ITEM_REQUEST: 'CONDITIONS_PARAMETERS_DELETE_COLLECTION_ITEM_REQUEST',
  DELETE_COLLECTION_ITEM_SUCCESS: 'CONDITIONS_PARAMETERS_DELETE_COLLECTION_ITEM_SUCCESS',
  DELETE_COLLECTION_ITEM_FAILURE: 'CONDITIONS_PARAMETERS_DELETE_COLLECTION_ITEM_FAILURE',
  PREPARE_NEW_COLLECTION_ITEM: 'CONDITIONS_PARAMETERS_PREPARE_NEW_COLLECTION_ITEM',
  REMOVE_NEW_COLLECTION_ITEM: 'CONDITIONS_PARAMETERS_REMOVE_NEW_COLLECTION_ITEM',
  RESET_NEW_COLLECTION: 'CONDITIONS_PARAMETERS_RESET_NEW_COLLECTION',
};

export const ConditionsParametersActions = {

  setCollection: (payload) => ({
    type: CONDITIONS_PARAMETERS.SET_COLLECTION,
    payload,
  }),

  resetCollection: () => ({
    type: CONDITIONS_PARAMETERS.RESET_COLLECTION,
  }),

  fetchCollectionRequest: (payload) => ({
    type: CONDITIONS_PARAMETERS.FETCH_COLLECTION_REQUEST,
    payload,
  }),

  fetchCollectionSuccess: () => ({
    type: CONDITIONS_PARAMETERS.FETCH_COLLECTION_SUCCESS,
  }),

  fetchCollectionFailure: (payload) => ({
    type: CONDITIONS_PARAMETERS.FETCH_COLLECTION_FAILURE,
    payload,
  }),

  addCollectionItem: (payload) => ({
    type: CONDITIONS_PARAMETERS.ADD_COLLECTION_ITEM,
    payload,
  }),

  addCollectionItemRequest: (payload) => ({
    type: CONDITIONS_PARAMETERS.ADD_COLLECTION_ITEM_REQUEST,
    payload,
  }),

  addCollectionItemFailure: (payload) => ({
    type: CONDITIONS_PARAMETERS.ADD_COLLECTION_ITEM_FAILURE,
    payload,
  }),

  addCollectionItemSuccess: () => ({
    type: CONDITIONS_PARAMETERS.ADD_COLLECTION_ITEM_SUCCESS,
  }),

  changeCollectionItem: (payload) => ({
    type: CONDITIONS_PARAMETERS.CHANGE_COLLECTION_ITEM,
    payload,
  }),

  changeCollectionItemRequest: (payload) => ({
    type: CONDITIONS_PARAMETERS.CHANGE_COLLECTION_ITEM_REQUEST,
    payload,
  }),

  changeCollectionItemFailure: (payload) => ({
    type: CONDITIONS_PARAMETERS.CHANGE_COLLECTION_ITEM_FAILURE,
    payload,
  }),

  changeCollectionItemSuccess: () => ({
    type: CONDITIONS_PARAMETERS.CHANGE_COLLECTION_ITEM_SUCCESS,
  }),

  deleteCollectionItem: (payload) => ({
    type: CONDITIONS_PARAMETERS.DELETE_COLLECTION_ITEM,
    payload,
  }),

  deleteCollectionItemRequest: (payload) => ({
    type: CONDITIONS_PARAMETERS.DELETE_COLLECTION_ITEM_REQUEST,
    payload,
  }),

  deleteCollectionItemFailure: (payload) => ({
    type: CONDITIONS_PARAMETERS.DELETE_COLLECTION_ITEM_FAILURE,
    payload,
  }),

  deleteCollectionItemSuccess: () => ({
    type: CONDITIONS_PARAMETERS.DELETE_COLLECTION_ITEM_SUCCESS,
  }),

  prepareNewCollectionItem: (payload) => ({
    type: CONDITIONS_PARAMETERS.PREPARE_NEW_COLLECTION_ITEM,
    payload,
  }),

  removeNewCollectionItem: (payload) => ({
    type: CONDITIONS_PARAMETERS.REMOVE_NEW_COLLECTION_ITEM,
    payload,
  }),

  resetNewCollection: () => ({
    type: CONDITIONS_PARAMETERS.RESET_NEW_COLLECTION,
  }),
};
