export default class VesselsService {
  constructor(apiService) {
    this.api = apiService;
  }

  getAll() {
    return this.api.getAllVessels();
  }

  getVessel(vesselId) {
    return this.api.getVessel(vesselId);
  }

  update(vesselId, vessel) {
    return this.api.updateVessel(vesselId, vessel);
  }

  getDetails(imo) {
    return this.api.getVesselDetails(imo);
  }

  delete(vesselId) {
    return this.api.deleteVessel(vesselId);
  }

  create(vessel) {
    return this.api.createVessel(vessel);
  }
}
