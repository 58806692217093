const toLocationPath = (locationChangedAction) => locationChangedAction.payload.location.pathname;

const toLocationChangeContext = (locationChangedAction) => {
  const { pathname, search, hash } = locationChangedAction.payload.location;
  return {
    path: pathname,
    search,
    hash,
  };
};

export { toLocationPath, toLocationChangeContext };
