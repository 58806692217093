import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  map,
  filter,
  mergeMap,
  tap,
  ignoreElements,
} from 'rxjs/operators';
import { get, isNull } from 'lodash';
import { routingActionCreators, routingActionTypes } from 'app/routing/actions/RoutingActions';
import { PoolsActions } from 'app/pools/actions/PoolsActions';
import { toast } from 'react-toastify';
import { FORECAST_SETTINGS, ForecastSettingsAction } from 'app/forecastSettings/actions/ForecastSettingsActions';
import { ROUTER } from 'app/routing/routes';
import { forecastSettingsRoute } from 'app/routing/routeConstants';

export const enteredForecastSettingsPage = (action$, state$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_FORECAST_SETTINGS_PAGE),
  map(() => get(state$.value, 'pools.currentPool', null)),
  map((currentPool) => [
    PoolsActions.fetchCollectionRequest(),
    ForecastSettingsAction.action(FORECAST_SETTINGS.SET_POOL, currentPool || 1),
  ])
);

export const setPoolForecastSettingsEpic = (action$) => action$.pipe(
  ofType(ForecastSettingsAction.action(FORECAST_SETTINGS.SET_POOL).type),
  filter((action) => !isNull(action.payload)),
  map((action) => [
    PoolsActions.setPool(action.payload),
    ForecastSettingsAction.request(FORECAST_SETTINGS.GET_FORECASTS, action.payload),
    ForecastSettingsAction.request(FORECAST_SETTINGS.GET_THRESHOLD, action.payload),
  ])
);

export const getThresholdEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(ForecastSettingsAction.request(FORECAST_SETTINGS.GET_THRESHOLD).type),
  mergeMap((action) => Services.forecast.getThreshold(action.payload).pipe(
    map((threshold) => [
      ForecastSettingsAction.success(FORECAST_SETTINGS.GET_THRESHOLD, threshold),
    ]),
    catchError(() => [
      ForecastSettingsAction.failure(FORECAST_SETTINGS.GET_THRESHOLD),
    ])
  )),
);

export const getForecastsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(ForecastSettingsAction.request(FORECAST_SETTINGS.GET_FORECASTS).type),
  mergeMap((action) => Services.forecast.getForecasts(action.payload).pipe(
    map((forecasts) => [
      ForecastSettingsAction.success(FORECAST_SETTINGS.GET_FORECASTS, forecasts),
    ]),
    catchError(() => [
      ForecastSettingsAction.failure(FORECAST_SETTINGS.GET_FORECASTS),
    ])
  )),
);

export const updateThresholdEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(ForecastSettingsAction.request(FORECAST_SETTINGS.UPDATE_THRESHOLD).type),
  map((action) => {
    const { poolId, threshold } = action.payload;
    return [
      poolId, threshold,
    ];
  }),
  mergeMap(([ poolId, threshold ]) => Services.forecast.updateThreshold(poolId, threshold).pipe(
    map((updatedThreshold) => [
      ForecastSettingsAction.success(FORECAST_SETTINGS.UPDATE_THRESHOLD, updatedThreshold),
    ]),
    catchError(() => [
      ForecastSettingsAction.failure(FORECAST_SETTINGS.UPDATE_THRESHOLD),
    ])
  )),
);

export const updateThresholdSuccessEpic = (action$) => action$.pipe(
  ofType(
    ForecastSettingsAction.success(FORECAST_SETTINGS.UPDATE_THRESHOLD).type,
  ),
  tap(() => toast.success('Updated successfully')),
  ignoreElements(),
);

export const addForecastsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(ForecastSettingsAction.request(FORECAST_SETTINGS.ADD_FORECASTS).type),
  map((action) => {
    const { poolId, forecasts } = action.payload;
    return [
      poolId, forecasts,
    ];
  }),
  mergeMap(([ poolId, forecasts ]) => Services.forecast.addForecasts(poolId, forecasts).pipe(
    map(() => [
      ForecastSettingsAction.success(FORECAST_SETTINGS.ADD_FORECASTS),
    ]),
    catchError(() => [
      ForecastSettingsAction.failure(FORECAST_SETTINGS.ADD_FORECASTS),
    ])
  )),
);

export const addForecastSuccessEpic = (action$) => action$.pipe(
  ofType(
    ForecastSettingsAction.success(FORECAST_SETTINGS.ADD_FORECASTS).type,
  ),
  tap(() => toast.success('Added successfully')),
  map(() => routingActionCreators.push(ROUTER.generate(forecastSettingsRoute))),
);

export const addForecastFailureEpic = (action$) => action$.pipe(
  ofType(
    ForecastSettingsAction.failure(FORECAST_SETTINGS.ADD_FORECASTS).type,
  ),
  tap(() => toast.error('Something went wrong')),
  ignoreElements(),
);

export const updateForecastEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(ForecastSettingsAction.request(FORECAST_SETTINGS.UPDATE_FORECAST).type),
  map((action) => {
    const { forecast } = action.payload;
    return forecast;
  }),
  mergeMap((forecast) => Services.forecast.updateForecast(forecast).pipe(
    map(() => [
      ForecastSettingsAction.success(FORECAST_SETTINGS.UPDATE_FORECAST),
    ]),
    catchError(() => [
      ForecastSettingsAction.failure(FORECAST_SETTINGS.UPDATE_FORECAST),
    ])
  )),
);

export const updateForecastSuccessEpic = (action$) => action$.pipe(
  ofType(
    ForecastSettingsAction.success(FORECAST_SETTINGS.UPDATE_FORECAST).type,
  ),
  tap(() => toast.success('Updated successfully')),
  map(() => routingActionCreators.push(ROUTER.generate(forecastSettingsRoute))),
);

export const updateForecastFailureEpic = (action$) => action$.pipe(
  ofType(
    ForecastSettingsAction.failure(FORECAST_SETTINGS.UPDATE_FORECAST).type,
  ),
  tap(() => toast.error('Something went wrong')),
  ignoreElements(),
);

export const removeForecastEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(ForecastSettingsAction.request(FORECAST_SETTINGS.REMOVE_FORECAST).type),
  map((action) => {
    const { poolId, forecastId } = action.payload;
    return [
      poolId, forecastId,
    ];
  }),
  mergeMap(([ poolId, forecastId ]) => Services.forecast.removeForecast(poolId, forecastId).pipe(
    map(() => [
      ForecastSettingsAction.success(FORECAST_SETTINGS.REMOVE_FORECAST),
    ]),
    catchError(() => [
      ForecastSettingsAction.failure(FORECAST_SETTINGS.REMOVE_FORECAST),
    ])
  )),
);

export const removeForecastSuccessEpic = (action$) => action$.pipe(
  ofType(
    ForecastSettingsAction.success(FORECAST_SETTINGS.REMOVE_FORECAST).type,
  ),
  tap(() => toast.success('Removed successfully')),
  map(() => routingActionCreators.push(ROUTER.generate(forecastSettingsRoute))),
);

export const removeForecastFailureEpic = (action$) => action$.pipe(
  ofType(
    ForecastSettingsAction.failure(FORECAST_SETTINGS.REMOVE_FORECAST).type,
  ),
  tap(() => toast.error('Something went wrong')),
  ignoreElements(),
);

export const forecastSettingsEpics = combineEpics(
  enteredForecastSettingsPage,
  setPoolForecastSettingsEpic,
  getThresholdEpic,
  updateThresholdEpic,
  updateThresholdSuccessEpic,
  getForecastsEpic,
  addForecastsEpic,
  addForecastSuccessEpic,
  addForecastFailureEpic,
  updateForecastEpic,
  updateForecastSuccessEpic,
  updateForecastFailureEpic,
  removeForecastEpic,
  removeForecastSuccessEpic,
  removeForecastFailureEpic,
);
