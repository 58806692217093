import { ActionCreator } from 'app/store/actionCreator';

export const BENCHMARK_RATES_NAME = 'BENCHMARK_RATES';

export const BENCHMARK_RATES = {
  GET_ALL: `${BENCHMARK_RATES_NAME}_GET_ALL`,
  SET_ACTIVE_POOL: `${BENCHMARK_RATES_NAME}_SET_ACTIVE_POOL`,
  CREATE_BENCHMARK: `${BENCHMARK_RATES_NAME}_CREATE_BENCHMARK_RATE`,
  UPDATE_BENCHMARK: `${BENCHMARK_RATES_NAME}_UPDATE_BENCHMARK_RATE`,
  REMOVE_BENCHMARK: `${BENCHMARK_RATES_NAME}_REMOVE_BENCHMARK_RATE`,
};

export const BenchmarkRatesAction = new ActionCreator(BENCHMARK_RATES_NAME);
