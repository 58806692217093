import { ActionCreator } from 'app/store/actionCreator';

const VESSEL_GROUPS_NAME = 'VESSEL_GROUPS';

export const VESSEL_GROUPS = {
  FETCH_COLLECTION: 'VESSEL_GROUPS_FETCH_COLLECTION',
  FETCH_GROUPS: 'VESSEL_GROUPS_FETCH_GROUPS',
  UPDATE_ITEM: 'VESSEL_GROUPS_UPDATE_ITEM',
  REMOVE_ITEM: 'VESSEL_GROUPS_REMOVE_ITEM',
  SET_POOL: 'VESSEL_GROUPS_SET_POOL',
  SET_COLLECTION: 'VESSEL_GROUPS_SET_COLLECTION',
  SET_GROUPS: 'VESSEL_GROUPS_SET_GROUPS',
};

export const VesselGroupsAction = new ActionCreator(VESSEL_GROUPS_NAME);
