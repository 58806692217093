class BunkerConsumptionService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getBunkerConsumptionConditionsParameters(poolId) {
    return this.apiClient.getBunkerConsumptionConditionsParameters(poolId);
  }

  postBunkerConsumptionConditionsParameters(quarter) {
    return this.apiClient.postBunkerConsumptionConditionsParameters(quarter);
  }

  putBunkerConsumptionConditionsParameters(quarter) {
    return this.apiClient.putBunkerConsumptionConditionsParameters(quarter);
  }

  deleteBunkerConsumptionConditionsParameters(quarterId) {
    return this.apiClient.deleteBunkerConsumptionConditionsParameters(quarterId);
  }

  getBunkerConsumptionConditionsWeights(poolId) {
    return this.apiClient.getBunkerConsumptionConditionsWeights(poolId);
  }

  postBunkerConsumptionConditionsWeights(quarter) {
    return this.apiClient.postBunkerConsumptionConditionsWeights(quarter);
  }

  putBunkerConsumptionConditionsWeights(quarter) {
    return this.apiClient.putBunkerConsumptionConditionsWeights(quarter);
  }

  deleteBunkerConsumptionConditionsWeights(quarterId) {
    return this.apiClient.deleteBunkerConsumptionConditionsWeights(quarterId);
  }
}

export default BunkerConsumptionService;
