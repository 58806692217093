export const transformToViewModel = (welcomePopup) => {
  const mapToViewModel = (features) => features.map(f => ({
    text: f,
    isValid: true,
  }));

  return {
    ...welcomePopup,
    changelog: mapToViewModel(welcomePopup.changelog),
    incomingFeatures: mapToViewModel(welcomePopup.incomingFeatures),
  };
};

export const transformFromViewModel = (welcomePopup) => {
  const mapFromViewModel = (features) => features.map(f => f.text);

  return {
    ...welcomePopup,
    changelog: mapFromViewModel(welcomePopup.changelog),
    incomingFeatures: mapFromViewModel(welcomePopup.incomingFeatures),
  };
};
