export const BUNKER_CONSUMPTION_SETTINGS = {
  INITIALIZE: 'BUNKER_CONSUMPTION_INITIALIZE',
  SET_CURRENT_POOL: 'BUNKER_CONSUMPTION_SETTINGS_CURRENT_POOL_SET',
  RESET_CURRENT_POOL: 'BUNKER_CONSUMPTION_SETTINGS_CURRENT_POOL_RESET',
};

export const BunkerConsumptionSettingsActions = {
  initialize: () => ({
    type: BUNKER_CONSUMPTION_SETTINGS.INITIALIZE,
  }),
  setCurrentPool: (payload) => ({
    type: BUNKER_CONSUMPTION_SETTINGS.SET_CURRENT_POOL,
    payload,
  }),
  resetCurrentPool: () => ({
    type: BUNKER_CONSUMPTION_SETTINGS.RESET_CURRENT_POOL,
  }),
};
