export const ERROR_BOUNDARIES = {
  THROW_ERROR: 'THROW_ERROR',
};

export const ERROR_STATUS = {
  NOT_AUTHORIZED: 401,
};

export const ErrorBoundariesActions = {
  throwError: (payload) => ({
    type: ERROR_BOUNDARIES.THROW_ERROR,
    payload,
  }),
};
