import { VESSEL_PARTNER_REPORT, VesselPartnerReportAction } from '../actions/vesselPartnerReportActions';

export const initialPoolsState = {
  report: [],
};

const vesselPartnerReportReducer = (state = initialPoolsState, action = { type: '' }) => {
  switch (action.type) {
    case VesselPartnerReportAction.success(VESSEL_PARTNER_REPORT.GET_VESSEL_PARTNER_REPORT).type:
      return {
        ...state,
        report: action.payload,
      };

    default:
      return state;
  }
};

export { vesselPartnerReportReducer };
