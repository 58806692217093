import { maintenanceActionTypes } from 'app/maintenance/actions/MaintanceStatusActions';

const initialState = {
  maintenanceStatus: {
    isFetching: false,
    isInMaintenance: false,
  },
  maintenanceStatusHistory: {
    isFetching: false,
    maintenanceHistory: [],
  },
  clearCacheStatus: {
    isFetching: false,
  },
};

const maintenanceStatusReducer = (state = initialState, action = { type: '' }) => {
  switch (action.type) {
    case maintenanceActionTypes.REQUEST_LAST_MAINTENANCE_STATUS:
      return {
        ...state,
        maintenanceStatus: {
          ...state.maintenanceStatus,
          isFetching: true,
        },
      };
    case maintenanceActionTypes.RECEIVE_LAST_MAINTENANCE_STATUS:
      return {
        ...state,
        maintenanceStatus: {
          ...state.maintenanceStatus,
          isFetching: false,
          isInMaintenance: action.maintenanceStatus.isInMaintenance,
        },
      };
    case maintenanceActionTypes.REQUEST_MAINTENANCE_STATUS_HISTORY:
      return {
        ...state,
        maintenanceStatusHistory: {
          ...state.maintenanceStatusHistory,
          isFetching: true,
        },
      };
    case maintenanceActionTypes.RECEIVE_MAINTENANCE_STATUS_HISTORY:
      return {
        ...state,
        maintenanceStatusHistory: {
          ...state.maintenanceStatusHistory,
          isFetching: false,
          maintenanceHistory: action.maintenanceStatusHistory,
        },
      };
    case maintenanceActionTypes.REQUEST_CLEAR_CACHE_MAINTENANCE:
      return {
        ...state,
        clearCacheStatus: {
          ...state.clearCacheStatus,
          isFetching: true,
        },
      };
    case maintenanceActionTypes.RESET_CLEAR_CACHE_MAINTENANCE:
      return {
        ...state,
        clearCacheStatus: initialState.clearCacheStatus,
      };
    case maintenanceActionTypes.RECEIVE_CLEAR_CACHE_MAINTENANCE_STATUS:
      return {
        ...state,
        clearCacheStatus: {
          ...state.clearCacheStatus,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export { maintenanceStatusReducer };
