import { ActionCreator } from 'app/store/actionCreator';

export const ETL_PROCESS_NAME = 'ETL_PROCESS';

export const ETL_PROCESS = {
  START: 'START_ETL_PROCESS',
  START_SINGLE: 'START_SINGLE_ETL_PROCESS',
  GET_STATUS: 'GET_ETL_PROCESS_STATUS',
  GET_LOGS: 'GET_ETL_PROCESS_LOGS',
  SET_STATUS: 'SET_ETL_PROCESS_STATUS',
};

export const EtlProcessAction = new ActionCreator(ETL_PROCESS_NAME);
