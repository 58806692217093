export default class ContactService {
  constructor(apiService) {
    this.api = apiService;
  }

  getContact() {
    return this.api.getContact();
  }

  createContactDepartment(department) {
    return this.api.createContactDepartment(department);
  }

  deleteContactDepartment(departmentId) {
    return this.api.deleteContactDepartment(departmentId);
  }

  updateContactDepartment(departmentId, department) {
    return this.api.updateContactDepartment(departmentId, department);
  }

  createContactPerson(departmentId, person) {
    return this.api.createContactPerson(departmentId, ContactService.createPersonFormData(person));
  }

  deleteContactPerson(departmentId, personId) {
    return this.api.deleteContactPerson(departmentId, personId);
  }

  updateContactPerson(departmentId, data) {
    return this.api.updateContactPerson(departmentId, data.person.id, ContactService.createPersonFormData(data.person, true), data.deleteImage);
  }

  getContactPerson(departmentId, personId) {
    return this.api.getContactPerson(departmentId, personId);
  }

  updateContactDepartmentsOrder(departmentIdsInOrder) {
    return this.api.updateContactDepartmentsOrder(departmentIdsInOrder);
  }

  updateContactPersonsOrder(departmentId, personIdsInOrder) {
    return this.api.updateContactPersonsOrder(departmentId, personIdsInOrder);
  }

  static createPersonFormData(person, isUpdate = false) {
    const contentKeyName = isUpdate ? 'UpdateContactPersonContent' : 'CreateContactPersonContent';
    const formData = new FormData();

    const personData = {
      name: person.name,
      title: person.title,
      email: person.email,
      phoneNumber: person.phoneNumber,
      poolTitle: person.poolTitle,
      contactDepartmentId: person.contactDepartmentId,
    };

    formData.append('File', person.imageFile);
    formData.append(contentKeyName, JSON.stringify(personData));

    return formData;
  }
}
