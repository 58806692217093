import { map } from 'rxjs/operators';

class PoolCommentsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  static createComment(poolId, comments) {
    return comments.map((comment) => ({
      id: comment.id,
      comment: comment.comment,
      relevantDate: comment.relevantDate,
      publishDate: comment.publishDate,
      poolId,
      isUpdated: false,
    }));
  }

  getComments(poolId) {
    return this.apiClient.getPoolComments(poolId).pipe(
      map((comments) => PoolCommentsService.createComment(poolId, comments)),
    );
  }

  addComment(comment) {
    return this.apiClient.addPoolComment(comment);
  }

  updateComment(comment) {
    return this.apiClient.updatePoolComment(comment);
  }

  deleteComment(comment) {
    return this.apiClient.deletePoolComment(comment);
  }
}

export default PoolCommentsService;
