import { ActionCreator } from '../../store/actionCreator';

const DATA_VALIDATION_RULES_NAME = 'DATA_VALIDATION_RULES';

export const DATA_VALIDATION_RULES = {
  ADD_ITEM: 'DATA_VALIDATION_RULES_ADD_ITEM',
  FETCH_COLLECTION: 'DATA_VALIDATION_RULES_FETCH_COLLECTION',
  REMOVE_ITEM: 'DATA_VALIDATION_RULES_REMOVE_ITEM',
  UPDATE_ITEM: 'DATA_VALIDATION_RULES_UPDATE_ITEM',
};

export const DataValidationRulesAction = new ActionCreator(DATA_VALIDATION_RULES_NAME);
