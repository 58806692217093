class PoolsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getPools() {
    return this.apiClient.getPoolsWithDetails();
  }

  getPool(poolId) {
    return this.apiClient.getPool(poolId);
  }

  createPool(pool) {
    return this.apiClient.postPool(pool);
  }

  updatePool(pool) {
    return this.apiClient.putPool(pool);
  }

  deletePool(poolId) {
    return this.apiClient.deletePool(poolId);
  }
}

export default PoolsService;
