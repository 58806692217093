import { combineEpics, ofType } from 'redux-observable';
import {
  catchError, ignoreElements, map, mergeMap, tap,
} from 'rxjs/operators';
import { routingActionTypes } from 'app/routing/actions/RoutingActions';
import { toast } from 'react-toastify';
import { routingActionCreators } from '../../../routing/actions/RoutingActions';
import { ROUTER } from '../../../routing/routes';
import { VESSELS, VesselsAction } from './vesselsActions';
import { vesselsRoute } from '../../../routing/routeConstants';

export const enteredVesselsEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_VESSELS_PAGE),
  map(() => [
    VesselsAction.request(VESSELS.GET_ALL),
  ]),
);

export const enteredCreateVesselEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_CREATE_VESSEL_PAGE),
  map(() => [
    VesselsAction.action(VESSELS.RESET_EDIT_VESSEL),
  ]),
);

export const enteredEditVesselEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_EDIT_VESSEL_PAGE),
  map(action => ({
    vesselId: Number(ROUTER.lookup(action.path).options.vesselId),
  })),
  map((vesselId) => [
    VesselsAction.action(VESSELS.RESET_EDIT_VESSEL),
    VesselsAction.request(VESSELS.GET_EDIT_VESSEL, vesselId),
  ]),
);

export const getEditVesselEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(VesselsAction.request(VESSELS.GET_EDIT_VESSEL).type),
  mergeMap((action) => Services.vessels.getVessel(action.payload.vesselId).pipe(
    map((vessel) => [
      VesselsAction.success(VESSELS.GET_EDIT_VESSEL, vessel),
    ]),
    catchError((error) => [
      VesselsAction.failure(VESSELS.GET_EDIT_VESSEL, error),
    ]),
  )),
);

export const getAllEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(VesselsAction.request(VESSELS.GET_ALL).type),
  mergeMap(() => Services.vessels.getAll().pipe(
    map((vessels) => [
      VesselsAction.success(VESSELS.GET_ALL, vessels),
    ]),
    catchError((error) => [
      VesselsAction.failure(VESSELS.GET_ALL, error),
    ]),
  )),
);

export const getAutoCompleteVesselDetailsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(VesselsAction.request(VESSELS.GET_AUTOCOMPLETE_VESSEL_DETAILS).type),
  mergeMap((action) => Services.vessels.getDetails(action.payload).pipe(
    map((details) => [
      VesselsAction.success(VESSELS.GET_AUTOCOMPLETE_VESSEL_DETAILS, details),
    ]),
    catchError((error) => [
      VesselsAction.failure(VESSELS.GET_AUTOCOMPLETE_VESSEL_DETAILS, error),
    ]),
  )),
);

export const createVesselEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(VesselsAction.request(VESSELS.CREATE_VESSEL).type),
  mergeMap((action) => Services.vessels.create(action.payload).pipe(
    map(() => [
      VesselsAction.success(VESSELS.CREATE_VESSEL, 'Vessel created successfully'),
      VesselsAction.request(VESSELS.GET_ALL),
      routingActionCreators.push(ROUTER.generate(vesselsRoute)),
    ]),
    catchError((error) => {
      let actions = [
        VesselsAction.failure(VESSELS.CREATE_VESSEL, error),
      ];

      if (error.status === 409) {
        actions = [
          VesselsAction.failure(VESSELS.CREATE_VESSEL, error.response.message),
        ]
      }

      return actions;
    }),
  )),
);

export const updateVesselEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(VesselsAction.request(VESSELS.UPDATE_VESSEL).type),
  mergeMap((action) => Services.vessels.update(action.payload.vesselId, action.payload.vessel).pipe(
    map(() => [
      VesselsAction.success(VESSELS.UPDATE_VESSEL, 'Vessel updated successfully'),
      VesselsAction.request(VESSELS.GET_ALL),
      routingActionCreators.push(ROUTER.generate(vesselsRoute)),
    ]),
    catchError((error) => {
      let actions = [
        VesselsAction.failure(VESSELS.UPDATE_VESSEL, error),
      ];

      if (error.status === 409) {
        actions = [
          VesselsAction.failure(VESSELS.UPDATE_VESSEL, error.response.message),
        ]
      }

      return actions;
    }),
  )),
);

export const deleteVesselEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(VesselsAction.request(VESSELS.DELETE_VESSEL).type),
  mergeMap((action) => Services.vessels.delete(action.payload).pipe(
    map(() => [
      VesselsAction.success(VESSELS.DELETE_VESSEL),
      VesselsAction.request(VESSELS.GET_ALL),
    ]),
    catchError((error) => [
      VesselsAction.failure(VESSELS.DELETE_VESSEL, error),
    ]),
  )),
);

export const succcessToastEpic = (action$) => action$.pipe(
  ofType(
    VesselsAction.success(VESSELS.CREATE_VESSEL).type,
    VesselsAction.success(VESSELS.UPDATE_VESSEL).type,
  ),
  tap((action) => toast.success(action.payload)),
  ignoreElements(),
);

export const failureToastEpic = (action$) => action$.pipe(
  ofType(
    VesselsAction.failure(VESSELS.GET_ALL).type,
    VesselsAction.failure(VESSELS.GET_AUTOCOMPLETE_VESSEL_DETAILS).type,
    VesselsAction.failure(VESSELS.GET_EDIT_VESSEL).type,
    VesselsAction.failure(VESSELS.CREATE_VESSEL).type,
    VesselsAction.failure(VESSELS.UPDATE_VESSEL).type,
  ),
  tap((action) => toast.error(action.payload)),
  ignoreElements(),
);

export const vesselsEpic = combineEpics(
  enteredVesselsEpic,
  enteredCreateVesselEpic,
  enteredEditVesselEpic,
  getAllEpic,
  getEditVesselEpic,
  getAutoCompleteVesselDetailsEpic,
  createVesselEpic,
  updateVesselEpic,
  deleteVesselEpic,
  succcessToastEpic,
  failureToastEpic,
);
