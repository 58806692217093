import { PoolsActions } from 'app/pools/actions/PoolsActions';
import { routingActionCreators, routingActionTypes } from 'app/routing/actions/RoutingActions';
import { poolPartnersRoute } from 'app/routing/routeConstants';
import { ROUTER } from 'app/routing/routes';
import { toast } from 'react-toastify';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError, ignoreElements, map, mergeMap, tap
} from 'rxjs/operators';
import { PoolPartnersAction, POOL_PARTNERS } from '../actions/poolPartnersActions';

export const enteredPoolPartnersEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_POOL_PARTNERS_PAGE),
  map(() => [
    PoolPartnersAction.request(POOL_PARTNERS.GET_COLLECTION),
    PoolsActions.fetchCollectionRequest(),
  ]),
);

export const enteredCreatePoolPartnerPageEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_CREATE_POOL_PARTNER_PAGE),
  map(() => [
    PoolPartnersAction.action(POOL_PARTNERS.RESET_POOL_PARTNER_CONTEXT),
    PoolsActions.fetchCollectionRequest(),
  ]),
);

export const enteredEditPoolPartnerPageEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_EDIT_POOL_PARTNER_PAGE),
  map((action) => Number(ROUTER.lookup(action.path).options.poolPartnerId)),
  map((poolPartnerId) => [
    PoolPartnersAction.action(POOL_PARTNERS.RESET_POOL_PARTNER_CONTEXT),
    PoolPartnersAction.request(POOL_PARTNERS.GET_POOL_PARTNER, poolPartnerId),
    PoolsActions.fetchCollectionRequest(),
  ]),
);

export const getPoolPartnersEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(PoolPartnersAction.request(POOL_PARTNERS.GET_COLLECTION).type),
  mergeMap((action) => Services.poolPartners.getPoolPartners(action.payload).pipe(
    map((poolPartners) => PoolPartnersAction.success(POOL_PARTNERS.GET_COLLECTION, poolPartners)),
    catchError(() => PoolPartnersAction.failure(POOL_PARTNERS.GET_COLLECTION, 'Couldn\'t fetch pool partners')),
  )),
);

export const getPoolPartnerEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(PoolPartnersAction.request(POOL_PARTNERS.GET_POOL_PARTNER).type),
  mergeMap((action) => Services.poolPartners.getPoolPartner(action.payload).pipe(
    map((poolPartner) => PoolPartnersAction.success(POOL_PARTNERS.GET_POOL_PARTNER, poolPartner)),
    catchError(() => PoolPartnersAction.failure(POOL_PARTNERS.GET_POOL_PARTNER, 'Could\'t fetch pool partner')),
  )),
);

export const createPoolPartnerEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(PoolPartnersAction.request(POOL_PARTNERS.CREATE_POOL_PARTNER).type),
  mergeMap((action) => Services.poolPartners.create(action.payload).pipe(
    map(() => [
      PoolPartnersAction.success(POOL_PARTNERS.CREATE_POOL_PARTNER, 'Pool partner created successfully'),
      PoolPartnersAction.request(POOL_PARTNERS.GET_COLLECTION),
      routingActionCreators.push(ROUTER.generate(poolPartnersRoute)),
    ]),
    catchError(() => of(PoolPartnersAction.failure(POOL_PARTNERS.CREATE_POOL_PARTNER, 'Pool partner creation failed'))),
  )),
);

export const updatePoolPartnerEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(PoolPartnersAction.request(POOL_PARTNERS.UPDATE_POOL_PARTNER).type),
  mergeMap((action) => Services.poolPartners.update(action.payload.id, action.payload.poolPartner).pipe(
    map(() => [
      PoolPartnersAction.success(POOL_PARTNERS.UPDATE_POOL_PARTNER, 'Pool partner updated successfully'),
      PoolPartnersAction.request(POOL_PARTNERS.GET_COLLECTION),
      routingActionCreators.push(ROUTER.generate(poolPartnersRoute)),
    ]),
    catchError(() => of(PoolPartnersAction.failure(POOL_PARTNERS.UPDATE_POOL_PARTNER, 'Pool partner update failed'))),
  )),
);

export const deletePoolPartnerEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(PoolPartnersAction.request(POOL_PARTNERS.DELETE_POOL_PARTNER).type),
  mergeMap((action) => Services.poolPartners.delete(action.payload).pipe(
    map(() => [
      PoolPartnersAction.success(POOL_PARTNERS.DELETE_POOL_PARTNER, 'Pool partner deleted successfully'),
      PoolPartnersAction.request(POOL_PARTNERS.GET_COLLECTION),
    ]),
    catchError((error) => 
    {  
      if(error.status == 409)
      {
        return of(PoolPartnersAction.failure(POOL_PARTNERS.DELETE_POOL_PARTNER, error.response.message))
      }

      return of(PoolPartnersAction.failure(POOL_PARTNERS.DELETE_POOL_PARTNER, 'Pool partner deletion failed'))
    }),
  )),
);

export const successToastEpic = (action$) => action$.pipe(
  ofType(
    PoolPartnersAction.success(POOL_PARTNERS.CREATE_POOL_PARTNER).type,
    PoolPartnersAction.success(POOL_PARTNERS.UPDATE_POOL_PARTNER).type,
    PoolPartnersAction.success(POOL_PARTNERS.DELETE_POOL_PARTNER).type,
  ),
  tap((action) => toast.success(action.payload)),
  ignoreElements(),
);

export const failureToastEpic = (action$) => action$.pipe(
  ofType(
    PoolPartnersAction.failure(POOL_PARTNERS.GET_COLLECTION).type,
    PoolPartnersAction.failure(POOL_PARTNERS.GET_POOL_PARTNER).type,
    PoolPartnersAction.failure(POOL_PARTNERS.CREATE_POOL_PARTNER).type,
    PoolPartnersAction.failure(POOL_PARTNERS.UPDATE_POOL_PARTNER).type,
    PoolPartnersAction.failure(POOL_PARTNERS.DELETE_POOL_PARTNER).type,
  ),
  tap((action) => toast.error(action.payload)),
  ignoreElements(),
);

export const poolPartnersEpic = combineEpics(
  getPoolPartnersEpic,
  getPoolPartnerEpic,
  enteredPoolPartnersEpic,
  enteredCreatePoolPartnerPageEpic,
  enteredEditPoolPartnerPageEpic,
  createPoolPartnerEpic,
  updatePoolPartnerEpic,
  deletePoolPartnerEpic,
  successToastEpic,
  failureToastEpic,
);
