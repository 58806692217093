import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  map,
  mergeMap,
  tap,
  ignoreElements,
  filter,
} from 'rxjs/operators';
import { routingActionTypes } from 'app/routing/actions/RoutingActions';
import { ROUTER } from '../../routing/routes';
import { toast } from 'react-toastify';
import { routingActionCreators } from '../../routing/actions/RoutingActions';
import { calendarRoute } from '../../routing/routeConstants';
import { CALENDAR, CalendarActions } from '../actions/CalendarActions';
import { PoolsActions } from 'app/pools/actions/PoolsActions';
import { get, isNull } from 'lodash';
import { convertPoolId } from '../../news/mappers/newsMappers';

export const enteredCalendarEpic = (action$, state$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_CALENDAR_PAGE),
  map(() => get(state$.value, 'pools.currentPool', null)),
  map((currentPool) => [
    PoolsActions.fetchCollectionRequest(),
    currentPool
      ? CalendarActions.request(CALENDAR.GET_CALENDAR_POOL_COLLECTION, currentPool)
      : CalendarActions.request(CALENDAR.GET_CALENDAR_COLLECTION),
  ]),
);

export const enteredCalendarEditEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_CALENDAR_EDIT_PAGE),
  map((action) => {
    const { id } = ROUTER.lookup(action.path).options;
    return Number(id);
  }),
  mergeMap(id => [
    CalendarActions.action(CALENDAR.RESET_CALENDAR_FORM),
    CalendarActions.request(CALENDAR.GET_EVENT, { id }),
    PoolsActions.fetchCollectionRequest(),
  ]),
);

export const setPoolCalendarEpic = (action$) => action$.pipe(
  ofType(CalendarActions.action(CALENDAR.SET_POOL).type),
  filter((action) => !isNull(action.payload)),
  map((action) => [
    PoolsActions.setPool(action.payload),
    CalendarActions.request(CALENDAR.GET_CALENDAR_POOL_COLLECTION, action.payload),
  ]),
);

export const enteredNCalendarCreateEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_CALENDAR_CREATE_PAGE),
  map(() => [
    CalendarActions.action(CALENDAR.RESET_CALENDAR_FORM),
    PoolsActions.fetchCollectionRequest(),
  ]),
);

export const getCalendarCollectionEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CalendarActions.request(CALENDAR.GET_CALENDAR_COLLECTION).type),
  mergeMap(() => Services.calendar.getEvents().pipe(
    map((events) => CalendarActions.success(CALENDAR.GET_CALENDAR_COLLECTION, events)),
    catchError((error) => CalendarActions.failure(CALENDAR.GET_CALENDAR_COLLECTION, error)),
  )),
);

export const getCalendarPoolCollectionEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CalendarActions.request(CALENDAR.GET_CALENDAR_POOL_COLLECTION).type),
  mergeMap((action) => Services.calendar.getPoolEvents(action.payload).pipe(
    map((events) => CalendarActions.success(CALENDAR.GET_CALENDAR_COLLECTION, events)),
    catchError((error) => CalendarActions.failure(CALENDAR.GET_CALENDAR_COLLECTION, error)),
  )),
);

export const getEventEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CalendarActions.request(CALENDAR.GET_EVENT).type),
  mergeMap((action) => Services.calendar.getEvent(action.payload.id).pipe(
    map((event) => [
      CalendarActions.success(CALENDAR.GET_EVENT, event),
    ]),
    catchError((error) => [
      CalendarActions.failure(CALENDAR.GET_EVENT, error),
    ]),
  )),
);

export const createEventEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CalendarActions.request(CALENDAR.ADD_EVENT).type),
  map(action => ({
    ...action.payload,
    poolId: convertPoolId(action.payload.poolId),
  })),
  mergeMap((request) => Services.calendar.postEvent(request).pipe(
    map((event) => [
      CalendarActions.success(CALENDAR.ADD_EVENT, event),
    ]),
    catchError((error) => [
      CalendarActions.failure(CALENDAR.ADD_EVENT, error),
    ]),
  )),
);

export const createEventSuccessEpic = (action$) => action$.pipe(
  ofType(CalendarActions.success(CALENDAR.ADD_EVENT).type),
  tap(() => toast.success('Event created successfully')),
  map(() => routingActionCreators.push(ROUTER.generate(calendarRoute))),
);

export const createEventFailureEpic = (action$) => action$.pipe(
  ofType(CalendarActions.failure(CALENDAR.ADD_EVENT).type),
  tap(() => toast.error('Failure while creating the event')),
  ignoreElements(),
);

export const updateEventEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CalendarActions.request(CALENDAR.UPDATE_EVENT).type),
  map(action => {
    const { id, ...rest } = action.payload;
    return {
      id,
      data: {
        ...rest,
        poolId: convertPoolId(rest.poolId),
      },
    };
  }),
  mergeMap((request) => Services.calendar.putEvent(request.id, request.data).pipe(
    map((event) => [
      CalendarActions.success(CALENDAR.UPDATE_EVENT, event),
    ]),
    catchError((error) => [
      CalendarActions.failure(CALENDAR.UPDATE_EVENT, error),
    ]),
  )),
);

export const updateEventSuccessEpic = (action$) => action$.pipe(
  ofType(CalendarActions.success(CALENDAR.UPDATE_EVENT).type),
  tap(() => toast.success('Event updated successfully')),
  map(() => routingActionCreators.push(ROUTER.generate(calendarRoute))),
);

export const updateEventFailureEpic = (action$) => action$.pipe(
  ofType(CalendarActions.failure(CALENDAR.UPDATE_EVENT).type),
  tap(() => toast.error('Failure while updating the event')),
  ignoreElements(),
);

export const removeEventEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CalendarActions.request(CALENDAR.REMOVE_CALENDAR_EVENT).type),
  mergeMap((action) => Services.calendar.deleteEvent(action.payload).pipe(
    map((event) => [
      CalendarActions.success(CALENDAR.REMOVE_CALENDAR_EVENT, event),
    ]),
    catchError((error) => [
      CalendarActions.failure(CALENDAR.REMOVE_CALENDAR_EVENT, error),
    ]),
  )),
);

export const removeEventSuccessEpic = (action$) => action$.pipe(
  ofType(CalendarActions.success(CALENDAR.REMOVE_CALENDAR_EVENT).type),
  tap(() => toast.success('Event removed successfully')),
  map(() => routingActionCreators.push(ROUTER.generate(calendarRoute))),
);

export const removeEventFailureEpic = (action$) => action$.pipe(
  ofType(CalendarActions.failure(CALENDAR.REMOVE_CALENDAR_EVENT).type),
  tap(() => toast.error('Failure while removing the event')),
  ignoreElements(),
);

export const calendarEpic = combineEpics(
  enteredCalendarEpic,
  enteredCalendarEditEpic,
  enteredNCalendarCreateEpic,
  getEventEpic,
  getCalendarCollectionEpic,
  getCalendarPoolCollectionEpic,
  updateEventEpic,
  updateEventSuccessEpic,
  updateEventFailureEpic,
  createEventEpic,
  createEventSuccessEpic,
  createEventFailureEpic,
  removeEventEpic,
  removeEventSuccessEpic,
  removeEventFailureEpic,
  setPoolCalendarEpic,
);
