import { EtlProcessAction, ETL_PROCESS } from '../actions/etlProcessActions';

export const initialEtlProcessState = {
  logs: [],
  isRunning: false,
};

const etlProcessReducer = (state = initialEtlProcessState, action = { type: '' }) => {
  switch (action.type) {
    case EtlProcessAction.success(ETL_PROCESS.GET_LOGS).type: {
      return {
        ...state,
        logs: action.payload.logs,
      };
    }
    case EtlProcessAction.action(ETL_PROCESS.SET_STATUS).type: {
      return {
        ...state,
        isRunning: action.payload,
      };
    }
    default:
      return state;
  }
};

export { etlProcessReducer };
