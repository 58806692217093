import { FORECAST_SETTINGS, ForecastSettingsAction } from 'app/forecastSettings/actions/ForecastSettingsActions';

export const initialForecastSettingsState = {
  threshold: null,
  forecasts: [],
};

const forecastSettingsReducer = (state = initialForecastSettingsState, action = { type: '' }) => {
  switch (action.type) {
    case ForecastSettingsAction.success(FORECAST_SETTINGS.GET_THRESHOLD).type: {
      const { threshold } = action.payload;
      return {
        ...state,
        threshold,
      };
    }

    case ForecastSettingsAction.success(FORECAST_SETTINGS.GET_FORECASTS).type: {
      const forecasts = action.payload;
      return {
        ...state,
        forecasts,
      };
    }

    case ForecastSettingsAction.success(FORECAST_SETTINGS.UPDATE_THRESHOLD).type: {
      const { threshold } = action.payload;
      return {
        ...state,
        threshold,
      };
    }

    default:
      return state;
  }
};

export { forecastSettingsReducer };
