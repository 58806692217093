/* eslint-disable class-methods-use-this */
import { of } from 'rxjs';

export default class BrenchmarkRatesService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  create(rate) {
    return this.apiClient.createBenchmarkRate(rate);
  }

  update(id, rate) {
    return this.apiClient.updateBenchmarkRate(id, rate);
  }

  remove(id) {
    return this.apiClient.removeBenchmarkRate(id);
  }

  get() {
    return this.apiClient.getBenchmarkRates();
  }
}
