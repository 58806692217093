import { map } from 'rxjs/operators';

class CalendarService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  static createEvent(eventData) {
    return eventData.map((event) => ({
      id: event.id,
      date: event.date,
      endDate: event.endDate ?? undefined,
      title: event.title,
      fileName: event.fileName,
      isUpdated: false,
      poolId: event?.poolId,
    }));
  }

  static createEventFormData(event) {
    const formData = new FormData();
    const {
      date, title, file, endDate, description, poolId,
    } = event;
    const eventUploadContent = {
      title,
      fileName: file?.path,
      date: date.replace('T', ' '),
      endDate: endDate?.replace('T', ' '),
      description: description === '' ? null : description,
      poolId,
    };
    if (file) {
      formData.append('File', file);
    }
    formData.append('EventUploadContent', JSON.stringify(eventUploadContent));
    return formData;
  }

  getEvents() {
    return this.apiClient.getCalendarEvents().pipe(
      map(CalendarService.createEvent),
    );
  }

  getPoolEvents(poolId) {
    return this.apiClient.getCalendarPoolEvents(poolId).pipe(
      map(CalendarService.createEvent),
    );
  }

  getEvent(id) {
    return this.apiClient.getCalendarEvent(id);
  }

  putEvent(eventId, eventData) {
    return this.apiClient.putCalendarEvent(
      eventId,
      CalendarService.createEventFormData(eventData),
    );
  }

  postEvent(event) {
    return this.apiClient.postCalendarEvent(
      CalendarService.createEventFormData(event),
    );
  }

  deleteEvent(id) {
    return this.apiClient.deleteCalendarEvent(id);
  }
}

export default CalendarService;
