import { authenticationEpic } from 'app/authentication/epics/AuthenticationEpic';
import { calendarEpic } from 'app/calendar/epics/CalendarEpics';
import { conditionsParametersEpic } from 'app/conditionsParameters/epics/conditionsParametersEpics';
import { consumptionsWeights } from 'app/consumptionsWeights/epics/consumptionsWeightsEpics';
import { documentsEpic } from 'app/documents/epics/documentsEpics';
import { errorEpic } from 'app/errorBoundaries/epics/errorBoundaries';
import { forecastSettingsEpics } from 'app/forecastSettings/epics/ForecastSettingsEpics';
import { maintenanceEpic } from 'app/maintenance/epics/MaintenanceEpics';
import { newsEpic } from 'app/news/epics/newsEpics';
import { poolCommentsEpic } from 'app/poolComments/epics/PoolCommentsEpics';
import { poolPartnersEpic } from 'app/poolPartners/store/epics/poolPartnersEpics';
import { poolsEpic } from 'app/pools/epics/PoolsEpics';
import { routingEpic } from 'app/routing/epics/routingEpics';
import { usersEpic } from 'app/users/store/epics/usersEpics';
import { vesselGroupsEpic } from 'app/vesselGroups/epics/VesselGroupsEpics';
import { uploadImage } from 'app/vessels/images/epics/uploadImageEpics';
import { welcomePopupEpic } from 'app/welcomePopup/epics/WelcomePopupEpics';
import { combineEpics } from 'redux-observable';
import { npsEpics } from '../nps/store/npsEpics';
import { exportXlsxEpics } from '../shared/components/xls/xlsFileEpic';
import { vesselsEpic } from '../vessels/manager/store/vesselsEpics';

const createRootEpic = () => {
  const rootEpic = [
    authenticationEpic,
    errorEpic,
    conditionsParametersEpic,
    consumptionsWeights,
    uploadImage,
    maintenanceEpic,
    poolsEpic,
    routingEpic,
    vesselGroupsEpic,
    calendarEpic,
    welcomePopupEpic,
    poolCommentsEpic,
    forecastSettingsEpics,
    documentsEpic,
    newsEpic,
    poolPartnersEpic,
    vesselsEpic,
    usersEpic,
    npsEpics,
    exportXlsxEpics,
  ];
  return combineEpics(...rootEpic);
};

export default createRootEpic;
