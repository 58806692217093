import { combineEpics, ofType } from 'redux-observable';
import { PoolsActions } from 'app/pools/actions/PoolsActions';
import { DOCUMENTS, DocumentsAction } from 'app/documents/actions/documentsActions';
import {
  catchError, filter, ignoreElements, map, mergeMap, tap,
} from 'rxjs/operators';
import { get, isNull } from 'lodash';
import { routingActionTypes } from 'app/routing/actions/RoutingActions';
import { toast } from 'react-toastify';
import { POOL_PARTNERS, PoolPartnersAction } from '../../poolPartners/store/actions/poolPartnersActions';
import { routingActionCreators } from '../../routing/actions/RoutingActions';
import { ROUTER } from '../../routing/routes';
import { documentsRoute } from '../../routing/routeConstants';

export const enteredDocumentEditEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_DOCUMENTS_EDIT_PAGE),
  map(action => {
    const documentId = ROUTER.lookup(action.path).options.id;
    return DocumentsAction.action(DOCUMENTS.SET_DOCUMENT_IN_EDIT, documentId);
  }),
);

export const enteredDocumentsEpic = (action$, state$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_DOCUMENTS_PAGE, routingActionTypes.ENTERED_DOCUMENTS_EDIT_PAGE),
  map(() => get(state$.value, 'pools.currentPool', null)),
  map((currentPool) => [
    PoolsActions.fetchCollectionRequest(),
    DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS, currentPool || 1),
    DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS_CATEGORY),
    PoolPartnersAction.request(POOL_PARTNERS.GET_COLLECTION, currentPool || 1),
    DocumentsAction.action(DOCUMENTS.SET_POOL, currentPool),
  ]),
);

export const enteredDocumentsCategoryEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_DOCUMENTS_CATEGORY_PAGE),
  map(() => [
    DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS_CATEGORY),
  ]),
);

export const enteredDocumentsUploadEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_DOCUMENTS_UPLOAD_PAGE),
  map(() => [
    PoolsActions.fetchCollectionRequest(),
    PoolPartnersAction.request(POOL_PARTNERS.GET_COLLECTION),
    DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS_CATEGORY),
  ]),
);

export const setPoolDocumentsEpic = (action$) => action$.pipe(
  ofType(DocumentsAction.action(DOCUMENTS.SET_POOL).type),
  filter((action) => !isNull(action.payload)),
  map((action) => [
    PoolsActions.setPool(action.payload),
    DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS, action.payload),
  ]),
);

export const uploadDocumentEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(DocumentsAction.request(DOCUMENTS.ADD_DOCUMENT).type),
  mergeMap((action) => Services.documents.postDocument(action.payload).pipe(
    map((document) => [
      DocumentsAction.success(DOCUMENTS.ADD_DOCUMENT, document),
    ]),
    catchError((error) => [
      DocumentsAction.failure(DOCUMENTS.ADD_DOCUMENT, error),
    ]),
  )),
);

export const uploadDocumentSuccessEpic = (action$) => action$.pipe(
  ofType(DocumentsAction.success(DOCUMENTS.ADD_DOCUMENT).type),
  tap(() => toast.success('File upload successfully completed.')),
  map(() => routingActionCreators.push(ROUTER.generate(documentsRoute))),
);

export const uploadDocumentFailureEpic = (action$) => action$.pipe(
  ofType(DocumentsAction.failure(DOCUMENTS.ADD_DOCUMENT).type),
  tap(() => toast.error('File upload failed.')),
  ignoreElements(),
);

export const updateDocumentEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(DocumentsAction.request(DOCUMENTS.UPDATE_DOCUMENT).type),
  mergeMap((action) => Services.documents.patchDocument(action.payload).pipe(
    map((document) => [
      DocumentsAction.success(DOCUMENTS.UPDATE_DOCUMENT, document),
      DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS),
    ]),
    catchError((error) => [
      DocumentsAction.failure(DOCUMENTS.UPDATE_DOCUMENT, error),
      DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS),
    ]),
  )),
);

export const updateDocumentSuccessEpic = (action$) => action$.pipe(
  ofType(DocumentsAction.success(DOCUMENTS.UPDATE_DOCUMENT).type),
  tap(() => toast.success('File update successfully completed.')),
  map(() => routingActionCreators.push(ROUTER.generate(documentsRoute))),
);

export const updateDocumentFailureEpic = (action$) => action$.pipe(
  ofType(DocumentsAction.failure(DOCUMENTS.UPDATE_DOCUMENT).type),
  tap(() => toast.error('File update failed.')),
  ignoreElements(),
);

export const getDocumentsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS).type),
  map(() => get(state$.value, 'pools.currentPool', null)),
  mergeMap((currentPool) => Services.documents.getDocuments(currentPool || 1).pipe(
    map((documents) => [
      DocumentsAction.success(DOCUMENTS.GET_DOCUMENTS, documents),
    ]),
    catchError((error) => [
      DocumentsAction.failure(DOCUMENTS.GET_DOCUMENTS, error),
    ]),
  )),
);

export const removeDocumentEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(DocumentsAction.request(DOCUMENTS.REMOVE_DOCUMENT).type),
  mergeMap((action) => Services.documents.deleteDocument(action.payload).pipe(
    map((documentCategory) => [
      DocumentsAction.success(DOCUMENTS.REMOVE_DOCUMENT, documentCategory),
      DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS),
    ]),
    catchError((error) => [
      DocumentsAction.failure(DOCUMENTS.REMOVE_DOCUMENT, error),
    ]),
  )),
);

export const getDocumentsCategoryEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS_CATEGORY).type),
  mergeMap(() => Services.documents.getDocumentCategories().pipe(
    map((documents) => [
      DocumentsAction.success(DOCUMENTS.GET_DOCUMENTS_CATEGORY, documents),
    ]),
    catchError((error) => [
      DocumentsAction.failure(DOCUMENTS.GET_DOCUMENTS_CATEGORY, error),
    ]),
  )),
);

export const addDocumentsCategoryEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(DocumentsAction.request(DOCUMENTS.ADD_DOCUMENT_CATEGORY).type),
  mergeMap((action) => Services.documents.postDocumentCategory(action.payload).pipe(
    map((documentCategory) => [
      DocumentsAction.success(DOCUMENTS.ADD_DOCUMENT_CATEGORY, documentCategory),
      DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS_CATEGORY, documentCategory),
    ]),
    catchError((error) => [
      DocumentsAction.failure(DOCUMENTS.ADD_DOCUMENT_CATEGORY, error),
    ]),
  )),
);

export const removeDocumentsCategoryEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(DocumentsAction.request(DOCUMENTS.REMOVE_CATEGORY).type),
  mergeMap((action) => Services.documents.deleteDocumentCategory(action.payload).pipe(
    map((documentCategory) => [
      DocumentsAction.success(DOCUMENTS.REMOVE_CATEGORY, documentCategory),
      DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS_CATEGORY, documentCategory),
    ]),
    catchError((error) => [
      DocumentsAction.failure(DOCUMENTS.ADD_DOCUMENT_CATEGORY, error),
    ]),
  )),
);

export const updateDocumentsCategoryEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(DocumentsAction.request(DOCUMENTS.UPDATE_DOCUMENT_CATEGORY).type),
  mergeMap((action) => {
    const { categoryId, categoryName } = action.payload;
    return Services.documents.putDocumentCategory(categoryId, categoryName).pipe(
      map((documentCategory) => [
        DocumentsAction.success(DOCUMENTS.UPDATE_DOCUMENT_CATEGORY, documentCategory),
        DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS_CATEGORY),
      ]),
      catchError((error) => [
        DocumentsAction.failure(DOCUMENTS.ADD_DOCUMENT_CATEGORY, error),
        DocumentsAction.request(DOCUMENTS.GET_DOCUMENTS_CATEGORY),
      ]),
    );
  }),
);

export const documentsEpic = combineEpics(
  enteredDocumentEditEpic,
  enteredDocumentsEpic,
  enteredDocumentsCategoryEpic,
  enteredDocumentsUploadEpic,
  setPoolDocumentsEpic,
  uploadDocumentEpic,
  updateDocumentEpic,
  updateDocumentSuccessEpic,
  updateDocumentFailureEpic,
  getDocumentsEpic,
  removeDocumentEpic,
  getDocumentsCategoryEpic,
  addDocumentsCategoryEpic,
  removeDocumentsCategoryEpic,
  updateDocumentsCategoryEpic,
  uploadDocumentSuccessEpic,
  uploadDocumentFailureEpic,
);
