import { USERS, UsersAction } from '../actions/usersActions';

export const initialUsersState = {
  collection: [],
  userContext: {},
};

const usersReducer = (state = initialUsersState, action = { type: '' }) => {
  switch (action.type) {
    case UsersAction.success(USERS.GET_COLLECTION).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case UsersAction.action(USERS.RESET_COLLECTION).type: {
      return {
        ...state,
        collection: initialUsersState.collection,
      };
    }
    case UsersAction.success(USERS.GET_USER).type: {
      return {
        ...state,
        userContext: action.payload,
      };
    }
    case UsersAction.action(USERS.RESET_USER_CONTEXT).type: {
      return {
        ...state,
        userContext: initialUsersState.userContext,
      };
    }
    default:
      return state;
  }
};

export { usersReducer };
