import { CALENDAR, CalendarActions } from '../actions/CalendarActions';

export const DEFAULT_POOL_ID_VALUE = 0;

export const initialCalendarState = {
  collection: [],
  event: {
    id: null,
    date: '',
    title: '',
    poolId: DEFAULT_POOL_ID_VALUE,
  },
};

const calendarReducer = (state = initialCalendarState, action = { type: '' }) => {
  switch (action.type) {
    case CalendarActions.success(CALENDAR.GET_CALENDAR_COLLECTION).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case CalendarActions.success(CALENDAR.GET_EVENT).type: {
      return {
        ...state,
        event: action.payload,
      };
    }
    case CalendarActions.action(CALENDAR.RESET_CALENDAR_FORM).type: {
      return {
        ...state,
        event: initialCalendarState.event,
      };
    }
    default:
      return state;
  }
};

export { calendarReducer };
