import { ActionCreator } from 'app/store/actionCreator';

export const POOL_PARTNERS_NAME = 'POOL_PARTNERS';

export const POOL_PARTNERS = {
  GET_COLLECTION: 'POOL_PARTNERS_GET_COLLECTION',
  RESET_COLLECTION: 'POOL_PARTNERS_RESET_COLLECTION',
  CREATE_POOL_PARTNER: 'CREATE_POOL_PARTNER',
  DELETE_POOL_PARTNER: 'DELETE_POOL_PARTNER',
  RESET_POOL_PARTNER_CONTEXT: 'RESET_POOL_PARTNER_CONTEXT',
  GET_POOL_PARTNER: 'GET_POOL_PARTNER',
  UPDATE_POOL_PARTNER: 'UPDATE_POOL_PARTNER',
};

export const PoolPartnersAction = new ActionCreator(POOL_PARTNERS_NAME);
