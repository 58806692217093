export const POOLS = {
  SET_POOL: 'POOLS_SET_POOL',
  SET_COLLECTION: 'POOLS_SET_COLLECTION',
  RESET_COLLECTION: 'POOLS_RESET_COLLECTION',
  RESET_CURRENT_POOL: 'POOLS_RESET_CURRENT_POOL',
  FETCH_COLLECTION_REQUEST: 'POOLS_COLLECTION_REQUEST',
  FETCH_COLLECTION_SUCCESS: 'POOLS_COLLECTION_SUCCESS',
  FETCH_COLLECTION_FAILURE: 'POOLS_COLLECTION_FAILURE',
  FETCH_COLLECTION_SELECTOR: 'POOLS_COLLECTION',
  ADD_COLLECTION_ITEM: 'POOLS_ADD_COLLECTION_ITEM',
  ADD_COLLECTION_ITEM_REQUEST: 'POOLS_ADD_COLLECTION_ITEM_REQUEST',
  ADD_COLLECTION_ITEM_SUCCESS: 'POOLS_ADD_COLLECTION_ITEM_SUCCESS',
  ADD_COLLECTION_ITEM_FAILURE: 'POOLS_ADD_COLLECTION_ITEM_FAILURE',
  CHANGE_COLLECTION_ITEM: 'POOLS_CHANGE_COLLECTION_ITEM',
  CHANGE_COLLECTION_ITEM_REQUEST: 'POOLS_CHANGE_COLLECTION_ITEM_REQUEST',
  CHANGE_COLLECTION_ITEM_SUCCESS: 'POOLS_CHANGE_COLLECTION_ITEM_SUCCESS',
  CHANGE_COLLECTION_ITEM_FAILURE: 'POOLS_CHANGE_COLLECTION_ITEM_FAILURE',
  DELETE_COLLECTION_ITEM: 'POOLS_DELETE_COLLECTION_ITEM',
  DELETE_COLLECTION_ITEM_REQUEST: 'POOLS_DELETE_COLLECTION_ITEM_REQUEST',
  DELETE_COLLECTION_ITEM_SUCCESS: 'POOLS_DELETE_COLLECTION_ITEM_SUCCESS',
  DELETE_COLLECTION_ITEM_FAILURE: 'POOLS_DELETE_COLLECTION_ITEM_FAILURE',
  PREPARE_NEW_COLLECTION_ITEM: 'POOLS_PREPARE_NEW_COLLECTION_ITEM',
  REMOVE_NEW_COLLECTION_ITEM: 'POOLS_REMOVE_NEW_COLLECTION_ITEM',
  RESET_NEW_COLLECTION: 'POOLS_RESET_NEW_COLLECTION',
};

export const PoolsActions = {
  setPool: (payload) => ({
    type: POOLS.SET_POOL,
    payload,
  }),
  resetCurrentPool: () => ({
    type: POOLS.RESET_CURRENT_POOL,
  }),
  setCollection: (payload) => ({
    type: POOLS.SET_COLLECTION,
    payload,
  }),
  resetCollection: () => ({
    type: POOLS.RESET_COLLECTION,
  }),
  fetchCollectionRequest: (payload) => ({
    type: POOLS.FETCH_COLLECTION_REQUEST,
    payload,
  }),
  fetchCollectionSuccess: () => ({
    type: POOLS.FETCH_COLLECTION_SUCCESS,
  }),
  fetchCollectionFailure: (payload) => ({
    type: POOLS.FETCH_COLLECTION_FAILURE,
    payload,
  }),
  addCollectionItem: (payload) => ({
    type: POOLS.ADD_COLLECTION_ITEM,
    payload,
  }),
  addCollectionItemRequest: (payload) => ({
    type: POOLS.ADD_COLLECTION_ITEM_REQUEST,
    payload,
  }),
  addCollectionItemFailure: (payload) => ({
    type: POOLS.ADD_COLLECTION_ITEM_FAILURE,
    payload,
  }),
  addCollectionItemSuccess: () => ({
    type: POOLS.ADD_COLLECTION_ITEM_SUCCESS,
  }),

  changeCollectionItem: (payload) => ({
    type: POOLS.CHANGE_COLLECTION_ITEM,
    payload,
  }),
  changeCollectionItemRequest: (payload) => ({
    type: POOLS.CHANGE_COLLECTION_ITEM_REQUEST,
    payload,
  }),
  changeCollectionItemFailure: (payload) => ({
    type: POOLS.CHANGE_COLLECTION_ITEM_FAILURE,
    payload,
  }),
  changeCollectionItemSuccess: () => ({
    type: POOLS.CHANGE_COLLECTION_ITEM_SUCCESS,
  }),
  deleteCollectionItem: (payload) => ({
    type: POOLS.DELETE_COLLECTION_ITEM,
    payload,
  }),
  deleteCollectionItemRequest: (payload) => ({
    type: POOLS.DELETE_COLLECTION_ITEM_REQUEST,
    payload,
  }),
  deleteCollectionItemFailure: (payload) => ({
    type: POOLS.DELETE_COLLECTION_ITEM_FAILURE,
    payload,
  }),
  deleteCollectionItemSuccess: () => ({
    type: POOLS.DELETE_COLLECTION_ITEM_SUCCESS,
  }),
  prepareNewCollectionItem: (payload) => ({
    type: POOLS.PREPARE_NEW_COLLECTION_ITEM,
    payload,
  }),
  removeNewCollectionItem: (payload) => ({
    type: POOLS.REMOVE_NEW_COLLECTION_ITEM,
    payload,
  }),
  resetNewCollection: () => ({
    type: POOLS.RESET_NEW_COLLECTION,
  }),
};
