import { DOCUMENTS, DocumentsAction } from 'app/documents/actions/documentsActions';

export const initialDocumentsState = {
  collection: [],
  collectionCategory: [],
  editingDocumentId: null,
};

const documentsReducer = (state = initialDocumentsState, action = { type: '' }) => {
  switch (action.type) {
    case DocumentsAction.success(DOCUMENTS.GET_DOCUMENTS).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case DocumentsAction.success(DOCUMENTS.GET_DOCUMENTS_CATEGORY).type: {
      return {
        ...state,
        collectionCategory: action.payload,
      };
    }
    case DocumentsAction.action(DOCUMENTS.SET_DOCUMENT_IN_EDIT).type: {
      return {
        ...state,
        editingDocumentId: action.payload,
      };
    }
    default:
      return state;
  }
};

export { documentsReducer };
