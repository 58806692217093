import { MARKETS, MarketsAction } from 'app/markets/actions/MarketsActions';

export const initialMarketsState = {
  collection: [],
};

const marketsReducer = (state = initialMarketsState, action = { type: '' }) => {
  switch (action.type) {
    case MARKETS.SET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
      };

    case MarketsAction.success(MARKETS.ADD_ITEM).type: {
      const {
        id, name, poolId, poolPercentage, marketPercentage, imoCargo,
      } = action.payload;
      return {
        ...state,
        collection: [
          ...state.collection,
          {
            id,
            name,
            poolId,
            poolPercentage,
            marketPercentage,
            imoCargo,
            countCommentaries: 0,
            commentaries: [],
            isUpdated: false,
            isCommentariesFetch: false,
          },
        ],
      };
    }

    case MarketsAction.request(MARKETS.UPDATE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== action.payload.id) { return market; }

          return {
            ...market,
            isUpdated: true,
          };
        }),
      };

    case MarketsAction.failure(MARKETS.UPDATE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((market) => ({
          ...market,
          isUpdated: false,
        })),
      };

    case MarketsAction.success(MARKETS.UPDATE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== action.payload.id) { return market; }
          return {
            ...market,
            ...action.payload,
            isUpdated: false,
          };
        }),
      };

    case MarketsAction.request(MARKETS.REMOVE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== action.payload.id) { return market; }

          return {
            ...market,
            isUpdated: true,
          };
        }),
      };

    case MarketsAction.failure(MARKETS.REMOVE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((market) => (
          {
            ...market,
            isUpdated: false,
          }
        )),
      };

    case MarketsAction.success(MARKETS.REMOVE_ITEM).type:
      return {
        ...state,
        collection: state.collection.filter((market) => market.id !== action.payload.id),
      };

    case MarketsAction.action(MARKETS.SET_COMMENTARIES).type:
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== action.payload.marketId) { return market; }
          return {
            ...market,
            countCommentaries: action.payload.collection.length,
            commentaries: action.payload.collection,
          };
        }),
      };

    case MarketsAction.success(MARKETS.REMOVE_COMMENT).type:
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== action.payload.marketId) { return market; }
          const commentaries = market.commentaries.filter((comment) => comment.id !== action.payload.id);
          return {
            ...market,
            countCommentaries: commentaries.length,
            commentaries,
          };
        }),
      };

    case MarketsAction.success(MARKETS.ADD_COMMENT).type: {
      const {
        id, title, comment, publishDate, market: marketData,
      } = action.payload;
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== marketData.id) { return market; }
          const commentaries = [
            ...market.commentaries,
            {
              id,
              title,
              comment,
              publishDate,
              marketId: marketData.id,
              poolId: marketData.poolId,
              isUpdated: false,
            },
          ];
          return {
            ...market,
            countCommentaries: commentaries.length,
            commentaries,
          };
        }),
      };
    }

    case MarketsAction.request(MARKETS.UPDATE_COMMENT).type:
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== action.payload.marketId) { return market; }
          const commentaries = market.commentaries.map((comment) => {
            if (comment.id !== action.payload.id) { return comment; }
            return {
              ...comment,
              isUpdated: true,
            };
          });
          return {
            ...market,
            isUpdated: true,
            commentaries,
          };
        }),
      };

    case MarketsAction.failure(MARKETS.UPDATE_COMMENT).type:
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== action.payload.marketId) { return market; }
          const commentaries = market.commentaries.map((comment) => {
            if (comment.id !== action.payload.id) { return comment; }
            return {
              ...comment,
              isUpdated: false,
            };
          });
          return {
            ...market,
            isUpdated: false,
            commentaries,
          };
        }),
      };

    case MarketsAction.success(MARKETS.UPDATE_COMMENT).type: {
      const {
        id, title, comment, publishDate, market: marketData,
      } = action.payload;
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== marketData.id) { return market; }
          const commentaries = market.commentaries.map((item) => {
            if (item.id !== id) { return item; }
            return {
              ...item,
              id,
              title,
              comment,
              publishDate,
              marketId: market.id,
              poolId: market.poolId,
              isUpdated: false,
            };
          });
          return {
            ...market,
            isUpdated: false,
            commentaries,
          };
        }),
      };
    }

    case MarketsAction.request(MARKETS.FETCH_COMMENTARIES).type: {
      return {
        ...state,
        collection: state.collection.map((market) => {
          const { marketId } = action.payload;
          if (market.id !== marketId) { return market; }
          return {
            ...market,
            isCommentariesFetch: true,
          };
        }),
      };
    }

    case MarketsAction.success(MARKETS.FETCH_COMMENTARIES).type: {
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== action.payload) { return market; }
          return {
            ...market,
            isCommentariesFetch: false,
          };
        }),
      };
    }

    case MarketsAction.failure(MARKETS.FETCH_COMMENTARIES).type: {
      const { marketId } = action.payload;
      return {
        ...state,
        collection: state.collection.map((market) => {
          if (market.id !== marketId) { return market; }
          return {
            ...market,
            isCommentariesFetch: false,
          };
        }),
      };
    }

    default:
      return state;
  }
};

export { marketsReducer };
