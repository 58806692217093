import { ActionCreator } from 'app/store/actionCreator';

const MARKETS_NAME = 'MARKETS';

export const MARKETS = {
  ADD_ITEM: 'MARKETS_ADD_ITEM',
  FETCH_COLLECTION: 'MARKETS_FETCH_COLLECTION',
  REMOVE_ITEM: 'MARKETS_REMOVE_ITEM',
  SET_POOL: 'MARKETS_SET_POOL',
  SET_COLLECTION: 'MARKETS_SET_COLLECTION',
  UPDATE_ITEM: 'MARKETS_UPDATE_ITEM',
  FETCH_COMMENTARIES: 'MARKETS_FETCH_COMMENTARIES',
  SET_COMMENTARIES: 'MARKETS_SET_COMMENTARIES',
  ADD_COMMENT: 'MARKETS_ADD_COMMENT',
  REMOVE_COMMENT: 'MARKETS_REMOVE_COMMENT',
  UPDATE_COMMENT: 'MARKETS_UPDATE_COMMENT',
};

export const MarketsAction = new ActionCreator(MARKETS_NAME);
