class VesselPartnerReportService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getReport() {
    return this.apiClient.getVesselPartnerReport();
  }
}

export default VesselPartnerReportService;
