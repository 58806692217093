import { XLS_FILE, XlsFileAction } from './xlsFileActions';
import { combineEpics, ofType } from 'redux-observable';
import { ignoreElements, map } from 'rxjs/operators';
import * as service from './xlsFileService';
import { toast } from 'react-toastify';

export const exportTableXlsxEpic = (action$) => action$.pipe(
  ofType(XlsFileAction.action(XLS_FILE.CREATE_XLS_FILE).type),
  map((action) => {
    const {
      rows = [], config = [], fileName = '', fileSuffix, mainRow, secondM, worksheetName,
    } = action.payload;
    if (action.payload?.rows?.length > 0) {
      service.createXlsxFile(rows, config, fileName, fileSuffix, mainRow, secondM, worksheetName);
    } else {
      toast.error('Unable to export to excel file');
    }
  }),
  ignoreElements(),
);

const exportXlsxEpics = combineEpics(
  exportTableXlsxEpic,
);

export { exportXlsxEpics };
