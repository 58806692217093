import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import { Auth0Config } from 'app/shared/services/config';

class AuthenticationService {
  constructor() {
    this.auth0 = new auth0.WebAuth(Auth0Config);
  }

  login() {
    this.auth0.authorize();
  }

  parseLoginResult(hash) {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash({ hash }, (err, authResult) => {
        if (err) {
          reject(err);
        }
        const { accessToken } = authResult;
        resolve(accessToken);
      });
    });
  }

  getUserData(accessToken) {
    const decodedAccessToken = jwtDecode(accessToken);
    const { permissions = [] } = decodedAccessToken;
    // The Authententication extension is providing role. Soon we don't have these. Instead, the we have permissions.
    const roles = decodedAccessToken['https://auth.maersktankers.com/pp/app_metadata/authorization'] || []; 
    return {
      accessToken,
      userRoles: permissions.concat(roles),
      // The Authententication extension is providing the email. Soon we don't have this. Instead of email, the top menu will just show "Logout".
      email: decodedAccessToken['https://auth.maersktankers.com/pp/email'] || "Logout",
    };
  }
}

export default AuthenticationService;
