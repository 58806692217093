
class ForecastSettingsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getThreshold(poolId) {
    return this.apiClient.getThreshold(poolId);
  }

  getForecasts(poolId) {
    return this.apiClient.getForecasts(poolId);
  }

  updateThreshold(poolId, threshold) {
    return this.apiClient.updateThreshold(poolId, threshold);
  }

  addForecasts(poolId, forecasts) {
    return this.apiClient.addForecasts(poolId, forecasts.map(forecast => ({
      target: forecast.target,
      fixedDays: forecast.fixedDays,
      unfixedDays: forecast.unfixedDays,
      alphaPrediction: forecast.alphaPrediction,
      resultActual: forecast.resultActual,
      payoutRatio: forecast.payoutRatio,
    })));
  }

  updateForecast(forecast) {
    return this.apiClient.updateForecast(forecast);
  }

  removeForecast(poolId, forecastId) {
    return this.apiClient.removeForecast(poolId, forecastId);
  }
}

export default ForecastSettingsService;
