import { NEWS, NewsAction } from '../actions/newsActions';
import { getUtcNow } from '../../shared/helpers/datetime';
import { DEFAULT_POOL_ID_VALUE } from '../mappers/newsMappers';

export const initialNewsState = {
  collection: [],
  newsForm: {
    id: null,
    poolId: DEFAULT_POOL_ID_VALUE,
    publishDate: getUtcNow(),
    leadingImage: '',
    title: '',
    summary: '',
    content: '',
    isActive: false,
  },
};

const newsReducer = (state = initialNewsState, action = { type: '' }) => {
  switch (action.type) {
    case NewsAction.success(NEWS.GET_NEWS_COLLECTION).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case NewsAction.success(NEWS.GET_NEWS).type: {
      return {
        ...state,
        newsForm: action.payload,
      };
    }
    case NewsAction.action(NEWS.RESET_NEWS_FORM).type: {
      return {
        ...state,
        newsForm: initialNewsState.newsForm,
      };
    }
    default:
      return state;
  }
};

export { newsReducer };
