import { applyMiddleware, compose, createStore } from 'redux';
import multi from 'redux-multi';
import { routerMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import createRootEpic from './configureEpics';
import createRootReducer from './configureReducers';
import createInitialState from './configureInitialState';
import { ajax } from 'rxjs/ajax';
import { BehaviorSubject } from 'rxjs';
import { RestClient } from 'app/shared/clients/RestClient';
import { ServicesFactory } from 'app/shared/services/servicesFactory';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { createBrowserHistory } from 'history';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [ 'authentication' ],
};

export const user$ = new BehaviorSubject(null);

const restClient = new RestClient(user$, ajax);
export const servicesFactory = new ServicesFactory(restClient);
// Create browser history to use in the Redux store
const baseUrl = document
  .getElementsByTagName('base')[0]
  .getAttribute('href');

const configureStore = (history) => {
  const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      user$,
      Services: servicesFactory,
    },
  });
  const middleware = [
    epicMiddleware,
    routerMiddleware(history),
    multi,
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  // noinspection JSUnresolvedVariable
  if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const storeEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

  const store = createStore(
    persistedReducer,
    createInitialState(),
    storeEnhancers
  );

  epicMiddleware.run(createRootEpic());

  return store;
};

const history = createBrowserHistory({ basename: baseUrl });

const store = configureStore(history);
const persistor = persistStore(store);

const dispatch = action => store.dispatch(action);

export {
  store,
  persistor,
  history,
  dispatch,
};
