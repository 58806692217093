import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { includes } from 'lodash/collection';

class VesselGroupsService {
  constructor(maerskApi) {
    this.maerskApi = maerskApi;
  }

  static createVessel(vesselsData) {
    return vesselsData.map((vessel) => ({
      imo: vessel.imo,
      name: vessel.name,
      isUpdated: false,
    }));
  }

  setGroupToVessel(vessels, groups) {
    const vesselsWithGroups = vessels.map((vessel) => {
      const includeGroups = groups.map((group) => ({
        id: group.id,
        name: group.name,
        includes: includes(group.vessels, vessel.imo),
      }));
      return {
        ...vessel,
        groups: includeGroups,
      };
    });
    return of(vesselsWithGroups);
  }

  getVessels(poolId) {
    return this.maerskApi.getVessels(poolId).pipe(
      map(VesselGroupsService.createVessel)
    );
  }

  getVesselGroups(poolId) {
    return this.maerskApi.getVesselGroups(poolId);
  }

  addVesselToGroup(vesselIMO, groupId) {
    return this.maerskApi.addVesselToGroup(vesselIMO, groupId);
  }

  removeVesselFromGroup(vesselIMO, groupId) {
    return this.maerskApi.removeVesselFromGroup(vesselIMO, groupId);
  }
}

export default VesselGroupsService;
