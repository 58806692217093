class NpsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getNps() {
    return this.apiClient.getNps();
  }

  createNpsPeriod(newPeriod) {
    return this.apiClient.createNpsPeriod(newPeriod);
  }

  updateNpsPeriod(newPeriod) {
    return this.apiClient.updateNpsPeriod(newPeriod);
  }

  deleteNpsPeriod(id) {
    return this.apiClient.deleteNpsPeriod(id);
  }

  updateNpsStatus(newStatus) {
    return this.apiClient.updateNpsStatus(newStatus);
  }
}

export default NpsService;
