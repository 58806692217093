import { FaqAction, FAQ } from '../actions/faqActions';

export const initialFaqState = {
  collection: [],
  currentCategoryId: null,
  editQuestion: {
    question: '',
    answer: '',
  },
};

const faqReducer = (state = initialFaqState, action = { type: '' }) => {
  switch (action.type) {
    case FaqAction.success(FAQ.GET_ALL).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case FaqAction.action(FAQ.SET_CURRENT_CATEGORY).type: {
      return {
        ...state,
        currentCategoryId: action.payload,
      };
    }
    case FaqAction.action(FAQ.SET_EDIT_QUESTION).type: {
      return {
        ...state,
        editQuestion: action.payload,
      };
    }
    case FaqAction.action(FAQ.RESET_QUESTION_FORM).type: {
      return {
        ...state,
        editQuestion: initialFaqState.editQuestion,
      };
    }
    default:
      return state;
  }
};

export { faqReducer };
