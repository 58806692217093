import { ActionCreator } from 'app/store/actionCreator';

export const DOCUMENTS_NAME = 'DOCUMENTS';

export const DOCUMENTS = {
  SET_POOL: 'DOCUMENTS_SET_POOL',
  ADD_DOCUMENT: 'DOCUMENTS_ADD_DOCUMENT',
  GET_DOCUMENTS: 'DOCUMENTS_GET_DOCUMENTS',
  UPDATE_DOCUMENT: 'DOCUMENTS_UPDATE_DOCUMENT',
  REMOVE_DOCUMENT: 'DOCUMENTS_REMOVE',
  GET_DOCUMENTS_CATEGORY: 'GET_DOCUMENTS_CATEGORY',
  ADD_DOCUMENT_CATEGORY: 'DOCUMENTS_ADD_DOCUMENT_CATEGORY',
  UPDATE_DOCUMENT_CATEGORY: 'DOCUMENTS_UPDATE_DOCUMENT_CATEGORY',
  REMOVE_CATEGORY: 'DOCUMENTS_REMOVE_CATEGORY',
  SET_DOCUMENT_IN_EDIT: 'SET_DOCUMENT_IN_EDIT',
};

export const DocumentsAction = new ActionCreator(DOCUMENTS_NAME);
