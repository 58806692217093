import { ActionCreator } from 'app/store/actionCreator';

export const USERS_NAME = 'USERS';

export const USERS = {
  GET_COLLECTION: 'USERS_GET_COLLECTION',
  RESET_COLLECTION: 'USERS_RESET_COLLECTION',
  CREATE_USER: 'CREATE_USER',
  DELETE_USER: 'DELETE_USER',
  RESET_USER_CONTEXT: 'RESET_USER_CONTEXT',
  GET_USER: 'GET_USER',
  UPDATE_USER: 'UPDATE_USER',
};

export const UsersAction = new ActionCreator(USERS_NAME);
