import { BUNKER_CONSUMPTION_SETTINGS } from 'app/bunkerConsumptionSettings/actions/BunkerConsumptionSettingsActions';

export const initialBunkerConsumptionSettingsState = {
  currentPool: null,
};

const bunkerConsumptionSettingsReducer = (state = initialBunkerConsumptionSettingsState, action = { type: '' }) => {
  switch (action.type) {
    case BUNKER_CONSUMPTION_SETTINGS.SET_CURRENT_POOL:
      return {
        ...state,
        currentPool: action.payload,
      };

    case BUNKER_CONSUMPTION_SETTINGS.RESET_CURRENT_POOL:
      return {
        ...state,
        currentPool: initialBunkerConsumptionSettingsState.currentPool,
      };

    default:
      return state;
  }
};

export { bunkerConsumptionSettingsReducer };
