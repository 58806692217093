import { ActionCreator } from 'app/store/actionCreator';

export const VESSELS_NAME = 'VESSELS';

export const VESSELS = {
  GET_ALL: 'GET_ALL_VESSELS',
  GET_EDIT_VESSEL: 'GET_EDIT_VESSEL',
  RESET_EDIT_VESSEL: 'RESET_EDIT_VESSEL',
  GET_AUTOCOMPLETE_VESSEL_DETAILS: 'GET_AUTOCOMPLETE_VESSEL_DETAILS',
  RESET_AUTOCOMPLETE_VESSEL_DETAILS: 'RESET_AUTOCOMPLETE_VESSEL_DETAILS',
  CREATE_VESSEL: 'CREATE_VESSEL',
  UPDATE_VESSEL: 'UPDATE_VESSEL',
  DELETE_VESSEL: 'DELETE_VESSEL',
};

export const VesselsAction = new ActionCreator(VESSELS_NAME);
