export const getApiBase = () => `${process.env.REACT_APP_API_BASE_URL}/api/v1`;

export const Auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${process.env.REACT_APP_CLIENT_BASE_URL}/auth-return`,
  responseType: 'token id_token',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope: 'profile',
};
