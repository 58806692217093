import { map } from 'rxjs/operators';

class MapSettingsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  static createVessel(vesselsData) {
    return vesselsData.map((vessel) => ({
      id: vessel.imo,
      name: vessel.name,
      poolName: vessel.poolName,
      imo: vessel.imo,
      isVisible: vessel.isVisible,
      isUpdated: false,
    }));
  }

  getMapVessels(poolName) {
    return this.apiClient.getMapVessels(poolName).pipe(
      map(MapSettingsService.createVessel)
    );
  }

  setVisibility(vesselData) {
    return this.apiClient.setVisibilityVessel(vesselData.imo, vesselData.isVisible);
  }
}

export default MapSettingsService;
