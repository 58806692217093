export default class FaqService {
  constructor(apiService) {
    this.api = apiService;
  }

  getFaq() {
    return this.api.getFaq();
  }

  createFaqCategory(category) {
    return this.api.createFaqCategory(category);
  }

  deleteFaqCategory(categoryId) {
    return this.api.deleteFaqCategory(categoryId);
  }

  updateFaqCategory(categoryId, category) {
    return this.api.updateFaqCategory(categoryId, category);
  }

  createFaqQuestion(categoryId, question) {
    return this.api.createFaqQuestion(categoryId, question);
  }

  deleteFaqQuestion(categoryId, questionId) {
    return this.api.deleteFaqQuestion(categoryId, questionId);
  }

  updateFaqQuestion(categoryId, questionId, question) {
    return this.api.updateFaqQuestion(categoryId, questionId, question);
  }

  getFaqQuestion(categoryId, questionId) {
    return this.api.getFaqQuestion(categoryId, questionId);
  }

  updateFaqCategoriesOrder(categoryIdsInOrder) {
    return this.api.updateFaqCategoriesOrder(categoryIdsInOrder);
  }

  updateFaqQuestionsOrder(categoryId, questionIdsInOrder) {
    return this.api.updateFaqQuestionsOrder(categoryId, questionIdsInOrder);
  }
}
