import { ActionCreator } from 'app/store/actionCreator';

export const TERMS_AND_CONDITIONS_NAME = 'TERMS_AND_CONDITIONS';

export const TERMS_AND_CONDITIONS = {
  GET_HISTORY: 'TERMS_AND_CONDITIONS_GET_HISTORY',
  CREATE: 'TERMS_AND_CONDITIONS_CREATE',
  UPDATE: 'TERMS_AND_CONDITIONS_UPDATE',
};

export const TermsAndConditionsAction = new ActionCreator(TERMS_AND_CONDITIONS_NAME);
