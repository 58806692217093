export const maintenanceActionTypes = {
  INITIALIZE_MAINTENANCE_STATUS: 'INITIALIZE_MAINTENANCE_STATUS',
  REQUEST_LAST_MAINTENANCE_STATUS: 'REQUEST_LAST_MAINTENANCE_STATUS',
  RECEIVE_LAST_MAINTENANCE_STATUS: 'RECEIVE_LAST_MAINTENANCE_STATUS',
  REQUEST_MAINTENANCE_STATUS_HISTORY: 'REQUEST_MAINTENANCE_STATUS_HISTORY',
  RECEIVE_MAINTENANCE_STATUS_HISTORY: 'RECEIVE_MAINTENANCE_STATUS_HISTORY',
  CHANGE_MAINTENANCE_STATUS: 'CHANGE_MAINTENANCE_STATUS',
  RESET_CLEAR_CACHE_MAINTENANCE: 'RESET_CLEAR_CACHE_MAINTENANCE',
  REQUEST_CLEAR_CACHE_MAINTENANCE: 'REQUEST_CLEAR_CACHE_MAINTENANCE',
  REQUEST_CLEAR_CACHE_MAINTENANCE_FAILURE: 'REQUEST_CLEAR_CACHE_MAINTENANCE_FAILURE',
  RECEIVE_CLEAR_CACHE_MAINTENANCE_STATUS: 'RECEIVE_CLEAR_CACHE_MAINTENANCE_STATUS',
  ERROR_MAINTENANCE_STATUS: 'ERROR_MAINTENANCE_STATUS',
};

export const maintenanceActionCreators = {
  initializeMaintenanceStatus: () => ({
    type: maintenanceActionTypes.INITIALIZE_MAINTENANCE_STATUS,
  }),
  changeMaintenanceStatus: (isMaintenanceEnabled) => ({
    type: maintenanceActionTypes.CHANGE_MAINTENANCE_STATUS,
    isMaintenanceEnabled,
  }),
  receiveMaintenanceStatusHistory: (maintenanceStatusHistory) => ({
    type: maintenanceActionTypes.RECEIVE_MAINTENANCE_STATUS_HISTORY,
    maintenanceStatusHistory,
  }),
  requestMaintenanceStatusHistory: () => ({
    type: maintenanceActionTypes.REQUEST_MAINTENANCE_STATUS_HISTORY,
  }),
  receiveLastMaintenanceStatus: (maintenanceStatus) => ({
    type: maintenanceActionTypes.RECEIVE_LAST_MAINTENANCE_STATUS,
    maintenanceStatus,
  }),
  requestLastMaintenanceStatus: () => ({
    type: maintenanceActionTypes.REQUEST_LAST_MAINTENANCE_STATUS,
  }),
  requestClearCacheMaintenance: () => ({
    type: maintenanceActionTypes.REQUEST_CLEAR_CACHE_MAINTENANCE,
  }),
  resetClearCacheMaintenance: () => ({
    type: maintenanceActionTypes.RESET_CLEAR_CACHE_MAINTENANCE,
  }),
  requestClearCacheMaintenanceFailure: (err) => ({
    type: maintenanceActionTypes.REQUEST_CLEAR_CACHE_MAINTENANCE_FAILURE,
    err,
  }),
  receiveClearCacheMaintenanceStatus: () => ({
    type: maintenanceActionTypes.RECEIVE_CLEAR_CACHE_MAINTENANCE_STATUS,
  }),
  maintenanceErrorStatus: () => ({
    type: maintenanceActionTypes.ERROR_MAINTENANCE_STATUS,
  }),
};
