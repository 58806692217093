import { combineEpics, ofType } from 'redux-observable';
import { ERROR_BOUNDARIES, ERROR_STATUS } from '../actions/errorBoundaries';
import { filter, mapTo } from 'rxjs/operators';
import { authenticationActionCreators } from '../../authentication/actions/AuthenticationActions';

export const errorBoundariesEpic = (action$) => action$.pipe(
  ofType(ERROR_BOUNDARIES.THROW_ERROR),
  filter((action) => action.payload.status === ERROR_STATUS.NOT_AUTHORIZED),
  mapTo(authenticationActionCreators.authenticationError()),
);

export const errorEpic = combineEpics(
  errorBoundariesEpic,
);
