import { MAP_SETTINGS } from 'app/mapSettings/actions/MapSettingsActions';

export const initialMapSettingsState = {
  collection: [],
};

const mapSettingsReducer = (state = initialMapSettingsState, action = { type: '' }) => {
  switch (action.type) {
    case MAP_SETTINGS.SET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
      };
    case MAP_SETTINGS.UPDATE_ITEM_REQUEST:
      return {
        ...state,
        collection: state.collection.map((vessel) => {
          if (vessel.imo !== action.payload.imo) { return vessel; }

          return {
            ...vessel,
            isUpdated: true,
          };
        }),
      };

    case MAP_SETTINGS.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        collection: state.collection.map((vessel) => {
          if (vessel.imo !== action.payload.imo) { return vessel; }

          return {
            ...vessel,
            isUpdated: false,
          };
        }),
      };

    case MAP_SETTINGS.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        collection: state.collection.map((vessel) => {
          if (vessel.imo !== action.payload.imo) { return vessel; }

          return {
            ...vessel,
            isVisible: action.payload.isVisible,
            isUpdated: false,
          };
        }),
      };

    default:
      return state;
  }
};

export { mapSettingsReducer };
