import { POOL_PARTNERS, PoolPartnersAction } from '../actions/poolPartnersActions';

export const initialPoolPartnersState = {
  collection: [],
  poolPartnerContext: {},
};

const poolPartnersReducer = (state = initialPoolPartnersState, action = { type: '' }) => {
  switch (action.type) {
    case PoolPartnersAction.success(POOL_PARTNERS.GET_COLLECTION).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case PoolPartnersAction.action(POOL_PARTNERS.RESET_COLLECTION).type: {
      return {
        ...state,
        collection: initialPoolPartnersState.collection,
      };
    }
    case PoolPartnersAction.success(POOL_PARTNERS.GET_POOL_PARTNER).type: {
      return {
        ...state,
        poolPartnerContext: action.payload,
      };
    }
    case PoolPartnersAction.action(POOL_PARTNERS.RESET_POOL_PARTNER_CONTEXT).type: {
      return {
        ...state,
        poolPartnerContext: initialPoolPartnersState.poolPartnerContext,
      };
    }
    default:
      return state;
  }
};

export { poolPartnersReducer };
