class PoolPartnersService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getPoolPartners(poolId) {
    return this.apiClient.getPoolPartners(poolId);
  }

  getPoolPartner(poolPartnerId) {
    return this.apiClient.getPoolPartner(poolPartnerId);
  }

  create(poolPartner) {
    return this.apiClient.addPoolPartner(poolPartner);
  }

  update(poolPartnerId, poolPartner) {
    return this.apiClient.updatePoolPartner(poolPartnerId, poolPartner);
  }

  delete(poolPartnerId) {
    return this.apiClient.deletePoolPartner(poolPartnerId);
  }
}

export default PoolPartnersService;
