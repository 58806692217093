import { combineEpics, ofType } from 'redux-observable';
import {
  CONDITIONS_PARAMETERS,
  ConditionsParametersActions,
} from 'app/conditionsParameters/actions/ConditionsParametersActions';
import {
  catchError, map, switchMap, tap, ignoreElements,
} from 'rxjs/operators';
import { toast } from 'react-toastify';
import { join, keys } from 'lodash';

export const setConditionsParametersCollectionEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.FETCH_COLLECTION_REQUEST),
  switchMap((action) => Services.bunkerConsumption.getBunkerConsumptionConditionsParameters(action.payload).pipe(
    map((conditionsParameters) => [
      ConditionsParametersActions.resetCollection(),
      ConditionsParametersActions.setCollection(conditionsParameters),
      ConditionsParametersActions.fetchCollectionSuccess(),
    ]),
    catchError(() => [
      ConditionsParametersActions.fetchCollectionFailure(),
    ]),
  )),
);

export const addConditionParameterEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.ADD_COLLECTION_ITEM),
  switchMap((action) => Services.bunkerConsumption.postBunkerConsumptionConditionsParameters(action.payload).pipe(
    map(() => ConditionsParametersActions.addCollectionItemSuccess()),
    catchError((e) => [
      ConditionsParametersActions.addCollectionItemFailure(e),
    ]),
  )),
);

export const addConditionParameterSuccessEpic = (action$, state$) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.ADD_COLLECTION_ITEM_SUCCESS),
  tap(() => toast.success('Created successfully')),
  map(() => ConditionsParametersActions.fetchCollectionRequest(state$.value.bunkerConsumptionSettings.currentPool)),
);

export const addConditionParameterFailureEpic = (action$, state$) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.ADD_COLLECTION_ITEM_FAILURE),
  map(action => `Creation Failure. Check ${join(keys(action.payload.response.errors), ', ')}  fields`),
  tap((errors) => toast.error(errors)),
  ignoreElements(),
);

export const changeConditionParameterEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.CHANGE_COLLECTION_ITEM),
  switchMap((action) => Services.bunkerConsumption.putBunkerConsumptionConditionsParameters(action.payload).pipe(
    map(() => ConditionsParametersActions.changeCollectionItemSuccess()),
    catchError((payload) => [
      ConditionsParametersActions.changeCollectionItemFailure(payload),
    ]),
  )),
);

export const changeConditionParameterSuccessEpic = (action$, state$) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.CHANGE_COLLECTION_ITEM_SUCCESS),
  tap(() => toast.success('Created successfully')),
  map(() => ConditionsParametersActions.fetchCollectionRequest(state$.value.bunkerConsumptionSettings.currentPool)),
);

export const changeConditionParameterFailureEpic = (action$, state$) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.CHANGE_COLLECTION_ITEM_FAILURE),
  map(action => `Failure while saving. Check ${join(keys(action.payload.response.errors), ', ')}  fields`),
  tap((errors) => toast.error(errors)),
  ignoreElements(),
);

export const deleteConditionParameterEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.DELETE_COLLECTION_ITEM),
  switchMap((action) => Services.bunkerConsumption.deleteBunkerConsumptionConditionsParameters(action.payload).pipe(
    map(() => ConditionsParametersActions.deleteCollectionItemSuccess()),
    catchError((payload) => [
      ConditionsParametersActions.deleteCollectionItemFailure(payload),
    ]),
  )),
);

export const deleteConditionParameterSuccessEpic = (action$, state$) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.DELETE_COLLECTION_ITEM_SUCCESS),
  tap(() => toast.success('Deleted successfully')),
  map(() => ConditionsParametersActions.fetchCollectionRequest(state$.value.bunkerConsumptionSettings.currentPool)),
);

export const deleteConditionParameterFailureEpic = (action$, state$) => action$.pipe(
  ofType(CONDITIONS_PARAMETERS.DELETE_COLLECTION_ITEM_FAILURE),
  tap(() => toast.error('Failure while deleting')),
  ignoreElements(),
);

export const conditionsParametersEpic = combineEpics(
  setConditionsParametersCollectionEpic,
  addConditionParameterEpic,
  addConditionParameterSuccessEpic,
  addConditionParameterFailureEpic,
  changeConditionParameterEpic,
  changeConditionParameterSuccessEpic,
  changeConditionParameterFailureEpic,
  deleteConditionParameterEpic,
  deleteConditionParameterSuccessEpic,
  deleteConditionParameterFailureEpic,
);
