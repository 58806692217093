import { VESSEL_GROUPS, VesselGroupsAction } from 'app/vesselGroups/actions/vesselGroups';

export const initialMarketsState = {
  collection: [],
  groups: [],
};

const vesselGroupsReducer = (state = initialMarketsState, action = { type: '' }) => {
  switch (action.type) {
    case VESSEL_GROUPS.SET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
      };
    case VESSEL_GROUPS.SET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case VesselGroupsAction.request(VESSEL_GROUPS.UPDATE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((vessel) => {
          const { vesselIMO } = action.payload;
          if (vessel.imo !== vesselIMO) { return vessel; }

          return {
            ...vessel,
            isUpdated: true,
          };
        }),
      };

    case VesselGroupsAction.failure(VESSEL_GROUPS.UPDATE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((vessel) => {
          const { vesselIMO } = action.payload;
          if (vessel.imo !== vesselIMO) { return vessel; }

          return {
            ...vessel,
            isUpdated: false,
          };
        }),
      };

    case VesselGroupsAction.success(VESSEL_GROUPS.UPDATE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((vessel) => {
          const { vesselIMO, groupId } = action.payload;
          if (vessel.imo !== vesselIMO) { return vessel; }

          const updatedGroup = vessel.groups.map((group) => {
            if (group.id !== groupId) { return group; }
            return {
              ...group,
              includes: true,
            };
          });

          return {
            ...vessel,
            groups: updatedGroup,
            isUpdated: false,
          };
        }),
      };

    case VesselGroupsAction.request(VESSEL_GROUPS.REMOVE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((vessel) => {
          const { vesselIMO } = action.payload;
          if (vessel.imo !== vesselIMO) { return vessel; }

          return {
            ...vessel,
            isUpdated: true,
          };
        }),
      };

    case VesselGroupsAction.failure(VESSEL_GROUPS.REMOVE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((vessel) => {
          const { vesselIMO } = action.payload;
          if (vessel.imo !== vesselIMO) { return vessel; }

          return {
            ...vessel,
            isUpdated: false,
          };
        }),
      };

    case VesselGroupsAction.success(VESSEL_GROUPS.REMOVE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((vessel) => {
          const { vesselIMO, groupId } = action.payload;
          if (vessel.imo !== vesselIMO) { return vessel; }

          const updatedGroup = vessel.groups.map((group) => {
            if (group.id !== groupId) { return group; }
            return {
              ...group,
              includes: false,
            };
          });

          return {
            ...vessel,
            groups: updatedGroup,
            isUpdated: false,
          };
        }),
      };

    default:
      return state;
  }
};

export { vesselGroupsReducer };
