import { map } from 'rxjs/operators';

class MarketsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  static createMarket(vesselsData) {
    return vesselsData.map((vessel) => ({
      id: vessel.id,
      name: vessel.name,
      poolId: vessel.poolId,
      poolPercentage: vessel.poolPercentage,
      marketPercentage: vessel.marketPercentage,
      imoCargo: vessel.imoCargo,
      showCommentaries: vessel.showCommentaries,
      showExposures: vessel.showExposures,
      countCommentaries: vessel.commentariesCount,
      commentaries: [],
      isUpdated: false,
      isCommentariesFetch: false,
    }));
  }

  static createComment(comments) {
    return comments.map((comment) => ({
      id: comment.id,
      comment: comment.comment,
      title: comment.title,
      publishDate: comment.publishDate,
      marketId: comment.market.id,
      poolId: comment.market.poolId,
      isUpdated: false,
    }));
  }

  getMarkets(poolId) {
    return this.apiClient.getMarkets(poolId).pipe(
      map(MarketsService.createMarket),
    );
  }

  addMarket(market) {
    return this.apiClient.addMarket(market);
  }

  updateMarket(market) {
    return this.apiClient.updateMarket(market);
  }

  deleteMarket(market) {
    return this.apiClient.deleteMarket(market);
  }

  getComments(poolId, marketId) {
    return this.apiClient.getMarketCommentaries(poolId, marketId).pipe(
      map(MarketsService.createComment),
    );
  }

  addComment(comment) {
    return this.apiClient.addComment(comment);
  }

  updateComment(comment) {
    return this.apiClient.updateComment(comment);
  }

  deleteComment(comment) {
    return this.apiClient.deleteComment(comment);
  }
}

export default MarketsService;
