import { getApiBase } from 'app/shared/services/config';
import { ROUTER } from 'app/routing/routes';
import {
  apiPoolsRoute,
  apiPoolsWithDetailsRoute,
  apiBunkerConsumptionConditionsParametersRoute,
  apiBunkerConsumptionConditionsParametersIdRoute,
  apiBunkerConsumptionConditionsWeightsRoute,
  apiBunkerConsumptionConditionsWeightsIdRoute,
  apiPoolsIdRoute,
  apiPoolsVesselsGroupsRoute,
  apiVesselGroupsUpdateRoute,
  maintenanceStatusHistoryRoute,
  maintenanceStatusRoute,
  apiClearCacheRoute,
  imagesRoute,
  apiMapVesselsRoute,
  apiVisibilityVesselRoute,
  apiMarketRoute,
  apiMarketIdRoute,
  apiCalendarEventsRoute,
  apiCalendarEventsIdRoute,
  apiMarketIdCommentaryRoute,
  apiMarketIdCommentaryIdRoute,
  apiWelcomePopupRoute,
  apiPoolCommentsRoute,
  apiPoolCommentsIdRoute,
  apiPoolThresholdRoute,
  apiDocumentsRoute,
  apiDocumentsIdRoute,
  apiDocumentDownloadRoute,
  apiDocumentCategories,
  apiDocumentCategoriesId,
  apiNewsRoute,
  apiNewsIdRoute,
  apiPoolPartnersRoute,
  apiPoolPartnersPoolIdRoute,
  apiVesselsRoute,
  apiPoolForecastsRoute,
  apiPoolForecastsIdRoute,
  apiFaqCategoriesRoute,
  apiFaqUpdateQuestionsOrderRoute,
  apiFaqUpdateCategoriesOrderRoute,
  apiFaqQuestionRoute,
  apiFaqCategoryRoute,
  apiFaqQuestionsRoute,
  apiEtlFilesRoute,
  apiEtlFilesIdRoute,
  apiDataValidationStatusRoute,
  apiDataValidationRulesRoute,
  apiDataValidationResultsRoute,
  apiDataValidationIdRulesRoute,
  apiDataValidationStartRoute,
  apiDataValidationStopRoute,
  apiDataValidationFinancialDate,
  apiAdminVesselDetailsImoRoute,
  apiAdminVesselIdRoute,
  apiAdminVesselRoute,
  apiContactsDepartmentRoute,
  apiContactsDepartmentsRoute,
  apiContactsPersonRoute,
  apiContactsPersonsRoute,
  apiContactsRoute,
  apiContactUpdateDepartmentsOrderRoute,
  apiContactUpdatePersonsOrderRoute,
  apiEtlProcessLogsRoute,
  apiEtlProcessStartAllRoute,
  apiEtlProcessStartSingleRoute,
  apiEtlProcessStatusRoute,
  apiPoolPartnerRoute,
  apiUserRoute,
  apiUsersRoute,
  apiTermsAndConditionsRoute,
  apiTermsAndConditionsUpdateRoute,
  apiBenchmarkRatesRoute,
  apiSpecificBenchmarkRateRoute,
  apiGetNpsRoute,
  apiCreateNpsRoute,
  apiUpdateNpsRoute,
  apiDeleteNpsRoute,
  apiUpdateNpsStatusRoute,
  apiGetVesselPartnerReportRoute,
} from 'app/routing/routeConstants';

export class MaerskApiClient {
  constructor(restClient) {
    this.baseUrl = getApiBase();
    this.restClient = restClient;
  }

  getPools() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiPoolsRoute));
  }

  getPoolsWithDetails() {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiPoolsWithDetailsRoute),
    );
  }

  getPool(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiPoolsIdRoute, { id: poolId }),
    );
  }

  postPool(pool) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiPoolsRoute),
      pool,
    );
  }

  putPool(pool) {
    return this.restClient.put(
      this.baseUrl + ROUTER.generate(apiPoolsIdRoute, { id: pool.id }),
      pool,
    );
  }

  deletePool(poolId) {
    return this.restClient.delete(
      this.baseUrl + ROUTER.generate(apiPoolsIdRoute, { id: poolId }),
    );
  }

  getBunkerConsumptionConditionsParameters(poolId) {
    return this.restClient.get(
      this.baseUrl
        + ROUTER.generate(apiBunkerConsumptionConditionsParametersRoute, {
          poolId,
        }),
    );
  }

  postBunkerConsumptionConditionsParameters(quarter) {
    return this.restClient.post(
      this.baseUrl
        + ROUTER.generate(apiBunkerConsumptionConditionsParametersRoute),
      quarter,
    );
  }

  putBunkerConsumptionConditionsParameters(quarter) {
    return this.restClient.put(
      this.baseUrl
        + ROUTER.generate(apiBunkerConsumptionConditionsParametersIdRoute, {
          id: quarter.id,
        }),
      quarter,
    );
  }

  deleteBunkerConsumptionConditionsParameters(quarterId) {
    return this.restClient.delete(
      this.baseUrl
        + ROUTER.generate(apiBunkerConsumptionConditionsParametersIdRoute, {
          id: quarterId,
        }),
    );
  }

  getBunkerConsumptionConditionsWeights(poolId) {
    return this.restClient.get(
      this.baseUrl
        + ROUTER.generate(apiBunkerConsumptionConditionsWeightsRoute, {
          poolId,
        }),
    );
  }

  postBunkerConsumptionConditionsWeights(quarter) {
    return this.restClient.post(
      this.baseUrl
        + ROUTER.generate(apiBunkerConsumptionConditionsWeightsRoute),
      quarter,
    );
  }

  putBunkerConsumptionConditionsWeights(quarter) {
    return this.restClient.put(
      this.baseUrl
        + ROUTER.generate(apiBunkerConsumptionConditionsWeightsIdRoute, {
          id: quarter.id,
        }),
      quarter,
    );
  }

  deleteBunkerConsumptionConditionsWeights(quarterId) {
    return this.restClient.delete(
      this.baseUrl
        + ROUTER.generate(apiBunkerConsumptionConditionsWeightsIdRoute, {
          id: quarterId,
        }),
    );
  }

  getVessels(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiVesselsRoute, { poolId }),
    );
  }

  getVesselGroups(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiPoolsVesselsGroupsRoute, { poolId }),
    );
  }

  addVesselToGroup(vesselIMO, groupId) {
    return this.restClient.post(
      this.baseUrl
        + ROUTER.generate(apiVesselGroupsUpdateRoute, {
          vesselIMO,
          groupId,
        }),
    );
  }

  removeVesselFromGroup(vesselIMO, groupId) {
    return this.restClient.delete(
      this.baseUrl
        + ROUTER.generate(apiVesselGroupsUpdateRoute, {
          vesselIMO,
          groupId,
        }),
    );
  }

  getMaintenanceStatusHistory() {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(maintenanceStatusHistoryRoute),
    );
  }

  getLastMaintenanceStatus() {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(maintenanceStatusRoute),
    );
  }

  setMaintenanceStatus(isInMaintenance) {
    return this.restClient.put(
      this.baseUrl + ROUTER.generate(maintenanceStatusRoute),
      { isInMaintenance },
    );
  }

  getClearCache() {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiClearCacheRoute),
    );
  }

  getImages() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(imagesRoute));
  }

  addImage(formData) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(imagesRoute),
      formData,
      true,
      false,
    );
  }

  getImage(fileName) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(imagesRoute, { fileName }),
    );
  }

  deleteImage(fileName) {
    return this.restClient.delete(
      this.baseUrl + ROUTER.generate(imagesRoute, { fileName }),
    );
  }

  getMapVessels(poolName) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiMapVesselsRoute, { poolName }),
    );
  }

  setVisibilityVessel(imo, isVisible) {
    if (isVisible) {
      return this.restClient.delete(
        this.baseUrl + ROUTER.generate(apiVisibilityVesselRoute, { imo }),
      );
    }
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiVisibilityVesselRoute, { imo }),
      { isVisible },
    );
  }

  getMarkets(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiMarketRoute, { poolId }),
    );
  }

  addMarket(market) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiMarketRoute, { poolId: market.poolId }),
      market,
    );
  }

  updateMarket(market) {
    return this.restClient.put(
      this.baseUrl
        + ROUTER.generate(apiMarketIdRoute, {
          poolId: market.poolId,
          id: market.id,
        }),
      market.newData,
    );
  }

  deleteMarket(market) {
    return this.restClient.delete(
      this.baseUrl
        + ROUTER.generate(apiMarketIdRoute, {
          poolId: market.poolId,
          id: market.id,
        }),
    );
  }

  getCalendarEvents() {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiCalendarEventsRoute),
    );
  }

  getCalendarPoolEvents(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiCalendarEventsRoute, { poolId }),
    );
  }

  getCalendarEvent(id) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiCalendarEventsIdRoute, { id }),
    );
  }

  postCalendarEvent(formData) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiCalendarEventsRoute),
      formData,
      true,
      false,
    );
  }

  putCalendarEvent(eventId, formData) {
    return this.restClient.put(
      this.baseUrl + ROUTER.generate(apiCalendarEventsIdRoute, { id: eventId }),
      formData,
      true,
      false,
    );
  }

  deleteCalendarEvent(id) {
    return this.restClient.delete(
      this.baseUrl + ROUTER.generate(apiCalendarEventsIdRoute, { id }),
    );
  }

  getMarketCommentaries(poolId, marketId) {
    return this.restClient.get(
      this.baseUrl
        + ROUTER.generate(apiMarketIdCommentaryRoute, { poolId, marketId }),
    );
  }

  addComment(comment) {
    return this.restClient.post(
      this.baseUrl
        + ROUTER.generate(apiMarketIdCommentaryRoute, {
          poolId: comment.poolId,
          marketId: comment.marketId,
        }),
      comment,
    );
  }

  updateComment(comment) {
    return this.restClient.put(
      this.baseUrl
        + ROUTER.generate(apiMarketIdCommentaryIdRoute, {
          poolId: comment.poolId,
          marketId: comment.marketId,
          id: comment.id,
        }),
      comment,
    );
  }

  deleteComment(comment) {
    return this.restClient.delete(
      this.baseUrl
        + ROUTER.generate(apiMarketIdCommentaryIdRoute, {
          poolId: comment.poolId,
          marketId: comment.marketId,
          id: comment.id,
        }),
    );
  }

  getWelcomePopup() {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiWelcomePopupRoute),
    );
  }

  updateWelcomePopup(welcomePopup) {
    return this.restClient.put(
      this.baseUrl + ROUTER.generate(apiWelcomePopupRoute),
      welcomePopup,
      true,
      false,
    );
  }

  getPoolComments(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiPoolCommentsRoute, { poolId }),
    );
  }

  addPoolComment(comment) {
    return this.restClient.post(
      this.baseUrl
        + ROUTER.generate(apiPoolCommentsRoute, {
          poolId: comment.poolId,
        }),
      comment,
    );
  }

  updatePoolComment(comment) {
    return this.restClient.put(
      this.baseUrl
        + ROUTER.generate(apiPoolCommentsIdRoute, {
          poolId: comment.poolId,
          id: comment.id,
        }),
      comment,
    );
  }

  deletePoolComment(comment) {
    return this.restClient.delete(
      this.baseUrl
        + ROUTER.generate(apiPoolCommentsIdRoute, {
          poolId: comment.poolId,
          id: comment.id,
        }),
    );
  }

  getThreshold(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiPoolThresholdRoute, { poolId }),
    );
  }

  getForecasts(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiPoolForecastsRoute, { poolId }),
    );
  }

  addForecasts(poolId, forecasts) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiPoolForecastsRoute, { poolId }),
      { forecasts },
    );
  }

  updateForecast(forecast) {
    return this.restClient.put(
      this.baseUrl + ROUTER.generate(apiPoolForecastsIdRoute, { poolId: forecast.poolId, id: forecast.id }),
      {
        forecastMonth: forecast.target,
        unfixedDays: forecast.unfixedDays,
        fixedDays: forecast.fixedDays,
        alphaPrediction: forecast.alphaPrediction,
        resultActual: forecast.resultActual,
        payoutRatio: forecast.payoutRatio,
      },
    );
  }

  removeForecast(poolId, forecastId) {
    return this.restClient.delete(
      this.baseUrl + ROUTER.generate(apiPoolForecastsIdRoute, { poolId, id: forecastId }),
    );
  }

  updateThreshold(poolId, threshold) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiPoolThresholdRoute, { poolId }),
      { threshold },
    );
  }

  getDocuments(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiDocumentsRoute, { poolId }),
    );
  }

  postDocument(formData) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiDocumentsRoute),
      formData,
      true,
      false,
    );
  }

  deleteDocument(documentId) {
    return this.restClient.delete(
      this.baseUrl + ROUTER.generate(apiDocumentsIdRoute, { id: documentId }),
    );
  }

  patchDocument(id, content) {
    return this.restClient.patch(
      this.baseUrl
        + ROUTER.generate(apiDocumentsIdRoute, { id }),
      content,
    );
  }

  getEtlFiles() {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiEtlFilesRoute),
    );
  }

  getEtlFile(fileId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiEtlFilesIdRoute, { id: fileId }),
    );
  }

  postEtlFile(formData) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiEtlFilesRoute),
      formData,
      true,
      false,
    );
  }

  deleteEtlFile(fileId) {
    return this.restClient.delete(
      this.baseUrl + ROUTER.generate(apiEtlFilesIdRoute, { id: fileId }),
    );
  }

  updateEtlFile(fileId, etlFile) {
    return this.restClient.patch(
      this.baseUrl + ROUTER.generate(apiEtlFilesIdRoute, { id: fileId }),
      etlFile,
      true,
      false,
    );
  }

  startEtlProcess(fileType = null) {
    if (fileType !== null) {
      const url = this.baseUrl + ROUTER.generate(apiEtlProcessStartSingleRoute, { fileType });
      return this.restClient.put(url);
    }

    const url = this.baseUrl + ROUTER.generate(apiEtlProcessStartAllRoute);
    return this.restClient.put(url);
  }

  getEtlProcessLogs() {
    const url = this.baseUrl + ROUTER.generate(apiEtlProcessLogsRoute);

    return this.restClient.get(url);
  }

  getEtlProcessStatus() {
    const url = this.baseUrl + ROUTER.generate(apiEtlProcessStatusRoute);

    return this.restClient.get(url);
  }

  getNews(id) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiNewsIdRoute, { id }),
    );
  }

  getNewsCollection() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiNewsRoute));
  }

  getNewsPoolCollection(poolId) {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiNewsRoute, { poolId }),
    );
  }

  postNews(formData) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiNewsRoute),
      formData,
      true,
    );
  }

  deleteNews(id) {
    return this.restClient.delete(
      this.baseUrl + ROUTER.generate(apiNewsIdRoute, { id }),
    );
  }

  updateNews(news) {
    return this.restClient.put(
      this.baseUrl + ROUTER.generate(apiNewsIdRoute, { id: news.id }),
      {
        publishDate: news.publishDate,
        leadingImage: news.leadingImage,
        title: news.title,
        summary: news.summary,
        content: news.content,
        isActive: news.isActive,
        poolId: news.poolId,
      },
    );
  }

  getPoolPartners(poolId) {
    const partnerRoute = poolId
      ? ROUTER.generate(apiPoolPartnersPoolIdRoute, { poolId })
      : ROUTER.generate(apiPoolPartnersRoute);

    return this.restClient.get(this.baseUrl + partnerRoute);
  }

  getPoolPartner(partnerId) {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiPoolPartnerRoute, { partnerId }));
  }

  addPoolPartner(poolPartner) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiPoolPartnersRoute), poolPartner);
  }

  updatePoolPartner(partnerId, poolPartner) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiPoolPartnerRoute, { partnerId }), poolPartner);
  }

  deletePoolPartner(partnerId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiPoolPartnerRoute, { partnerId }));
  }

  getUsers() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiUsersRoute));
  }

  getUser(userId) {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiUserRoute, { userId }));
  }

  addUser(user) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiUsersRoute), user);
  }

  updateUser(userId, user) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiUserRoute, { userId }), user);
  }

  deleteUser(userId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiUserRoute, { userId }));
  }

  downloadDocument(documentId) {
    return this.restClient.get(
      this.baseUrl
        + ROUTER.generate(apiDocumentDownloadRoute, { id: documentId }),
    );
  }

  getDocumentCategories() {
    return this.restClient.get(
      this.baseUrl + ROUTER.generate(apiDocumentCategories),
    );
  }

  postDocumentCategory(categoryName) {
    return this.restClient.post(
      this.baseUrl + ROUTER.generate(apiDocumentCategories),
      { name: categoryName },
    );
  }

  putDocumentCategory(categoryId, categoryName) {
    return this.restClient.put(
      this.baseUrl
        + ROUTER.generate(apiDocumentCategoriesId, { id: categoryId }),
      { id: categoryId, name: categoryName },
    );
  }

  deleteDocumentCategory(categoryId) {
    return this.restClient.delete(
      this.baseUrl
        + ROUTER.generate(apiDocumentCategoriesId, { id: categoryId }),
    );
  }

  getFaq() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiFaqCategoriesRoute));
  }

  createFaqCategory(category) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiFaqCategoriesRoute), category);
  }

  deleteFaqCategory(categoryId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiFaqCategoryRoute, { categoryId }));
  }

  updateFaqCategory(categoryId, category) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiFaqCategoryRoute, { categoryId }), category);
  }

  createFaqQuestion(categoryId, question) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiFaqQuestionsRoute, { categoryId }), question);
  }

  deleteFaqQuestion(categoryId, questionId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiFaqQuestionRoute, { categoryId, questionId }));
  }

  getFaqQuestion(categoryId, questionId) {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiFaqQuestionRoute, { categoryId, questionId }));
  }

  updateFaqQuestion(categoryId, questionId, question) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiFaqQuestionRoute, { categoryId, questionId }), question);
  }

  updateFaqCategoriesOrder(idsInOrder) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiFaqUpdateCategoriesOrderRoute), idsInOrder);
  }

  updateFaqQuestionsOrder(categoryId, idsInOrder) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiFaqUpdateQuestionsOrderRoute, { categoryId }), idsInOrder);
  }

  getValidationProcessStatus() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiDataValidationStatusRoute));
  }

  getValidationLatestFinancialDate() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiDataValidationFinancialDate));
  }

  startValidationProcess(body) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiDataValidationStartRoute), body);
  }

  stopValidationProcess() {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiDataValidationStopRoute));
  }

  getValidationRules() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiDataValidationRulesRoute));
  }

  addValidationRule(rule) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiDataValidationRulesRoute), rule);
  }

  updateValidationRule(rule) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiDataValidationIdRulesRoute, { id: rule.id }), rule);
  }

  deleteValidationRule(ruleId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiDataValidationIdRulesRoute, { id: ruleId }));
  }

  getValidationResults() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiDataValidationResultsRoute));
  }

  getAllVessels() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiAdminVesselRoute));
  }

  createVessel(vessel) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiAdminVesselRoute), vessel);
  }

  getVesselDetails(imo) {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiAdminVesselDetailsImoRoute, { imo }));
  }

  getVessel(vesselId) {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiAdminVesselIdRoute, { id: vesselId }));
  }

  updateVessel(vesselId, vessel) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiAdminVesselIdRoute, { id: vesselId }), vessel);
  }

  deleteVessel(vesselId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiAdminVesselIdRoute, { id: vesselId }));
  }

  getContact() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiContactsRoute));
  }

  createContactDepartment(department) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiContactsDepartmentsRoute), department);
  }

  deleteContactDepartment(departmentId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiContactsDepartmentRoute, { departmentId }));
  }

  updateContactDepartment(departmentId, department) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiContactsDepartmentRoute, { departmentId }), department);
  }

  createContactPerson(departmentId, personForm) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiContactsPersonsRoute, { departmentId }), personForm, true, false);
  }

  deleteContactPerson(departmentId, personId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiContactsPersonRoute, { departmentId, personId }));
  }

  getContactPerson(departmentId, personId) {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiContactsPersonRoute, { departmentId, personId }));
  }

  updateContactPerson(departmentId, personId, personForm, deleteImage) {
    const deleteAvatarParam = deleteImage ? '?deleteAvatar=true' : '';

    return this.restClient.put(this.baseUrl + ROUTER.generate(apiContactsPersonRoute, { departmentId, personId }) + deleteAvatarParam, personForm, true, false);
  }

  updateContactDepartmentsOrder(idsInOrder) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiContactUpdateDepartmentsOrderRoute), idsInOrder);
  }

  updateContactPersonsOrder(departmentId, idsInOrder) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiContactUpdatePersonsOrderRoute, { departmentId }), idsInOrder);
  }

  getTermsAndConditionsHistory() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiTermsAndConditionsRoute));
  }

  createTermsAndConditions(newContent) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiTermsAndConditionsRoute), { content: newContent });
  }

  updateTermsAndConditions(termsAndConditionsId, newContent) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiTermsAndConditionsUpdateRoute, { termsAndConditionsId }), { content: newContent });
  }

  getBenchmarkRates() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiBenchmarkRatesRoute));
  }

  createBenchmarkRate(benchmarkRate) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiBenchmarkRatesRoute), benchmarkRate);
  }

  updateBenchmarkRate(benchmarkRateId, benchmarkRate) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiSpecificBenchmarkRateRoute, { benchmarkRateId }), benchmarkRate);
  }

  removeBenchmarkRate(benchmarkRateId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiSpecificBenchmarkRateRoute, { benchmarkRateId }));
  }

  getNps() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiGetNpsRoute));
  }

  createNpsPeriod(newNpsPeriod) {
    return this.restClient.post(this.baseUrl + ROUTER.generate(apiCreateNpsRoute), newNpsPeriod);
  }

  updateNpsPeriod(updatedNpsPeriod) {
    const { id, ...update } = updatedNpsPeriod;
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiUpdateNpsRoute, { id }), update);
  }

  deleteNpsPeriod(npsPeriodId) {
    return this.restClient.delete(this.baseUrl + ROUTER.generate(apiDeleteNpsRoute, { id: npsPeriodId }));
  }

  updateNpsStatus(newStatus) {
    return this.restClient.put(this.baseUrl + ROUTER.generate(apiUpdateNpsStatusRoute), { isEnabled: newStatus });
  }

  getVesselPartnerReport() {
    return this.restClient.get(this.baseUrl + ROUTER.generate(apiGetVesselPartnerReportRoute));
  }
}
