class ImageService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  addImage(formData) {
    return this.apiClient.addImage(formData);
  }

  getImages() {
    return this.apiClient.getImages();
  }

  getImage(fileName) {
    return this.apiClient.getImage(fileName);
  }

  deleteImage(fileName) {
    return this.apiClient.deleteImage(fileName);
  }
}

export default ImageService;
