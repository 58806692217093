class WelcomePopupService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  static createWelcomePopupData(welcomePopup) {
    const formData = new FormData();
    const {
      image, description, videoLink, ...popupData
    } = welcomePopup;

    const popupUploadContent = {
      ...popupData,
      description: description === '' ? null : description,
      videoLink: videoLink === '' ? null : videoLink,
    };
    if (image) {
      formData.append('Image', image);
    }
    formData.append('WelcomePopupContent', JSON.stringify(popupUploadContent));
    return formData;
  }

  getWelcomePopup() {
    return this.apiClient.getWelcomePopup();
  }

  putWelcomePopup(welcomePopup) {
    return this.apiClient.updateWelcomePopup(WelcomePopupService.createWelcomePopupData(welcomePopup));
  }
}

export default WelcomePopupService;
