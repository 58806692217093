import { WELCOME_POPUP } from '../actions/WelcomePopupActions';

export const initialWelcomePopupState = {
  welcomePopup: {
    version: '',
    releaseDate: '',
    title: '',
    description: '',
    changelog: [],
    incomingFeatures: [],
    footer: '',
    isEnabled: true,
  },
};

const welcomePopupReducer = (state = initialWelcomePopupState, action = { type: '' }) => {
  switch (action.type) {
    case WELCOME_POPUP.GET_WELCOME_POPUP_SUCCESS:
      return {
        ...state,
        welcomePopup: action.payload,
      };
    case WELCOME_POPUP.UPDATE_WELCOME_POPUP_SUCCESS:
      return {
        ...state,
        welcomePopup: action.payload,
      };

    default:
      return state;
  }
};

export { welcomePopupReducer };
