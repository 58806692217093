import { combineEpics, ofType } from 'redux-observable';
import { toLocationChangeContext, toLocationPath } from 'app/routing/actions/RoutingActionsTransformations';
import { filter, map } from 'rxjs/operators';
import { routingActionTypes } from 'app/routing/actions/RoutingActions';
import { routes } from 'app/routing/routes';
import { ROUTER } from '../routes';
import {
  calendarEditRoute,
  contactEditPersonRoute, documentsEditRoute, editPoolPartnerRoute, editUserRoute,
  editVesselRoute, etlFileEditRoute, faqEditQuestionRoute, getRoutes, maintenanceEditNpsRoute, newsEditRoute,
} from '../routeConstants';

export const discoverPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter(action => routes.hasRoute(toLocationPath(action))),
  map(action => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(locationChangeContext.path);
    return actionCreator(locationChangeContext);
  }),
);

// TODO this needs to be connected into discoverPageEnter epic but current routing system doesn't allow to do that
export const newsEditPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter(action => ROUTER.lookup(action.payload.location.pathname).name === newsEditRoute),
  map(action => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.newsEditRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const calendarEditPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter(action => ROUTER.lookup(action.payload.location.pathname).name === calendarEditRoute),
  map(action => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.calendarEditRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const documentEditPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter(action => ROUTER.lookup(action.payload.location.pathname).name === documentsEditRoute),
  map(action => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.documentsEditRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const faqEditQuestionPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter(action => ROUTER.lookup(action.payload.location.pathname).name === faqEditQuestionRoute),
  map(action => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.faqEditQuestionRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const etlFileEditPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter(action => ROUTER.lookup(action.payload.location.pathname).name === etlFileEditRoute),
  map(action => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.etlFileEditRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const editVesselPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter(action => ROUTER.lookup(action.payload.location.pathname).name === editVesselRoute),
  map(action => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.editVesselRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const contactEditPersonPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter(action => ROUTER.lookup(action.payload.location.pathname).name === contactEditPersonRoute),
  map(action => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.contactEditPersonRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const editPoolPartnerPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter((action) => ROUTER.lookup(action.payload.location.pathname).name === editPoolPartnerRoute),
  map((action) => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.editPoolPartnerRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const editUserPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter((action) => ROUTER.lookup(action.payload.location.pathname).name === editUserRoute),
  map((action) => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.editUserRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const editNpsPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter((action) => ROUTER.lookup(action.payload.location.pathname).name === maintenanceEditNpsRoute),
  map((action) => toLocationChangeContext(action)),
  map((locationChangeContext) => {
    const actionCreator = routes.getOnEnterActionCreatorForRoute(getRoutes.maintenanceEditNpsRoute);
    return actionCreator(locationChangeContext);
  }),
);

export const routingEpic = combineEpics(
  discoverPageEnter,
  newsEditPageEnter,
  calendarEditPageEnter,
  documentEditPageEnter,
  faqEditQuestionPageEnter,
  etlFileEditPageEnter,
  editVesselPageEnter,
  contactEditPersonPageEnter,
  editPoolPartnerPageEnter,
  editUserPageEnter,
  editNpsPageEnter,
);
