import { combineEpics, ofType } from 'redux-observable';
import {
  mergeMap, map, tap, catchError, filter, ignoreElements,
} from 'rxjs/operators';
import { maintenanceActionTypes, maintenanceActionCreators } from 'app/maintenance/actions/MaintanceStatusActions';
import { routingActionTypes } from 'app/routing/actions/RoutingActions';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { npsActions } from 'app/nps/store/npsActions';

const isUserAuthenticated = (state$) => state$.value.authentication.isUserAuthenticated;

export const initializeMaintenanceStatus = (action$, state$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_MAINTENANCE_PAGE),
  filter(() => isUserAuthenticated(state$)),
  map(() => [
    maintenanceActionCreators.initializeMaintenanceStatus(),
    npsActions.read.request(),
  ]),
);

export const changeMaintenanceStatus = (action$, state$, { Services }) => action$.pipe(
  ofType(maintenanceActionTypes.CHANGE_MAINTENANCE_STATUS),
  mergeMap(action => Services.maintenance.changeMaintenanceStatus(action.isMaintenanceEnabled).pipe(
    map(() => [
      maintenanceActionCreators.requestLastMaintenanceStatus(),
      maintenanceActionCreators.requestMaintenanceStatusHistory(),
    ]),
  )),
  catchError(() => [
    maintenanceActionCreators.maintenanceErrorStatus(),
  ]),
);

export const loadLastMaintenanceStatus = (action$, state$, { Services }) => action$.pipe(
  ofType(
    maintenanceActionTypes.REQUEST_LAST_MAINTENANCE_STATUS,
    maintenanceActionTypes.INITIALIZE_MAINTENANCE_STATUS,
  ),
  mergeMap(() => Services.maintenance.loadLastMaintenanceStatus().pipe(
    map(lastMaintenanceStatus => maintenanceActionCreators.receiveLastMaintenanceStatus(lastMaintenanceStatus)),
  )),
  catchError(() => [
    maintenanceActionCreators.maintenanceErrorStatus(),
  ]),
);

export const loadMaintenanceHistory = (action$, state$, { Services }) => action$.pipe(
  ofType(
    maintenanceActionTypes.REQUEST_MAINTENANCE_STATUS_HISTORY,
    maintenanceActionTypes.INITIALIZE_MAINTENANCE_STATUS,
  ),
  mergeMap(() => Services.maintenance.loadMaintenanceStatusHistory().pipe(
    map(maintenanceStatusHistory => [
      maintenanceActionCreators.receiveMaintenanceStatusHistory(maintenanceStatusHistory),
    ]),
  )),
  catchError(() => [
    maintenanceActionCreators.maintenanceErrorStatus(),
  ]),
);

export const maintenanceStatusError = (action$) => action$.pipe(
  ofType(maintenanceActionTypes.ERROR_MAINTENANCE_STATUS),
  tap(() => toast.error('Error maintenance status handling.')),
  ignoreElements(),
);

export const requestClearCacheMaintenance = (action$, state$, { Services }) => action$.pipe(
  ofType(maintenanceActionTypes.REQUEST_CLEAR_CACHE_MAINTENANCE),
  mergeMap(() => Services.maintenance.clearCache().pipe(
    map(() => [
      maintenanceActionCreators.receiveClearCacheMaintenanceStatus(),
    ]),
    catchError((err) => [
      maintenanceActionCreators.requestClearCacheMaintenanceFailure(err),
    ]),
  )),
);

export const receiveClearCacheMaintenanceStatus = (action$) => action$.pipe(
  ofType(maintenanceActionTypes.RECEIVE_CLEAR_CACHE_MAINTENANCE_STATUS),
  tap(() => toast.success('Cache cleared successfully')),
  ignoreElements(),
);

export const requestClearCacheMaintenanceFailure = (action$) => action$.pipe(
  ofType(maintenanceActionTypes.REQUEST_CLEAR_CACHE_MAINTENANCE_FAILURE),
  tap((err) => toast.error(`Something went wrong. Cache not cleared. (${get(err, 'err.status', 'unknown error')})`)),
  map(() => maintenanceActionCreators.resetClearCacheMaintenance()),
);

export const maintenanceEpic = combineEpics(
  initializeMaintenanceStatus,
  changeMaintenanceStatus,
  loadMaintenanceHistory,
  loadLastMaintenanceStatus,
  maintenanceStatusError,
  loadLastMaintenanceStatus,
  requestClearCacheMaintenance,
  receiveClearCacheMaintenanceStatus,
  requestClearCacheMaintenanceFailure,
);
