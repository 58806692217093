import { BenchmarkRatesAction, BENCHMARK_RATES } from './benchmarkRatesAction';

export const initialBenchmarkRatesState = {
  collection: [],
};

const benchmarkRatesReducer = (state = initialBenchmarkRatesState, action = { type: '' }) => {
  switch (action.type) {
    case BenchmarkRatesAction.success(BENCHMARK_RATES.GET_ALL).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    default:
      return state;
  }
};

export { benchmarkRatesReducer };
