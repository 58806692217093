import { combineEpics, ofType } from 'redux-observable';
import {
  catchError, map, switchMap, tap, ignoreElements,
} from 'rxjs/operators';
import {
  CONSUMPTIONS_WEIGHTS,
  ConsumptionsWeightsActions,
} from 'app/consumptionsWeights/actions/ConsumptionsWeightsActions';
import { toast } from 'react-toastify';
import { join, keys } from 'lodash';

export const setConsumptionsWeightsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.FETCH_COLLECTION_REQUEST),
  switchMap((action) => Services.bunkerConsumption.getBunkerConsumptionConditionsWeights(action.payload).pipe(
    map((consumptionConditionsWeights) => [
      ConsumptionsWeightsActions.resetCollection(),
      ConsumptionsWeightsActions.setCollection(consumptionConditionsWeights),
      ConsumptionsWeightsActions.fetchCollectionSuccess(),
    ]),
    catchError(() => [
      ConsumptionsWeightsActions.fetchCollectionFailure(),
    ]),
  )),
);

export const addConsumptionWeightEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.ADD_COLLECTION_ITEM),
  switchMap((action) => Services.bunkerConsumption.postBunkerConsumptionConditionsWeights(action.payload).pipe(
    map(() => ConsumptionsWeightsActions.addCollectionItemSuccess()),
    catchError((payload) => [
      ConsumptionsWeightsActions.addCollectionItemFailure(payload),
    ]),
  )),
);

export const addCollectionItemSuccessEpic = (action$, state$) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.ADD_COLLECTION_ITEM_SUCCESS),
  tap(() => toast.success('Created successfully')),
  map(() => ConsumptionsWeightsActions.fetchCollectionRequest(state$.value.bunkerConsumptionSettings.currentPool)),
);

export const addCollectionItemFailureEpic = (action$, state$) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.ADD_COLLECTION_ITEM_FAILURE),
  map(action => `Creation Failure. Check ${join(keys(action.payload.response.errors), ', ')}  fields`),
  tap((errors) => toast.error(errors)),
  ignoreElements(),
);

export const changeConsumptionWeightEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.CHANGE_COLLECTION_ITEM),
  switchMap((action) => Services.bunkerConsumption.putBunkerConsumptionConditionsWeights(action.payload).pipe(
    map(() => ConsumptionsWeightsActions.changeCollectionItemSuccess()),
    catchError((payload) => [
      ConsumptionsWeightsActions.changeCollectionItemFailure(payload),
    ]),
  )),
);

export const changeCollectionItemSuccessEpic = (action$, state$) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.CHANGE_COLLECTION_ITEM_SUCCESS),
  tap(() => toast.success('Saved successfully')),
  map(() => ConsumptionsWeightsActions.fetchCollectionRequest(state$.value.bunkerConsumptionSettings.currentPool)),
);

export const changeCollectionItemFailureEpic = (action$, state$) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.CHANGE_COLLECTION_ITEM_FAILURE),
  map(action => `Failure while saving. Check ${join(keys(action.payload.response.errors), ', ')}  fields`),
  tap((errors) => toast.error(errors)),
  ignoreElements(),
);

export const deleteConsumptionWeightEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.DELETE_COLLECTION_ITEM),
  switchMap((action) => Services.bunkerConsumption.deleteBunkerConsumptionConditionsWeights(action.payload).pipe(
    map(() => ConsumptionsWeightsActions.deleteCollectionItemSuccess()),
    catchError((payload) => [
      ConsumptionsWeightsActions.deleteCollectionItemFailure(payload),
    ]),
  )),
);

export const deleteCollectionItemSuccessEpic = (action$, state$) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.DELETE_COLLECTION_ITEM_SUCCESS),
  tap(() => toast.success('Deleted successfully')),
  map(() => ConsumptionsWeightsActions.fetchCollectionRequest(state$.value.bunkerConsumptionSettings.currentPool)),
);

export const deleteCollectionItemFailureEpic = (action$, state$) => action$.pipe(
  ofType(CONSUMPTIONS_WEIGHTS.DELETE_COLLECTION_ITEM_FAILURE),
  map(() => 'Failure while deleting.'),
  tap((errors) => toast.error(errors)),
  ignoreElements(),
);

export const consumptionsWeights = combineEpics(
  setConsumptionsWeightsEpic,
  addConsumptionWeightEpic,
  changeConsumptionWeightEpic,
  deleteConsumptionWeightEpic,
  addCollectionItemSuccessEpic,
  addCollectionItemFailureEpic,
  changeCollectionItemSuccessEpic,
  changeCollectionItemFailureEpic,
  deleteCollectionItemSuccessEpic,
  deleteCollectionItemFailureEpic,
);
