import { of, from } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import {
  map,
  filter,
  ignoreElements,
  tap,
  catchError,
  mergeMap,
} from 'rxjs/operators';
import { routingActionTypes, routingActionCreators } from 'app/routing/actions/RoutingActions';
import {
  authenticationActionCreators,
  authenticationActionTypes,
} from 'app/authentication/actions/AuthenticationActions';
import { toLocationPath } from 'app/routing/actions/RoutingActionsTransformations';
import { ROUTER, routes } from 'app/routing/routes';
import { authenticationRoute, rootRoute } from 'app/routing/routeConstants';

const isUserAuthenticated = (state$) => state$.value.authentication.isUserAuthenticated;

const isAuthenticationRequired = (locationChangedAction) => {
  const path = toLocationPath(locationChangedAction);
  return routes.isAuthenticationRequired(path);
};

export const redirectIfNotAuthenticated = (action$, state$) => action$.pipe(
  ofType(routingActionTypes.LOCATION_CHANGED),
  filter(isAuthenticationRequired),
  map(() => isUserAuthenticated(state$)),
  filter(isAuthenticated => !isAuthenticated),
  map(() => routingActionCreators.push(ROUTER.generate(authenticationRoute)))
);

export const redirectIfAuthenticationError = (action$) => action$.pipe(
  ofType(authenticationActionTypes.AUTHENTICATION_ERROR),
  map(() => routingActionCreators.push(ROUTER.generate(authenticationRoute)))
);

export const loginOnAuthPageEnter = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_AUTH_PAGE),
  map(() => authenticationActionCreators.userLogIn())
);

export const initializeLogin = (action$, store$, { Services }) => action$.pipe(
  ofType(authenticationActionTypes.USER_LOG_IN),
  map(() => Services.authentication.login()),
  ignoreElements()
);

export const initializeUserFromPersist = (action$, store$, { user$ }) => action$.pipe(
  ofType('persist/REHYDRATE'),
  tap(() => user$.next(store$.value.authentication.user)),
  ignoreElements(),
);

export const extractUserData = (action$, store$, { user$, Services }) => action$.pipe(
  ofType(authenticationActionTypes.USER_LOGGED_IN),
  map((action) => Services.authentication.getUserData(action.payload)),
  map(user => {
    const { userRoles = [] } = user;
    if (userRoles.includes("Admin")) {
      user$.next(user);
      return [
        authenticationActionCreators.userCreated(user),
        authenticationActionCreators.authenticationCompleted(),
      ];
    }
    return [
      authenticationActionCreators.userLogOut(),
      authenticationActionCreators.authenticationError(),
    ];
  })
);

export const parseLoginResults = (action$, store$, { Services }) => action$.pipe(
  ofType(routingActionTypes.ENTERED_AUTH_RETURN_PAGE),
  mergeMap(action => from(Services.authentication.parseLoginResult(action.hash)).pipe(
    map(accessToken => authenticationActionCreators.userLoggedIn(accessToken)),
    catchError(error => of(authenticationActionCreators.authenticationError(error))),
  ))
);

export const finishAuthentication = (action$) => action$.pipe(
  ofType(authenticationActionTypes.AUTHENTICATION_COMPLETED),
  map(() => routingActionCreators.push(ROUTER.generate(rootRoute)))
);

export const logOutUser = (action$, state$, { user$ }) => action$.pipe(
  ofType(authenticationActionTypes.USER_LOG_OUT),
  filter(() => !state$.value.authentication.isUserAuthenticated),
  map(() => {
    user$.next(null);
    return [
      authenticationActionCreators.userLoggedOut(),
      routingActionCreators.push(ROUTER.generate(authenticationRoute)),
    ];
  })
);

export const authenticationEpic = combineEpics(redirectIfNotAuthenticated,
  redirectIfAuthenticationError,
  loginOnAuthPageEnter,
  initializeLogin,
  parseLoginResults,
  initializeUserFromPersist,
  extractUserData,
  finishAuthentication,
  logOutUser);
