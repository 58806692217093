
export const WELCOME_POPUP = {
  WELCOME_POPUP_PAGE_EXIT: 'WELCOME_POPUP_PAGE_EXIT',
  WELCOME_POPUP_PAGE_EXIT_CONFIRMED: 'WELCOME_POPUP_PAGE_EXIT_CONFIRMED',
  GET_WELCOME_POPUP: 'GET_WELCOME_POPUP',
  GET_WELCOME_POPUP_SUCCESS: 'GET_WELCOME_POPUP_SUCCESS',
  GET_WELCOME_POPUP_FAILURE: 'GET_WELCOME_POPUP_FAILURE',
  UPDATE_WELCOME_POPUP: 'UPDATE_WELCOME_POPUP',
  UPDATE_WELCOME_POPUP_CONFIRMED: 'UPDATE_WELCOME_POPUP_CONFIRMED',
  UPDATE_WELCOME_POPUP_SUCCESS: 'UPDATE_WELCOME_POPUP_SUCCESS',
  UPDATE_WELCOME_POPUP_FAILURE: 'UPDATE_WELCOME_POPUP_FAILURE',
};

export const WelcomePopupActions = {
  getWelcomePopup: () => ({
    type: WELCOME_POPUP.GET_WELCOME_POPUP,
  }),
  getWelcomePopupSuccess: (payload) => ({
    type: WELCOME_POPUP.GET_WELCOME_POPUP_SUCCESS,
    payload,
  }),
  getWelcomePopupFailure: () => ({
    type: WELCOME_POPUP.GET_WELCOME_POPUP_FAILURE,
  }),
  updateWelcomePopup: (payload) => ({
    type: WELCOME_POPUP.UPDATE_WELCOME_POPUP,
    payload,
  }),
  updateWelcomePopupConfirmed: (payload) => ({
    type: WELCOME_POPUP.UPDATE_WELCOME_POPUP_CONFIRMED,
    payload,
  }),
  updateWelcomePopupSuccess: (payload) => ({
    type: WELCOME_POPUP.UPDATE_WELCOME_POPUP_SUCCESS,
    payload,
  }),
  updateWelcomePopupFailure: (payload) => ({
    type: WELCOME_POPUP.UPDATE_WELCOME_POPUP_FAILURE,
    payload,
  }),
};
