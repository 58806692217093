class UsersService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getUsers() {
    return this.apiClient.getUsers();
  }

  getUser(userId) {
    return this.apiClient.getUser(userId);
  }

  create(user) {
    return this.apiClient.addUser(user);
  }

  update(userId, user) {
    return this.apiClient.updateUser(userId, user);
  }

  delete(userId) {
    return this.apiClient.deleteUser(userId);
  }
}

export default UsersService;
