import { ActionCreator } from 'app/store/actionCreator';

const npsActionsGroup = 'NPS';
const npsStatusActionsGroup = 'NPS_STATUS';
export const NPS = {
  SET_CONTEXT: 'NPS_SET_CONTEXT',
};

export const npsActions = new ActionCreator(npsActionsGroup);
export const npsStatusActions = new ActionCreator(npsStatusActionsGroup);
