import { VESSELS, VesselsAction } from './vesselsActions';

export const initialVesselsState = {
  collection: [],
  editVessel: {},
  autoCompleteVesselDetails: null,
};

const vesselsReducer = (state = initialVesselsState, action = { type: '' }) => {
  switch (action.type) {
    case VesselsAction.success(VESSELS.GET_ALL).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case VesselsAction.success(VESSELS.GET_EDIT_VESSEL).type: {
      return {
        ...state,
        editVessel: action.payload,
      };
    }
    case VesselsAction.action(VESSELS.RESET_EDIT_VESSEL).type: {
      return {
        ...state,
        editVessel: {},
      };
    }
    case VesselsAction.success(VESSELS.GET_AUTOCOMPLETE_VESSEL_DETAILS).type: {
      return {
        ...state,
        autoCompleteVesselDetails: action.payload,
      };
    }
    case VesselsAction.action(VESSELS.RESET_AUTOCOMPLETE_VESSEL_DETAILS).type: {
      return {
        ...state,
        autoCompleteVesselDetails: null,
      };
    }
    default:
      return state;
  }
};

export { vesselsReducer };
