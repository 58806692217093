import { POOL_COMMENTS, PoolCommentsAction } from 'app/poolComments/actions/PoolComments';

export const initialPoolCommentsState = {
  collection: [],
};

const poolCommentsReducer = (state = initialPoolCommentsState, action = { type: '' }) => {
  switch (action.type) {
    case POOL_COMMENTS.SET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
      };

    case PoolCommentsAction.success(POOL_COMMENTS.ADD_ITEM).type: {
      const {
        id, relevantDate, comment, publishDate,
      } = action.payload;
      return {
        ...state,
        collection: [
          ...state.collection,
          {
            id,
            relevantDate,
            comment,
            publishDate,
            isUpdated: false,
          },
        ],
      };
    }

    case PoolCommentsAction.request(POOL_COMMENTS.UPDATE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((comment) => {
          if (comment.id !== action.payload.id) { return comment; }

          return {
            ...comment,
            isUpdated: true,
          };
        }),
      };

    case PoolCommentsAction.failure(POOL_COMMENTS.UPDATE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((comment) => ({
          ...comment,
          isUpdated: false,
        })),
      };

    case PoolCommentsAction.success(POOL_COMMENTS.UPDATE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((comment) => {
          if (comment.id !== action.payload.id) { return comment; }
          return {
            ...comment,
            ...action.payload,
            isUpdated: false,
          };
        }),
      };

    case PoolCommentsAction.request(POOL_COMMENTS.REMOVE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((comment) => {
          if (comment.id !== action.payload.id) { return comment; }

          return {
            ...comment,
            isUpdated: true,
          };
        }),
      };

    case PoolCommentsAction.failure(POOL_COMMENTS.REMOVE_ITEM).type:
      return {
        ...state,
        collection: state.collection.map((comment) => (
          {
            ...comment,
            isUpdated: false,
          }
        )),
      };

    case PoolCommentsAction.success(POOL_COMMENTS.REMOVE_ITEM).type:
      return {
        ...state,
        collection: state.collection.filter((comment) => comment.id !== action.payload.id),
      };

    default:
      return state;
  }
};

export { poolCommentsReducer };
