import { DATA_VALIDATION_PROCESS } from 'app/dataValidation/actions/DataValidationProcess';
import { DATA_VALIDATION_RULES, DataValidationRulesAction } from 'app/dataValidation/actions/DataValidationRules';
import { DATA_VALIDATION_RESULTS, DataValidationResultsAction } from 'app/dataValidation/actions/DataValidationResults';
import { DataValidationProcessAction } from '../actions/DataValidationProcess';

export const initialDataValidationState = {
  rules: {
    collection: [],
  },
  results: {
    collection: [],
  },
  process: {
    inProgress: null,
  },
  period: null,
  latestFinancialDate: null,
  collection: [],
};

const dataValidationReducer = (state = initialDataValidationState, action = { type: '' }) => {
  switch (action.type) {
    case DataValidationProcessAction.action(DATA_VALIDATION_PROCESS.SET_IN_PROGRESS).type:
      return {
        ...state,
        process: {
          ...state.process,
          inProgress: action.payload.status,
        },
      };

    case DataValidationRulesAction.success(DATA_VALIDATION_RULES.FETCH_COLLECTION).type:
      return {
        ...state,
        rules: {
          ...state.rules,
          collection: action.payload,
        },
      };

    case DataValidationResultsAction.success(DATA_VALIDATION_RESULTS.FETCH_COLLECTION).type:
      return {
        ...state,
        results: {
          ...state.results,
          collection: action.payload,
        },
      };

    case DataValidationProcessAction.action(DATA_VALIDATION_PROCESS.SET_PERIOD).type:
      return {
        ...state,
        period: action.payload,
      };

    case DataValidationProcessAction.action(DATA_VALIDATION_PROCESS.RESET_PERIOD).type:
      return {
        ...state,
        period: null,
      };

    case DataValidationProcessAction.success(DATA_VALIDATION_PROCESS.FETCH_LATEST_FINANCIAL_DATE).type:
      return {
        ...state,
        latestFinancialDate: action.payload,
      };

    default:
      return state;
  }
};

export { dataValidationReducer };
