import { ActionCreator } from 'app/store/actionCreator';

const FORECAST_SETTINGS_NAME = 'FORECAST_SETTINGS';

export const FORECAST_SETTINGS = {
  UPDATE_THRESHOLD: 'FORECAST_SETTINGS_UPDATE_THRESHOLD',
  GET_THRESHOLD: 'FORECAST_SETTINGS_GET_THRESHOLD',
  GET_FORECASTS: 'FORECAST_SETTINGS_GET_FORECASTS',
  ADD_FORECASTS: 'FORECAST_SETTINGS_ADD_FORECASTS',
  UPDATE_FORECAST: 'FORECAST_SETTINGS_UPDATE_FORECAST',
  REMOVE_FORECAST: 'FORECAST_SETTINGS_REMOVE_FORECAST',
  SET_POOL: 'FORECAST_SETTINGS_SET_POOL',
};

export const ForecastSettingsAction = new ActionCreator(FORECAST_SETTINGS_NAME);
