class EtlProcessService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  start(fileType = null) {
    return this.apiClient.startEtlProcess(fileType);
  }

  getLogs() {
    return this.apiClient.getEtlProcessLogs();
  }

  getStatus() {
    return this.apiClient.getEtlProcessStatus();
  }
}

export default EtlProcessService;
