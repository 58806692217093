class MaintenanceService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  changeMaintenanceStatus(isInMaintenance) {
    return this.apiClient.setMaintenanceStatus(isInMaintenance);
  }

  loadMaintenanceStatusHistory() {
    return this.apiClient.getMaintenanceStatusHistory();
  }

  loadLastMaintenanceStatus() {
    return this.apiClient.getLastMaintenanceStatus();
  }

  clearCache() {
    return this.apiClient.getClearCache();
  }
}

export default MaintenanceService;
