import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  map,
  mergeMap,
  tap,
  ignoreElements,
  filter,
} from 'rxjs/operators';
import { routingActionTypes } from 'app/routing/actions/RoutingActions';
import { NEWS, NewsAction } from '../actions/newsActions';
import { ROUTER } from '../../routing/routes';
import { toast } from 'react-toastify';
import { routingActionCreators } from '../../routing/actions/RoutingActions';
import { newsRoute } from '../../routing/routeConstants';
import { PoolsActions } from 'app/pools/actions/PoolsActions';
import { get, isNull } from 'lodash';

export const enteredNewsEpic = (action$, state$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_NEWS_PAGE),
  map(() => get(state$.value, 'pools.currentPool', null)),
  map((currentPool) => [
    PoolsActions.fetchCollectionRequest(),
    currentPool
      ? NewsAction.request(NEWS.GET_NEWS_POOL_COLLECTION, currentPool)
      : NewsAction.request(NEWS.GET_NEWS_COLLECTION),
  ]),
);

export const enteredNewsEditEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_NEWS_EDIT_PAGE),
  map((action) => {
    const newsId = ROUTER.lookup(action.path).options.id;
    return Number(newsId);
  }),
  mergeMap(newsId => [
    NewsAction.action(NEWS.RESET_NEWS_FORM),
    NewsAction.request(NEWS.GET_NEWS, { id: newsId }),
    PoolsActions.fetchCollectionRequest(),
  ]),
);

export const setPoolNewsEpic = (action$) => action$.pipe(
  ofType(NewsAction.action(NEWS.SET_POOL).type),
  filter((action) => !isNull(action.payload)),
  map((action) => [
    PoolsActions.setPool(action.payload),
    NewsAction.request(NEWS.GET_NEWS_POOL_COLLECTION, action.payload),
  ]),
);

export const enteredNewsCreateEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_NEWS_CREATE_PAGE),
  map(() => [
    NewsAction.action(NEWS.RESET_NEWS_FORM),
    PoolsActions.fetchCollectionRequest() ]),
);

export const getNewsCollectionEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(NewsAction.request(NEWS.GET_NEWS_COLLECTION).type),
  mergeMap(() => Services.news.getNewsCollection().pipe(
    map((news) => NewsAction.success(NEWS.GET_NEWS_COLLECTION, news)),
    catchError((error) => NewsAction.failure(NEWS.GET_NEWS_COLLECTION, error)),
  )),
);

export const getNewsPoolCollectionEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(NewsAction.request(NEWS.GET_NEWS_POOL_COLLECTION).type),
  mergeMap((action) => Services.news.getNewsPoolCollection(action.payload).pipe(
    map((news) => NewsAction.success(NEWS.GET_NEWS_COLLECTION, news)),
    catchError((error) => NewsAction.failure(NEWS.GET_NEWS_COLLECTION, error)),
  )),
);

export const getNewsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(NewsAction.request(NEWS.GET_NEWS).type),
  mergeMap((action) => Services.news.getNews(action.payload.id).pipe(
    map((news) => [
      NewsAction.success(NEWS.GET_NEWS, news),
    ]),
    catchError((error) => [
      NewsAction.failure(NEWS.GET_NEWS, error),
    ]),
  )),
);

export const createNewsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(NewsAction.request(NEWS.ADD_NEWS).type),
  mergeMap((action) => Services.news.addNews(action.payload).pipe(
    map((news) => [
      NewsAction.success(NEWS.ADD_NEWS, news),
    ]),
    catchError((error) => [
      NewsAction.failure(NEWS.ADD_NEWS, error),
    ]),
  )),
);

export const createNewsSuccessEpic = (action$) => action$.pipe(
  ofType(NewsAction.success(NEWS.ADD_NEWS).type),
  tap(() => toast.success('News created successfully')),
  map(() => routingActionCreators.push(ROUTER.generate(newsRoute))),
);

export const createNewsFailureEpic = (action$) => action$.pipe(
  ofType(NewsAction.failure(NEWS.ADD_NEWS).type),
  tap(() => toast.error('Failure while creating the news')),
  ignoreElements(),
);

export const updateNewsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(NewsAction.request(NEWS.UPDATE_NEWS).type),
  mergeMap((action) => Services.news.updateNews(action.payload).pipe(
    map((news) => [
      NewsAction.success(NEWS.UPDATE_NEWS, news),
    ]),
    catchError((error) => [
      NewsAction.failure(NEWS.UPDATE_NEWS, error),
    ]),
  )),
);

export const updateNewsSuccessEpic = (action$) => action$.pipe(
  ofType(NewsAction.success(NEWS.UPDATE_NEWS).type),
  tap(() => toast.success('News updated successfully')),
  map(() => routingActionCreators.push(ROUTER.generate(newsRoute))),
);

export const updateNewsFailureEpic = (action$) => action$.pipe(
  ofType(NewsAction.failure(NEWS.UPDATE_NEWS).type),
  tap(() => toast.error('Failure while updating the news')),
  ignoreElements(),
);

export const removeNewsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(NewsAction.request(NEWS.REMOVE_NEWS).type),
  mergeMap((action) => Services.news.removeNews(action.payload).pipe(
    map((news) => [
      NewsAction.success(NEWS.REMOVE_NEWS, news),
    ]),
    catchError((error) => [
      NewsAction.failure(NEWS.REMOVE_NEWS, error),
    ]),
  )),
);

export const removeNewsSuccessEpic = (action$) => action$.pipe(
  ofType(NewsAction.success(NEWS.REMOVE_NEWS).type),
  tap(() => toast.success('News removed successfully')),
  map(() => routingActionCreators.push(ROUTER.generate(newsRoute))),
);

export const removeNewsFailureEpic = (action$) => action$.pipe(
  ofType(NewsAction.failure(NEWS.REMOVE_NEWS).type),
  tap(() => toast.error('Failure while removing the news')),
  ignoreElements(),
);

export const newsEpic = combineEpics(
  enteredNewsEpic,
  enteredNewsEditEpic,
  enteredNewsCreateEpic,
  getNewsEpic,
  getNewsCollectionEpic,
  getNewsPoolCollectionEpic,
  updateNewsEpic,
  updateNewsSuccessEpic,
  updateNewsFailureEpic,
  createNewsEpic,
  createNewsSuccessEpic,
  createNewsFailureEpic,
  removeNewsEpic,
  removeNewsFailureEpic,
  removeNewsSuccessEpic,
  setPoolNewsEpic,
);
