import { combineEpics, ofType } from 'redux-observable';
import { routingActionTypes } from 'app/routing/actions/RoutingActions';
import {
  catchError,
  map,
  switchMap,
  filter,
  ignoreElements,
  tap,
} from 'rxjs/operators';
import { from } from 'rxjs';
import { WELCOME_POPUP, WelcomePopupActions } from '../actions/WelcomePopupActions';
import { toast } from 'react-toastify';
import * as Swal from 'sweetalert2';
import { transformFromViewModel, transformToViewModel } from '../mappers/WelcomPopupMappers';

const showUpdateAlert = () => {
  const promise = Swal.fire({
    title: 'Do you want to update welcome popup?',
    text: 'You won\'t be able to revert this',
    type: 'question',
    showCancelButton: true,
    confirmButtonColor: '#f86c6b',
    cancelButtonColor: '#20a8d8',
    confirmButtonText: 'Update',
    animation: false,
  });
  return from(promise);
};

export const enteredWelcomePopupPageEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_WELCOME_POPUP_PAGE),
  map(() => WelcomePopupActions.getWelcomePopup()),
);

export const getWelcomePopupRequestEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(WELCOME_POPUP.GET_WELCOME_POPUP),
  switchMap(() => Services.welcomePopup.getWelcomePopup().pipe(
    map((welcomePopup) => transformToViewModel(welcomePopup)),
    map((welcomePopup) => WelcomePopupActions.getWelcomePopupSuccess(welcomePopup)),
    catchError(() => [
      WelcomePopupActions.getWelcomePopupFailure(),
    ]),
  )),
);

export const getWelcomePopupFailureEpic = (action$) => action$.pipe(
  ofType(WELCOME_POPUP.GET_WELCOME_POPUP_FAILURE),
  tap(() => toast.error('Couldn\'t receive welcome popup from API')),
  ignoreElements(),
);

export const confirmWelcomePopupUpdateEpic = (action$, state$) => action$.pipe(
  ofType(WELCOME_POPUP.UPDATE_WELCOME_POPUP),
  filter((action) => action.payload !== state$.welcomePopup),
  switchMap((action) => showUpdateAlert().pipe(
    filter(result => result.value),
    map(() => action),
  )),
  map((action) => WelcomePopupActions.updateWelcomePopupConfirmed(action.payload)),
);

export const updateWelcomePopupRequestEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(WELCOME_POPUP.UPDATE_WELCOME_POPUP_CONFIRMED),
  map((action) => transformFromViewModel(action.payload)),
  switchMap((freshWelcomePopup) => Services.welcomePopup.putWelcomePopup(freshWelcomePopup).pipe(
    map((updatedWelcomePopup) => transformToViewModel(updatedWelcomePopup)),
    map((updatedWelcomePopup) => WelcomePopupActions.updateWelcomePopupSuccess(updatedWelcomePopup)),
    catchError((payload) => WelcomePopupActions.updateWelcomePopupFailure(payload)),
  )),
);

export const updateWelcomePopupSuccessEpic = (action$) => action$.pipe(
  ofType(WELCOME_POPUP.UPDATE_WELCOME_POPUP_SUCCESS),
  tap(() => toast.success('Updated successfully')),
  map(() => WelcomePopupActions.getWelcomePopup()),
);

export const updateWelcomePopupFailureEpic = (action$) => action$.pipe(
  ofType(WELCOME_POPUP.UPDATE_WELCOME_POPUP_FAILURE),
  tap(() => toast.error('Failure while updating')),
  ignoreElements(),
);

export const welcomePopupEpic = combineEpics(
  enteredWelcomePopupPageEpic,
  getWelcomePopupRequestEpic,
  getWelcomePopupFailureEpic,
  confirmWelcomePopupUpdateEpic,
  updateWelcomePopupRequestEpic,
  updateWelcomePopupSuccessEpic,
  updateWelcomePopupFailureEpic,
);
