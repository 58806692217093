import { ActionCreator } from 'app/store/actionCreator';

export const NEWS_NAME = 'NEWS';

export const NEWS = {
  ADD_NEWS: 'NEWS_ADD_NEWS',
  GET_NEWS: 'NEWS_GET_NEWS',
  GET_NEWS_COLLECTION: 'NEWS_GET_NEWS_COLLECTION',
  GET_NEWS_POOL_COLLECTION: 'GET_NEWS_POOL_COLLECTION',
  UPDATE_NEWS: 'NEWS_UPDATE_NEWS',
  REMOVE_NEWS: 'NEWS_REMOVE',
  SET_NEWS_FORM: 'SET_NEWS_FORM',
  RESET_NEWS_FORM: 'RESET_NEWS_FORM',
  SET_POOL: 'NEWS_SET_POOL',
};

export const NewsAction = new ActionCreator(NEWS_NAME);
