class DataValidationService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  getValidationRules() {
    return this.apiClient.getValidationRules();
  }

  addValidationRule(rule) {
    return this.apiClient.addValidationRule(rule);
  }

  updateValidationRule(rule) {
    return this.apiClient.updateValidationRule(rule);
  }

  deleteValidationRule(ruleId) {
    return this.apiClient.deleteValidationRule(ruleId);
  }

  getValidationProcessStatus() {
    return this.apiClient.getValidationProcessStatus();
  }

  startValidationProcess(rules, financialDate) {
    const date = financialDate != null ? new Date(financialDate.year, financialDate.month) : null;
    return this.apiClient.startValidationProcess({ contents: rules, date });
  }

  stopValidationProcess() {
    return this.apiClient.stopValidationProcess();
  }

  getValidationResults() {
    return this.apiClient.getValidationResults();
  }

  getLatestFinancialDate() {
    return this.apiClient.getValidationLatestFinancialDate();
  }
}

export default DataValidationService;
