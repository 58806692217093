import { combineEpics, ofType } from 'redux-observable';
import {
  tap, ignoreElements, concatMap, catchError, map,
} from 'rxjs/operators';
import { toast } from 'react-toastify';
import { UPLOAD_IMAGE, UploadImageActions } from 'app/vessels/images/actions/UploadImageActions';

export const uploadImageEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(UPLOAD_IMAGE.UPLOAD),
  concatMap((action) => Services.images.addImage(action.payload).pipe(
    map(() => UploadImageActions.uploadSuccess()),
    catchError((payload) => [
      UploadImageActions.uploadFailure(payload),
    ]),
  )),
);

export const uploadImageSuccessEpic = (action$) => action$.pipe(
  ofType(UPLOAD_IMAGE.SUCCESS),
  tap(() => toast.success('File uploaded successfully')),
  ignoreElements(),
);

export const uploadImageFailureEpic = (action$) => action$.pipe(
  ofType(UPLOAD_IMAGE.FAIL),
  tap((error) => toast.error(error.payload.response.message)),
  ignoreElements(),
);

export const uploadImage = combineEpics(
  uploadImageEpic,
  uploadImageSuccessEpic,
  uploadImageFailureEpic,
);
