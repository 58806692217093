import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import 'App.scss';
import { Spinner } from 'app/shared/components/Spinner';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Create a client
export const queryClient = new QueryClient();

const Layout = React.lazy(() => import('app/layout/components/Layout'));

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Switch>
      <Route
        path="/"
        name="Home"
        render={() => (
          <Suspense fallback={<Spinner />}>
            <Layout />
          </Suspense>
        )}
      />
    </Switch>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
