import { LOCATION_CHANGE, push } from 'connected-react-router';

const routingActionTypes = {
  LOCATION_CHANGED: LOCATION_CHANGE,
  ENTERED_MAINTENANCE_PAGE: 'ENTERED_MAINTENANCE_PAGE',
  ENTERED_MAINTENANCE_NPS_PAGE: 'ENTERED_MAINTENANCE_NPS_PAGE',
  ENTERED_MAINTENANCE_CREATE_NPS_PAGE: 'ENTERED_MAINTENANCE_CREATE_NPS_PAGE',
  ENTERED_MAINTENANCE_EDIT_NPS_PAGE: 'ENTERED_MAINTENANCE_EDIT_NPS_PAGE',
  ENTERED_AUTH_PAGE: 'ENTERED_AUTH_PAGE',
  ENTERED_AUTH_RETURN_PAGE: 'ENTERED_AUTH_RETURN_PAGE',
  ENTERED_POOLS_PAGE: 'ENTERED_POOLS_PAGE',
  ENTERED_BUNKER_CONSUMPTION_SETTINGS_PAGE: 'ENTERED_BUNKER_CONSUMPTION_SETTINGS_PAGE',
  ENTERED_VESSELS_IMAGES_PAGE: 'ENTERED_VESSELS_IMAGES_PAGE',
  ENTERED_MAP_SETTINGS_PAGE: 'ENTERED_MAP_SETTINGS_PAGE',
  ENTERED_MARKETS_PAGE: 'ENTERED_MARKETS_PAGE',
  ENTERED_POOL_COMMENTS_PAGE: 'ENTERED_POOL_COMMENTS_PAGE',
  ENTERED_VESSEL_GROUP_PAGE: 'ENTERED_VESSEL_GROUP_PAGE',
  ENTERED_CALENDAR_PAGE: 'ENTERED_CALENDAR_PAGE',
  ENTERED_CALENDAR_EDIT_PAGE: 'ENTERED_CALENDAR_EDIT_PAGE',
  ENTERED_CALENDAR_CREATE_PAGE: 'ENTERED_CALENDAR_CREATE_PAGE',
  ENTERED_WELCOME_POPUP_PAGE: 'ENTERED_WELCOME_POPUP_PAGE',
  ENTERED_FORECAST_SETTINGS_PAGE: 'ENTERED_FORECAST_SETTINGS_PAGE',
  ENTERED_FORECAST_SETTINGS_ADD_FORECASTS_PAGE: 'ENTERED_FORECAST_SETTINGS_ADD_FORECASTS_PAGE',
  ENTERED_DOCUMENTS_PAGE: 'ENTERED_DOCUMENTS_PAGE',
  ENTERED_DOCUMENTS_UPLOAD_PAGE: 'ENTERED_DOCUMENTS_UPLOAD_PAGE',
  ENTERED_DOCUMENTS_EDIT_PAGE: 'ENTERED_DOCUMENTS_EDIT_PAGE',
  ENTERED_DOCUMENTS_CATEGORY_PAGE: 'ENTERED_DOCUMENTS_CATEGORY_PAGE',
  ENTERED_ETL_FILES_PAGE: 'ENTERED_ETL_FILES_PAGE',
  ENTERED_ETL_FILES_UPLOAD_PAGE: 'ENTERED_ETL_FILES_UPLOAD_PAGE',
  ENTERED_ETL_FILE_EDIT_PAGE: 'ENTERED_ETL_FILE_EDIT_PAGE',
  ENTERED_NEWS_PAGE: 'ENTERED_NEWS_PAGE',
  ENTERED_NEWS_EDIT_PAGE: 'ENTERED_NEWS_EDIT_PAGE',
  ENTERED_NEWS_CREATE_PAGE: 'ENTERED_NEWS_CREATE_PAGE',
  ENTERED_FAQ_PAGE: 'ENTERED_FAQ_PAGE',
  ENTERED_FAQ_NEW_QUESTION_PAGE: 'ENTERED_FAQ_NEW_QUESTION_PAGE',
  ENTERED_FAQ_EDIT_QUESTION_PAGE: 'ENTERED_FAQ_EDIT_QUESTION_PAGE',
  ENTERED_DATA_VALIDATION_PAGE: 'ENTERED_DATA_VALIDATION_PAGE',
  ENTERED_VESSELS_PAGE: 'ENTERED_VESSELS_PAGE',
  ENTERED_EDIT_VESSEL_PAGE: 'ENTERED_EDIT_VESSEL_PAGE',
  ENTERED_CREATE_VESSEL_PAGE: 'ENTERED_CREATE_VESSEL_PAGE',
  ENTERED_POOL_PARTNERS_PAGE: 'ENTERED_POOL_PARTNERS_PAGE',
  ENTERED_CREATE_POOL_PARTNER_PAGE: 'ENTERED_CREATE_POOL_PARTNER_PAGE',
  ENTERED_EDIT_POOL_PARTNER_PAGE: 'ENTERED_EDIT_POOL_PARTNER_PAGE',
  ENTERED_USERS_PAGE: 'ENTERED_USERS_PAGE',
  ENTERED_CREATE_USER_PAGE: 'ENTERED_CREATE_USER_PAGE',
  ENTERED_EDIT_USER_PAGE: 'ENTERED_EDIT_USER_PAGE',
  ENTERED_CONTACT_PAGE: 'ENTERED_CONTACT_PAGE',
  ENTERED_CONTACT_NEW_PERSON_PAGE: 'ENTERED_CONTACT_NEW_PERSON_PAGE',
  ENTERED_CONTACT_EDIT_PERSON_PAGE: 'ENTERED_CONTACT_EDIT_PERSON_PAGE',
  ENTERED_TERMS_AND_CONDITIONS_PAGE: 'ENTERED_TERMS_AND_CONDITIONS_PAGE',
  ENTERED_BENCHMARK_RATES_PAGE: 'ENTERED_BENCHMARK_RATES_PAGE',
  ENTERED_VESSEL_PARTNER_REPORT_PAGE: 'ENTERED_VESSEL_PARTNER_REPORT_PAGE',
};
export { routingActionTypes };

const routingActionCreators = {
  push: (location) => push(location),
  enteredMaintenancePage: () => ({
    type: routingActionTypes.ENTERED_MAINTENANCE_PAGE,
  }),
  enteredMaintenanceNpsPage: () => ({
    type: routingActionTypes.ENTERED_MAINTENANCE_NPS_PAGE,
  }),
  enteredMaintenanceCreateNpsPage: () => ({
    type: routingActionTypes.ENTERED_MAINTENANCE_CREATE_NPS_PAGE,
  }),
  enteredMaintenanceEditNpsPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_MAINTENANCE_EDIT_NPS_PAGE,
    path: locationChangeContext.path,
  }),
  enteredAuthPage: () => ({
    type: routingActionTypes.ENTERED_AUTH_PAGE,
  }),
  enteredAuthReturnPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_AUTH_RETURN_PAGE,
    hash: locationChangeContext.hash,
  }),
  enteredPoolsPage: () => ({
    type: routingActionTypes.ENTERED_POOLS_PAGE,
  }),
  enteredBunkerConsumptionSettingsPage: () => ({
    type: routingActionTypes.ENTERED_BUNKER_CONSUMPTION_SETTINGS_PAGE,
  }),
  enteredVesselsImagesPage: () => ({
    type: routingActionTypes.ENTERED_VESSELS_IMAGES_PAGE,
  }),
  enteredMapSettingsPage: () => ({
    type: routingActionTypes.ENTERED_MAP_SETTINGS_PAGE,
  }),
  enteredMarketsPage: () => ({
    type: routingActionTypes.ENTERED_MARKETS_PAGE,
  }),
  enteredPoolCommentsPage: () => ({
    type: routingActionTypes.ENTERED_POOL_COMMENTS_PAGE,
  }),
  enteredVesselGroupsPage: () => ({
    type: routingActionTypes.ENTERED_VESSEL_GROUP_PAGE,
  }),
  enteredCalendarPage: () => ({
    type: routingActionTypes.ENTERED_CALENDAR_PAGE,
  }),
  enteredCalendarEditPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_CALENDAR_EDIT_PAGE,
    path: locationChangeContext.path,
  }),
  enteredCalendarCreatePage: () => ({
    type: routingActionTypes.ENTERED_CALENDAR_CREATE_PAGE,
  }),
  enteredWelcomePopupPage: () => ({
    type: routingActionTypes.ENTERED_WELCOME_POPUP_PAGE,
  }),
  enteredForecastSettingsPage: () => ({
    type: routingActionTypes.ENTERED_FORECAST_SETTINGS_PAGE,
  }),
  enteredDocumentsPage: () => ({
    type: routingActionTypes.ENTERED_DOCUMENTS_PAGE,
  }),
  enteredDocumentsEditPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_DOCUMENTS_EDIT_PAGE,
    path: locationChangeContext.path,
  }),
  enteredDocumentsUploadPage: () => ({
    type: routingActionTypes.ENTERED_DOCUMENTS_UPLOAD_PAGE,
  }),
  enteredDocumentsCategoryPage: () => ({
    type: routingActionTypes.ENTERED_DOCUMENTS_CATEGORY_PAGE,
  }),
  enteredEtlFilesPage: () => ({
    type: routingActionTypes.ENTERED_ETL_FILES_PAGE,
  }),
  enteredEtlFilesUploadPage: () => ({
    type: routingActionTypes.ENTERED_ETL_FILES_UPLOAD_PAGE,
  }),
  enteredEtlFileEditPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_ETL_FILE_EDIT_PAGE,
    path: locationChangeContext.path,
  }),
  enteredNewsPage: () => ({
    type: routingActionTypes.ENTERED_NEWS_PAGE,
  }),
  enteredNewsEditPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_NEWS_EDIT_PAGE,
    path: locationChangeContext.path,
  }),
  enteredNewsCreatePage: () => ({
    type: routingActionTypes.ENTERED_NEWS_CREATE_PAGE,
  }),
  enteredFaqPage: () => ({
    type: routingActionTypes.ENTERED_FAQ_PAGE,
  }),
  enteredFaqCategoriesPage: () => ({
    type: routingActionTypes.ENTERED_FAQ_PAGE,
  }),
  enteredFaqNewQuestionPage: () => ({
    type: routingActionTypes.ENTERED_FAQ_NEW_QUESTION_PAGE,
  }),
  enteredFaqEditQuestionPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_FAQ_EDIT_QUESTION_PAGE,
    path: locationChangeContext.path,
  }),
  enteredDataValidationPage: () => ({
    type: routingActionTypes.ENTERED_DATA_VALIDATION_PAGE,
  }),
  enteredEditVesselPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_EDIT_VESSEL_PAGE,
    path: locationChangeContext.path,
  }),
  enteredCreateVesselPage: () => ({
    type: routingActionTypes.ENTERED_CREATE_VESSEL_PAGE,
  }),
  enteredVesselsPage: () => ({
    type: routingActionTypes.ENTERED_VESSELS_PAGE,
  }),
  enteredPoolPartnersPage: () => ({
    type: routingActionTypes.ENTERED_POOL_PARTNERS_PAGE,
  }),
  enteredCreatePoolPartnerPage: () => ({
    type: routingActionTypes.ENTERED_CREATE_POOL_PARTNER_PAGE,
  }),
  enteredEditPoolPartnerPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_EDIT_POOL_PARTNER_PAGE,
    path: locationChangeContext.path,
  }),
  enteredUsersPage: () => ({
    type: routingActionTypes.ENTERED_USERS_PAGE,
  }),
  enteredCreateUserPage: () => ({
    type: routingActionTypes.ENTERED_CREATE_USER_PAGE,
  }),
  enteredEditUserPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_EDIT_USER_PAGE,
    path: locationChangeContext.path,
  }),
  enteredContactPage: () => ({
    type: routingActionTypes.ENTERED_CONTACT_PAGE,
  }),
  enteredContactDepartmentsPage: () => ({
    type: routingActionTypes.ENTERED_CONTACT_PAGE,
  }),
  enteredContactNewPersonPage: () => ({
    type: routingActionTypes.ENTERED_CONTACT_NEW_PERSON_PAGE,
  }),
  enteredContactEditPersonPage: (locationChangeContext) => ({
    type: routingActionTypes.ENTERED_CONTACT_EDIT_PERSON_PAGE,
    path: locationChangeContext.path,
  }),
  termsAndConditionsPage: () => ({
    type: routingActionTypes.ENTERED_TERMS_AND_CONDITIONS_PAGE,
  }),
  benchmarkRatesPage: () => ({
    type: routingActionTypes.ENTERED_BENCHMARK_RATES_PAGE,
  }),
  vesselPartnerReportPage: () => ({
    type: routingActionTypes.ENTERED_VESSEL_PARTNER_REPORT_PAGE,
  }),
};
export { routingActionCreators };
