import { combineEpics, ofType } from 'redux-observable';
import {
  catchError, mergeMap, map, ignoreElements, tap,
} from 'rxjs/operators';
import { NPS, npsActions, npsStatusActions } from './npsActions';
import { routingActionCreators, routingActionTypes } from 'app/routing/actions/RoutingActions';
import { ROUTER } from 'app/routing/routes';
import { maintenanceRoute } from 'app/routing/routeConstants';
import { toast } from 'react-toastify';
import { of } from 'rxjs';

const enteredNpsUpdatePageEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_MAINTENANCE_EDIT_NPS_PAGE),
  map(action => Number(ROUTER.lookup(action.path).options.npsId)),
  map(id => [
    npsActions.read.request(),
    npsActions.action(NPS.SET_CONTEXT, id),
  ]),
);

const fetchNpsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(npsActions.read.request().type),
  mergeMap(() => Services.nps.getNps().pipe(
    map((response) => npsActions.read.success(response)),
    catchError((error) => of(npsActions.read.failure(error.response?.message || error.message))),
  )),
);

const createNpsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(npsActions.create.request().type),
  mergeMap((action) => Services.nps.createNpsPeriod(action.payload).pipe(
    map((response) => [
      npsActions.create.success(response),
      routingActionCreators.push(ROUTER.generate(maintenanceRoute)),
    ]),
    catchError((error) => of(npsActions.create.failure(error.response?.message || error.message))),
  )),
);

const createNpsSuccessEpic = (action$) => action$.pipe(
  ofType(npsActions.create.success().type),
  tap(() => {
    toast.success('NPS successfully added');
  }),
  ignoreElements(),
);

const updateNpsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(npsActions.update.request().type),
  mergeMap((action) => Services.nps.updateNpsPeriod(action.payload).pipe(
    map((response) => [
      npsActions.update.success(response),
      routingActionCreators.push(ROUTER.generate(maintenanceRoute)),
    ]),
    catchError((error) => of(npsActions.update.failure(error.response?.message || error.message))),
  )),
);

const updateNpsSuccessEpic = (action$) => action$.pipe(
  ofType(npsActions.update.success().type),
  tap((action) => {
    toast.success(`NPS ${action.payload.id} successfully updated`);
  }),
  ignoreElements(),
);

const deleteNpsEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(npsActions.delete.request().type),
  mergeMap((action) => Services.nps.deleteNpsPeriod(action.payload).pipe(
    map(() => npsActions.delete.success(action.payload)),
    catchError((error) => of(npsActions.delete.failure(error.response?.message || error.message))),
  )),
);

const deleteNpsSuccessEpic = (action$) => action$.pipe(
  ofType(npsActions.delete.success().type),
  tap((action) => {
    toast.success(`NPS ${action.payload} successfully deleted`);
  }),
  ignoreElements(),
);

const updateNpsStatusEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(npsStatusActions.update.request().type),
  mergeMap((action) => Services.nps.updateNpsStatus(action.payload).pipe(
    map((response) => npsStatusActions.update.success(response.isEnabled)),
    catchError((error) => of(npsStatusActions.update.failure(error.response?.message || error.message))),
  )),
);

const updateNpsStatusSuccessEpic = (action$) => action$.pipe(
  ofType(npsStatusActions.update.success().type),
  tap((action) => {
    toast.success(`NPS is now globally ${action.payload ? 'enabled' : 'disabled'}`);
  }),
  ignoreElements(),
);

const npsFailureToastEpic = (action$) => action$.pipe(
  ofType(
    npsActions.read.failure().type,
    npsActions.create.failure().type,
    npsActions.update.failure().type,
    npsActions.delete.failure().type,
    npsStatusActions.update.failure().type,
  ),
  tap((action) => toast.error(action.payload)),
  ignoreElements(),
);

export const npsEpics = combineEpics(
  enteredNpsUpdatePageEpic,
  fetchNpsEpic,
  createNpsEpic,
  createNpsSuccessEpic,
  updateNpsEpic,
  updateNpsSuccessEpic,
  deleteNpsEpic,
  deleteNpsSuccessEpic,
  updateNpsStatusEpic,
  updateNpsStatusSuccessEpic,
  npsFailureToastEpic,
);
