const authenticationActionTypes = {
  USER_LOG_IN: 'USER_LOG_IN',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_CREATED: 'USER_CREATED',
  AUTHENTICATION_COMPLETED: 'AUTHENTICATION_COMPLETED',
  USER_LOG_OUT: 'USER_LOG_OUT',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
};

export { authenticationActionTypes };

const authenticationActionCreators = {
  userLogIn: () => ({
    type: authenticationActionTypes.USER_LOG_IN,
  }),
  authenticationError: (error) => ({
    type: authenticationActionTypes.AUTHENTICATION_ERROR,
    error,
  }),
  userLoggedIn: (payload) => ({
    type: authenticationActionTypes.USER_LOGGED_IN,
    payload,
  }),
  userCreated: (user) => ({
    type: authenticationActionTypes.USER_CREATED,
    user,
  }),
  authenticationCompleted: () => ({
    type: authenticationActionTypes.AUTHENTICATION_COMPLETED,
  }),
  userLogOut: () => ({
    type: authenticationActionTypes.USER_LOG_OUT,
  }),
  userLoggedOut: () => ({
    type: authenticationActionTypes.USER_LOGGED_OUT,
  }),
};
export { authenticationActionCreators };
