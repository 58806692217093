import { CONTACT, ContactsAction } from './contactActions';

export const initialContactState = {
  collection: [],
  currentDepartmentId: null,
  editPerson: {},
};

const contactReducer = (state = initialContactState, action = { type: '' }) => {
  switch (action.type) {
    case ContactsAction.success(CONTACT.GET_ALL).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case ContactsAction.action(CONTACT.SET_CURRENT_DEPARTMENT).type: {
      return {
        ...state,
        currentDepartmentId: action.payload,
      };
    }
    case ContactsAction.action(CONTACT.SET_EDIT_PERSON).type: {
      return {
        ...state,
        editPerson: action.payload,
      };
    }
    case ContactsAction.action(CONTACT.RESET_PERSON_FORM).type: {
      return {
        ...state,
        editQuestion: initialContactState.editPerson,
      };
    }
    default:
      return state;
  }
};

export { contactReducer };
