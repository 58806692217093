import { formatDatetimeForApi, formatDatetimeForInput } from '../../shared/helpers/datetime';

export const DEFAULT_POOL_ID_VALUE = 0;

export const convertPoolId = (id) => {
  const idAsNumber = Number(id);
  return idAsNumber === DEFAULT_POOL_ID_VALUE ? null : idAsNumber;
};

export const newsToApi = (news) => ({
  id: news.id,
  publishDate: formatDatetimeForApi(news.publishDate),
  leadingImage: news.leadingImage,
  title: news.title,
  summary: news.summary,
  content: news.content,
  isActive: news.isActive,
  poolId: convertPoolId(news.poolId),
});

export const newsToView = (news) => ({
  id: news.id,
  publishDate: formatDatetimeForInput(news.publishDate),
  leadingImage: news.leadingImage,
  title: news.title,
  summary: news.summary,
  content: news.content,
  isActive: news.isActive,
  poolId: !news.poolId ? DEFAULT_POOL_ID_VALUE : news.poolId,
});
