import { routingActionCreators, routingActionTypes } from 'app/routing/actions/RoutingActions';
import { usersRoute } from 'app/routing/routeConstants';
import { ROUTER } from 'app/routing/routes';
import { toast } from 'react-toastify';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError, ignoreElements, map, mergeMap, tap,
} from 'rxjs/operators';
import { USERS, UsersAction } from '../actions/usersActions';
import { POOL_PARTNERS, PoolPartnersAction } from 'app/poolPartners/store/actions/poolPartnersActions';
import { VESSELS, VesselsAction } from 'app/vessels/manager/store/vesselsActions';

export const enteredUsersEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_USERS_PAGE),
  map(() => UsersAction.request(USERS.GET_COLLECTION)),
);

export const enteredCreateUserPageEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_CREATE_USER_PAGE),
  map(() => [
    UsersAction.action(USERS.RESET_USER_CONTEXT),
    PoolPartnersAction.request(POOL_PARTNERS.GET_COLLECTION),
    VesselsAction.request(VESSELS.GET_ALL),
  ]),
);

export const enteredEditUserPageEpic = (action$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_EDIT_USER_PAGE),
  map((action) => ROUTER.lookup(action.path).options.userId),
  map((userId) => [
    UsersAction.action(USERS.RESET_USER_CONTEXT),
    UsersAction.request(USERS.GET_USER, userId),
    PoolPartnersAction.request(POOL_PARTNERS.GET_COLLECTION),
    VesselsAction.request(VESSELS.GET_ALL),
  ]),
);

export const getUsersEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(UsersAction.request(USERS.GET_COLLECTION).type),
  mergeMap(() => Services.users.getUsers().pipe(
    map((users) => UsersAction.success(USERS.GET_COLLECTION, users)),
    catchError((error) => {
      if (error.status !== 500) {
        return of(UsersAction.failure(USERS.GET_COLLECTION, error?.message ?? 'Couldn\'t fetch users'));
      }

      return of(UsersAction.failure(USERS.GET_COLLECTION, 'Couldn\'t fetch users'));
    }),
  )),
);

export const getUserEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(UsersAction.request(USERS.GET_USER).type),
  mergeMap((action) => Services.users.getUser(action.payload).pipe(
    map((user) => UsersAction.success(USERS.GET_USER, user)),
    catchError((error) => {
      if (error.status !== 500) {
        return of(UsersAction.failure(USERS.GET_USER, error?.message ?? 'Couldn\'t fetch user'));
      }

      return of(UsersAction.failure(USERS.GET_USER, 'Couldn\'t fetch user'));
    }),
  )),
);

export const createUserEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(UsersAction.request(USERS.CREATE_USER).type),
  mergeMap((action) => Services.users.create(action.payload).pipe(
    map(() => [
      UsersAction.success(USERS.CREATE_USER, 'User created successfully'),
      UsersAction.request(USERS.GET_COLLECTION),
      routingActionCreators.push(ROUTER.generate(usersRoute)),
    ]),
    catchError((error) => {
      if (error.status !== 500) {
        return of(UsersAction.failure(USERS.CREATE_USER, error?.message ?? 'User creation failed'));
      }

      return of(UsersAction.failure(USERS.CREATE_USER, 'User creation failed'));
    }),
  )),
);

export const updateUserEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(UsersAction.request(USERS.UPDATE_USER).type),
  mergeMap((action) => Services.users.update(action.payload.id, action.payload.user).pipe(
    map(() => [
      UsersAction.success(USERS.UPDATE_USER, 'User updated successfully'),
      UsersAction.request(USERS.GET_COLLECTION),
      routingActionCreators.push(ROUTER.generate(usersRoute)),
    ]),
    catchError((error) => {
      if (error.status !== 500) {
        return of(UsersAction.failure(USERS.UPDATE_USER, error?.message ?? 'User update failed'));
      }

      return of(UsersAction.failure(USERS.UPDATE_USER, 'User update failed'));
    }),
  )),
);

export const deleteUserEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(UsersAction.request(USERS.DELETE_USER).type),
  mergeMap((action) => Services.users.delete(action.payload).pipe(
    map(() => [
      UsersAction.success(USERS.DELETE_USER, 'User deleted successfully'),
      UsersAction.request(USERS.GET_COLLECTION),
    ]),
    catchError((error) => {
      if (error.status !== 500) {
        return of(UsersAction.failure(USERS.DELETE_USER, error?.message ?? 'User deletion failed'));
      }

      return of(UsersAction.failure(USERS.DELETE_USER, 'User deletion failed'));
    }),
  )),
);

export const successToastEpic = (action$) => action$.pipe(
  ofType(
    UsersAction.success(USERS.CREATE_USER).type,
    UsersAction.success(USERS.UPDATE_USER).type,
    UsersAction.success(USERS.DELETE_USER).type,
  ),
  tap((action) => toast.success(action.payload)),
  ignoreElements(),
);

export const failureToastEpic = (action$) => action$.pipe(
  ofType(
    UsersAction.failure(USERS.GET_COLLECTION).type,
    UsersAction.failure(USERS.GET_USER).type,
    UsersAction.failure(USERS.CREATE_USER).type,
    UsersAction.failure(USERS.UPDATE_USER).type,
    UsersAction.failure(USERS.DELETE_USER).type,
  ),
  tap((action) => toast.error(action.payload)),
  ignoreElements(),
);

export const usersEpic = combineEpics(
  getUsersEpic,
  getUserEpic,
  enteredUsersEpic,
  enteredCreateUserPageEpic,
  enteredEditUserPageEpic,
  createUserEpic,
  updateUserEpic,
  deleteUserEpic,
  successToastEpic,
  failureToastEpic,
);
