class DocumentsService {
  constructor(apiClient) {
    this.apiClient = apiClient;
  }

  static createDocumentFormData(document) {
    const formData = new FormData();
    const {
      categoryId, title, description, poolId, partnerId, file,
    } = document;
    const documentUploadContent = {
      title,
      description,
      categoryId,
      partnerId: partnerId === 'All' ? null : partnerId,
      poolId: poolId === 'All' ? null : poolId,
    };
    formData.append('File', file);
    formData.append('DocumentUploadContent', JSON.stringify(documentUploadContent));
    return formData;
  }

  static createDocumentPatchData(document) {
    const {
      categoryId, title, description, poolId, partnerId,
    } = document;
    return {
      title,
      description,
      categoryId,
      partnerId: partnerId === 'All' ? null : partnerId,
      poolId: poolId === 'All' ? null : poolId,
    };
  }

  getDocuments(poolId) {
    return this.apiClient.getDocuments(poolId);
  }

  postDocument(document) {
    return this.apiClient.postDocument(
      DocumentsService.createDocumentFormData(document),
    );
  }

  deleteDocument(documentId) {
    return this.apiClient.deleteDocument(documentId);
  }

  patchDocument(document) {
    return this.apiClient.patchDocument(document.id, DocumentsService.createDocumentPatchData(document));
  }

  downloadDocument(documentId) {
    return this.apiClient.downloadDocument(documentId);
  }

  getDocumentCategories() {
    return this.apiClient.getDocumentCategories();
  }

  postDocumentCategory(categoryName) {
    return this.apiClient.postDocumentCategory(categoryName);
  }

  putDocumentCategory(categoryId, categoryName) {
    return this.apiClient.putDocumentCategory(categoryId, categoryName);
  }

  deleteDocumentCategory(categoryId) {
    return this.apiClient.deleteDocumentCategory(categoryId);
  }
}

export default DocumentsService;
