import { ActionCreator } from 'app/store/actionCreator';

export const ETL_FILES_NAME = 'ETL_FILES';

export const ETL_FILES = {
  ADD_FILE: 'ETL_FILES_ADD_FILE',
  GET_FILES: 'ETL_FILES_GET_FILES',
  GET_EDIT_FILE: 'ETL_FILES_GET_EDIT_FILE',
  UPDATE_FILE: 'ETL_FILES_UPDATE_FILE',
  REMOVE_FILE: 'ETL_FILES_REMOVE_FILE',
  DOWNLOAD_FILE: 'ETL_FILES_DOWNLOAD_FILE',
};

export const EtlFilesAction = new ActionCreator(ETL_FILES_NAME);
