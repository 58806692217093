import { ActionCreator } from 'app/store/actionCreator';

export const FAQ_NAME = 'FAQ';

export const FAQ = {
  GET_ALL: 'GET_ALL',
  SET_CURRENT_CATEGORY: 'SET_CURRENT_CATEGORY',
  UPDATE_CATEGORIES_ORDER: 'UPDATE_CATEGORIES_ORDER',
  UPDATE_QUESTIONS_ORDER: 'UPDATE_QUESTIONS_ORDER',
  CREATE_QUESTION: 'CREATE_QUESTION',
  DELETE_QUESTION: 'DELETE_QUESTION',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  SET_EDIT_QUESTION: 'SET_EDIT_QUESTION',
  GET_EDIT_QUESTION: 'GET_EDIT_QUESTION',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  RESET_QUESTION_FORM: 'RESET_QUESTION_FORM',
};

export const FaqAction = new ActionCreator(FAQ_NAME);
