export const UPLOAD_IMAGE = {
  UPLOAD: 'UPLOAD_IMAGE_UPLOAD',
  SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  FAIL: 'UPLOAD_IMAGE_FAIL',
};

export const UploadImageActions = {

  upload: (payload) => ({
    type: UPLOAD_IMAGE.UPLOAD,
    payload,
  }),

  uploadSuccess: (payload) => ({
    type: UPLOAD_IMAGE.SUCCESS,
    payload,
  }),

  uploadFailure: (payload) => ({
    type: UPLOAD_IMAGE.FAIL,
    payload,
  }),
};
