import { CONDITIONS_PARAMETERS } from 'app/conditionsParameters/actions/ConditionsParametersActions';

export const initialConditionsParametersState = {
  newItems: [],
  collection: [],
};

const conditionsParametersReducer = (state = initialConditionsParametersState, action = { type: '' }) => {
  switch (action.type) {
    case CONDITIONS_PARAMETERS.SET_COLLECTION:
      return {
        ...state,
        collection: action.payload,
      };

    case CONDITIONS_PARAMETERS.RESET_COLLECTION:
      return {
        ...state,
        collection: initialConditionsParametersState.collection,
      };

    case CONDITIONS_PARAMETERS.PREPARE_NEW_COLLECTION_ITEM:
      return {
        ...state,
        newItems: [
          ...state.newItems,
          action.payload,
        ],
      };

    case CONDITIONS_PARAMETERS.REMOVE_NEW_COLLECTION_ITEM:
      return {
        ...state,
        newItems: state.newItems.filter(item => item.id !== action.payload),
      };

    case CONDITIONS_PARAMETERS.RESET_NEW_COLLECTION:
      return {
        ...state,
        newItems: [],
      };

    default:
      return state;
  }
};

export { conditionsParametersReducer };
