import { ETL_FILES, EtlFilesAction } from 'app/etlFiles/actions/etlFilesActions';

export const initialEtlFilesState = {
  collection: [],
  editFile: {},
};

const etlFilesReducer = (state = initialEtlFilesState, action = { type: '' }) => {
  switch (action.type) {
    case EtlFilesAction.success(ETL_FILES.GET_FILES).type: {
      return {
        ...state,
        collection: action.payload,
      };
    }
    case EtlFilesAction.success(ETL_FILES.GET_EDIT_FILE).type: {
      return {
        ...state,
        editFile: action.payload,
      };
    }
    default:
      return state;
  }
};

export { etlFilesReducer };
